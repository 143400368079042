<template>
  <div>
    <data-table
      model="koala/Currency"
      service-file="koala/CurrenciesService"
      :path="path()"
      :columns="columns()"
      ref="table"
      :tableLimits="[10, 50, 100]"
    />
  </div>
</template>

<script>
import TableColumnTemplate from '@constants/table-column-template.js'

export default {
  created() {
    this.setTitle()
  },
  components: {},
  methods: {
    columns() {
      return [
        {
          field: 'name',
          type: 'text',
          search_key: 'name',
          filterable: true,
          advancedFilter: true,
          showModalAdvanced: true,
          basicFilterType: 'text',
          showEmptyNull: false,
        },
        {
          field: 'code',
          type: 'text',
          search_key: 'code',
          filterable: true,
          advancedFilter: true,
          showModalAdvanced: true,
          basicFilterType: 'text',
          showEmptyNull: false,
        },
        {
          field: 'updated',
          type: 'text',
          filterable: false,
          template: TableColumnTemplate.RELATIVE_DATE,
        },
      ]
    },
    path() {
      return `koala/v1/currencies`
    },
  },
}
</script>
