<template>
  <div>
    <div
      class="card scrollable-table"
      ref="table-card"
      :style="applyTableSize ? { minHeight: calculateTableSize + 'px' } : {}"
      v-bind:class="{
        'scrollable-table': isScrollableModel(),
        'tracking-links-table': model === 'AffiliateLink',
      }"
    >
      <div v-if="showBetaHeader" class="card-header">
        <div v-if="showPandoraTag" class="left-element top-0 left-0 position-absolute m-3">
          <div class="badge bg-white-75">Pandora</div>
        </div>
        <div class="right-element top-0 right-0 position-absolute m-3">
          <div class="badge bg-white-75">BETA</div>
        </div>
      </div>
      <div v-if="showCardHeader" class="card-header" style="border-bottom: 1px solid rgb(241, 242, 249)">
        <table-header
          :basicHeader="basicTable"
          :columns="columns"
          :stateName="stateName"
          :customActionButton="customActionButton"
          :customSecondaryButton="customSecondaryButton"
          :enableAdvancedSearch="enableAdvancedSearch"
          :enableQuickSearch="enableQuickSearch"
          :totalRows="totalRows"
          :tableLimits="tableLimits"
          @bulkEditClick="bulkEditClick"
          @createClick="$refs.createModal.show()"
        />
      </div>
      <div class="card-body" :style="applyTableSize ? { minHeight: '240px' } : {}">
        <div class="text-right" v-if="confluenceId">
          <span @click="openModalInformation()" class="btn btn-outline-info btn-sm">
            <i class="uil uil-info-circle"></i>Help
          </span>
        </div>
        <table
          class="table"
          v-bind:class="[{ 'opacity-50': isLoading, 'table-sm': basicTable }, customTableClass]"
          style="border-bottom: 1px solid rgb(241, 242, 249)"
        >
          <thead>
            <tr>
              <th style="padding: 12px 10px; max-width: 85px" v-if="enableBulkSelect">
                <table-bulk-checkbox
                  extraClass="ml-1"
                  id="bulkToggleAll"
                  :inHeader="true"
                  :checked="bulkToggleAll"
                  @bulkToggleVisible="(value) => bulkToggleVisibleRows(value)"
                  :stateName="stateName"
                  :basicTable="basicTable"
                />
              </th>
              <template v-for="(column, index) in columns">
                <th
                  v-if="isColumnVisible(column)"
                  :key="index"
                  @click="orderBy(column)"
                  v-bind:class="{
                    clickable: column.sortable !== false,
                    'text-primary': order && order.orderBy === column,
                  }"
                >
                  <div class="d-flex justify-content-between">
                    <span style="padding-top: 2px; white-space: nowrap">{{
                      $prettyLabels(column.label ? column.label : column.field)
                    }}</span>
                    <i
                      v-if="order && order.orderBy === (column.order_key ? column.order_key : column.field)"
                      class="uil"
                      v-bind:class="{
                        'uil-sort-amount-up': order.ascending,
                        'uil-sort-amount-down': !order.ascending,
                      }"
                    ></i>
                  </div>
                </th>
              </template>
            </tr>
            <tr class="filter-row" v-if="isKoalaCampaign || (enableAdvancedSearch && advancedSearch)">
              <th v-if="enableBulkSelect" />
              <template v-for="column in columns">
                <th v-if="isColumnVisible(column)" :key="column.field">
                  <table-advanced-filter
                    v-if="column.filterable !== false && column.field !== 'actions'"
                    :column="column"
                    @filterChange="advancedFilter"
                    :filtersEnabled="filters"
                    :stateName="stateName"
                    :isKoalaModel="isKoalaModel"
                  />
                </th>
              </template>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td
                class="text-center"
                v-if="rows && rows.length === 0 && visibleColumns"
                :colspan="enableBulkSelect ? visibleColumns.length + 1 : visibleColumns.length"
              >
                {{ isLoading ? 'Loading ...' : 'No matching records' }}
              </td>
            </tr>
            <tr
              v-for="(row, index) in rows"
              :key="index"
              v-bind:class="{ active: false }"
              @click="enableRowClick && rowClick($event, row[fieldAccountId], row)"
            >
              <td class="bulk-col" v-if="enableBulkSelect" @click.stop>
                <table-bulk-checkbox
                  :basicTable="basicTable"
                  :id="row.id"
                  :checked="bulkSelected.includes(row.id)"
                  @bulkRowClick="(value) => (bulkSelected = value)"
                  :stateName="stateName"
                />
              </td>
              <template v-for="(column, index) in columns">
                <table-column
                  v-if="isColumnVisible(column)"
                  :key="index"
                  :column="column"
                  :row="row"
                  :template="columnTemplates[column.field] || null"
                />
              </template>
              <td v-if="!basicTable" class="text-center">
                <table-row-actions
                  :rowActionButtons="rowActionButtons"
                  :row="row"
                  :showMinimalRemove="showMinimalRemove"
                  :showRemove="showRemove && methodAllowed('delete', path)"
                  @remove="remove(row)"
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="card-footer" ref="table-footer" v-if="showCardFooter">
        <table-footer
          :enableDataExport="enableDataExport"
          :stateName="stateName"
          :totalRows="totalRows"
          :pageCount="pageCount"
          :activeLimit="activeLimit"
          :activePage="activePage"
          @paginationClick="paginationClick"
          @exportCsv="() => $refs.exportModal.show()"
        />
      </div>
    </div>
    <table-create-modal
      v-bind="modalProps"
      v-if="model"
      v-on:refresh="search"
      ref="createModal"
      :post-create-navigation-route="postCreateNavigationRoute"
      :create-defaults="createDefaults"
      :customCreateActionButton="customCreateActionButton"
      :notifyExtraFieldIdCreate="notifyExtraFieldIdCreate"
    />
    <table-edit-modal
      v-bind="modalProps"
      v-if="model"
      v-on:refresh="search"
      ref="editModal"
      :show-remove="showRemove && methodAllowed('delete', path)"
      :extraEditInfo="extraEditInfo"
      :extraDeleteInfo="extraDeleteInfo"
      :granular="granular"
      :showRemove="showRemove"
      :edit-field-filters="editFieldFilters"
      :submit-data-modifier="editSubmitDataModifier"
      :twoColumnLayout="editGridLayout"
      :notifyExtraFieldIdEdit="notifyExtraFieldIdEdit"
      :pusherModel="pusherModel"
      :checkStatusOfOperators="checkStatusOfOperators"
    />
    <export-table-modal
      v-if="enableDataExport"
      ref="exportModal"
      :rows="rows"
      :filters="filters"
      :columns="columns"
      :totalRows="totalRows"
      :exportAllRows="exportAllRows"
      :visibleColumns="visibleColumns"
      :columnTemplates="columnTemplates"
      :csvColumnTemplates="csvColumnTemplates"
      :requestMethod="makeRequest"
    />
    <information-modal ref="informationModal" :id="confluenceId" />
  </div>
</template>

<script>
import qs from 'qs'
import axios from 'axios'
import ConfirmDialog from '@atoms/misc/confirm-dialog.vue'
import ConfirmGranularDialog from '@atoms/misc/confirm-granular-dialog.vue'
import { openDialog } from 'vue3-promise-dialog'
import _ from 'lodash'

import TableColumn from '@atoms/table/table-column.vue'
import TableHeader from '@molecules/table/table-header.vue'
import TableFooter from '@molecules/table/table-footer.vue'
import TableAdvancedFilter from '@molecules/table/table-advanced-filter.vue'
import TableHelper from '@mixins/TableHelper.js'
import TableCreateModal from '@organisms/table/table-create.vue'
import TableEditModal from '@organisms/table/table-edit.vue'
import TableRowActions from '@atoms/table/table-row-actions.vue'
import TableBulkCheckbox from '@atoms/table/table-bulk-checkbox.vue'
import ExportTableModal from '@molecules/table/export-table-modal.vue'

import { store } from '@store/index'
import tableStore from '@store/modules/table'
import patterns from '@constants/regex-patterns.js'
import InformationModal from '@molecules/cms/modals/information-modal.vue'
import { loadService, loadModel } from '@mixins/HandleDynamicImports.js'

const confirmDelete = async (title, content, extraMessage, boldText) => {
  return await openDialog(ConfirmDialog, { title, content, extraMessage, boldText })
}

const confirmGranularDelete = async (title, content, granular_id) => {
  return await openDialog(ConfirmGranularDialog, { title, content, granular_id })
}

export default {
  components: {
    ConfirmDialog,
    TableColumn,
    TableAdvancedFilter,
    TableHeader,
    TableFooter,
    TableCreateModal,
    TableEditModal,
    TableRowActions,
    TableBulkCheckbox,
    ExportTableModal,
    InformationModal,
  },
  beforeCreate() {
    let stateName = this.stateName ? this.stateName : 'data-table'

    if (!this.$store.state[stateName]) {
      store.registerModule(stateName, tableStore)
    }

    this.$store.dispatch(
      `${stateName}/setAdvancedSearch`,
      _.isEmpty(TableHelper.getUrlParamsFilters(this.$route.query))
        ? this.$cookies.get('advanced_search') === 'true'
        : true
    )
  },
  beforeMount() {
    // Reset store to Default
    const user = this.$auth?.user()

    const tableSettings = user?.table_settings || null

    this.$store.commit(`${this.stateName}/setIsLoading`, true)

    this.$store.dispatch(
      `${this.stateName}/setVisibleColumns`,
      this.statefulColumns && tableSettings && tableSettings[this.$route.path] && !this.basicTable
        ? tableSettings[this.$route.path]
        : this.columns.map((column) => column.field)
    )

    this.$store.dispatch(
      `${this.stateName}/setShowCreate`,
      this.showCreate && this.methodAllowed('post', this.path)
    )
  },
  created() {
    let match = this.$route.path.match(patterns.table.regex)
    if (match && match.length > 0 && match[0] === this.$route.path) {
      this.confluenceId = patterns.table.id
      return true
    }
  },
  mounted() {
    if (this.$route.query['child']) {
      this.$refs.editModal.show(this.$route.query['child'])
    }

    const urlParams = TableHelper.getUrlParamsFilters(this.$route.query)
    if (!_.isEmpty(urlParams)) {
      this.filters = Object.assign({}, urlParams, this.filters)
    }

    this.emitter.on(`${this.stateName}.applyFilters`, (filters) => {
      const currSearchParams = this.searchParams
      this.filters = filters
      TableHelper.compareFilters(currSearchParams, this.searchParams) && this.search(true)
    })

    if (Object.keys(this.$store.getters[`${this.stateName}/advancedFilters`]).length) {
      this.filters = this.$store.getters[`${this.stateName}/advancedFilters`]
    }

    this.emitter.on(`${this.stateName}.search`, this.search)

    if (this.enabledAdvancedSearchOnMount) {
      this.$store.dispatch(`${this.stateName}/setAdvancedSearch`, true)
    }
    this.order = this.$store.getters[`${this.stateName}/order`]
    this.loadOnMount && this.search()
  },

  unmounted() {
    this.emitter.off(`${this.stateName}.applyFilters`)
    this.emitter.off(`${this.stateName}.search`)
    this.$store.dispatch(`${this.stateName}/setSelecteditems`, [])
  },
  data() {
    return {
      controller: new AbortController(),
      rows: [],
      totalRows: null,
      extraData: {},
      order: null,
      filters: {},
      lastSearchParams: null,
      bulkToggleAll: false,
      confluenceId: 0,
      modalProps: {
        model: this.model,
        path: this.permissionPath ? this.permissionPath : this.path,
        serviceFile: this.serviceFile,
        serviceParams: this.serviceParams,
      },
    }
  },
  props: {
    fieldAccountId: {
      type: String,
      default: 'id',
    },
    extraComponentHeight: {
      type: Number,
      default: 0,
    },
    checkStatusOfOperators: {
      type: Function,
      default: null,
    },
    granular: {
      default: false,
      type: Boolean,
    },
    enabledAdvancedSearchOnMount: {
      type: Boolean,
      default: false,
    },
    defaultLimit: {
      type: Number,
    },
    statefulColumns: {
      default: true,
      type: Boolean,
    },
    customActionButton: {
      type: Object,
      default: null,
    },
    customSecondaryButton: {
      type: Object,
      default: null,
    },
    customRowClick: {
      type: [Function],
      default: null,
    },
    stateName: {
      type: String,
      default: () => 'data-table',
    },
    exportAllRows: { type: Boolean, default: true },
    model: {
      type: String,
    },
    path: {
      required: true,
      type: String,
    },
    permissionPath: {
      type: String,
    },
    serviceFile: {
      type: String,
      default: () => '',
    },
    serviceParams: {
      type: Array,
      default: () => [],
    },
    postCreateNavigationRoute: {
      type: String,
      default: () => null,
    },
    createDefaults: {
      type: Object,
    },
    columns: {
      type: Array,
      required: true,
    },
    columnTemplates: {
      type: Object,
      default: () => ({}),
    },
    csvColumnTemplates: {
      type: Object,
      default: () => ({}),
    },
    customCreateActionButton: {
      type: Object,
      default: () => {
        return {
          action: null,
          title: 'Primary',
          icon: 'uil uil-share-alt',
          roles: [],
        }
      },
    },
    extraDeleteInfo: {
      type: Object,
      default: () => {
        return {
          title: 'Warning',
          message: '',
          extraMessage: '',
          boldText: '',
        }
      },
    },
    extraEditInfo: {
      type: Object,
      default: () => {
        return {
          title: 'Warning',
          message: '',
        }
      },
    },
    rowActionButtons: {
      type: Array,
      default: () => [],
    },
    enableDataExport: {
      type: Boolean,
      default: () => true,
    },
    enableAdvancedSearch: {
      type: Boolean,
      default: () => true,
    },
    enableQuickSearch: {
      type: Boolean,
      default: () => true,
    },
    showCardHeader: {
      type: Boolean,
      default: () => true,
    },
    showCardFooter: {
      type: Boolean,
      default: () => true,
    },
    showBetaHeader: {
      type: Boolean,
      default: () => false,
    },
    showPandoraTag: {
      type: Boolean,
      default: () => false,
    },
    basicTable: {
      type: Boolean,
      default: () => false,
    },
    enableBulkSelect: {
      type: Boolean,
      default: () => false,
    },
    showCreate: {
      type: Boolean,
      default: () => true,
    },
    showEditModal: {
      type: Boolean,
      default: () => true,
    },
    removeClick: {
      type: Function,
    },
    showRemove: {
      type: Boolean,
      default: () => true,
    },
    showMinimalRemove: {
      type: Boolean,
      default: () => false,
    },
    notifyExtraFieldIdCreate: {
      type: String,
      default: () => null,
    },
    // fields that need to be removed from the object before submitting
    editFieldFilters: {
      type: Array,
      default: () => [],
    },
    // modify data before submitting
    editSubmitDataModifier: {
      type: Function,
      default: null,
    },
    editGridLayout: {
      type: Boolean,
      default: false,
    },
    enableRowClick: {
      type: Boolean,
      default: true,
    },
    notifyExtraFieldIdEdit: {
      type: String,
      default: () => null,
    },
    loadOnMount: {
      type: Boolean,
      default: () => true,
    },
    customTableClass: {
      type: String,
      default: () => '',
    },
    tableLimits: {
      required: false,
      default: () => [10, 100, 1000],
    },
    getCustomDataFunctionName: {
      required: false,
      default: '',
    },
    pusherModel: {
      type: String,
      default: () => {
        return 'default'
      },
    },
    applyTableSize: {
      type: Boolean,
      default: true,
    },
  },
  watch: {
    isLoading: function (newVal) {
      this.emitter.emit('table.loading', newVal)
    },
    searchParams: {
      handler(newVal, oldVal) {
        !TableHelper.compareFilters(newVal, oldVal) && this.search()
      },
      deep: true,
    },
    advancedSearch: function () {
      this.resetFilters()
    },
    visibleColumns: function () {
      !this.isLoading && this.resetFilters()
    },
    bulkSelected: function () {
      if (this.bulkSelected.length === 0) {
        this.bulkToggleAll = false
      }
    },
  },
  computed: {
    isLoading() {
      return this.$store.getters[`${this.stateName}/isLoading`]
    },
    isKoalaCampaign() {
      return this.$route && this.$route.path === '/koala-campaigns'
    },
    bulkSelectedAllItems() {
      return this.$store.getters[`${this.stateName}/allItemsSelected`]
    },
    bulkSelected: {
      set(value) {
        this.$store.dispatch(`${this.stateName}/setSelecteditems`, value)
      },
      get() {
        return this.$store.getters[`${this.stateName}/selecteditems`]
      },
    },
    visibleColumns() {
      return this.$store.getters[`${this.stateName}/visibleColumns`]
    },
    activeLimit() {
      return this.$store.getters[`${this.stateName}/limit`]
    },
    activePage() {
      return this.$store.getters[`${this.stateName}/activePage`]
    },
    advancedSearch() {
      return this.$store.getters[`${this.stateName}/advancedSearch`]
    },
    searchQuery() {
      return this.$store.getters[`${this.stateName}/searchQuery`]
    },
    pageCount() {
      return Math.ceil(this.totalRows / this.activeLimit)
    },
    serviceLoader() {
      return () => loadService(this.serviceFile)
    },
    modelLoader() {
      return () => loadModel(this.model)
    },
    searchParams() {
      let params = { limit: this.activeLimit }

      if (this.searchQuery) {
        params.search = this.searchQuery
      }

      if (this.$store.getters['data-table/siteFilters']) {
        params = Object.assign({}, params, this.$store.getters['data-table/siteFilters'])
      }

      params = this.filterObject(Object.assign({}, params, this.filters), ['', null])

      if (this.order) {
        params = Object.assign({}, params, this.order)
      }

      return params
    },
    isKoalaModel() {
      return this.model && this.model.startsWith('koala/') && this.model !== 'koala/Campaign'
    },
    calculateTableSize() {
      let filterHeight = this.extraComponentHeight > 0 ? this.extraComponentHeight + 16 : 0
      return window.innerHeight - (88 + 24 + filterHeight) // 88 is the header height, 24 is the padding
    },
  },
  methods: {
    openModalInformation() {
      this.$refs.informationModal.show()
    },
    isColumnVisible(column) {
      return !this.basicTable
        ? this.visibleColumns.includes(column.field) && column.field !== 'actions' && column.type !== 'csv'
        : true
    },
    rowClick(event, id, row) {
      if (TableHelper.isTextSelected() || TableHelper.isNotValidClick(event)) {
        return
      }

      const path = `${this.$route.path}/${id}`

      if (this.showEditModal) {
        TableHelper.isCtrlKey(event)
          ? window.open(`${this.$route.path}?child=${id}`, '_blank')
          : this.$refs.editModal.show(id)

        return
      } else if (this.customRowClick) {
        this.customRowClick(event, row, TableHelper.isCtrlKey(event))
        return
      } else if (this.enableBulkSelect && !this.postCreateNavigationRoute) {
        this.bulkSelected = id
        return
      }

      TableHelper.isCtrlKey(event) ? window.open(path, '_blank') : this.$router.push(path)
    },
    advancedFilter(filter) {
      let sanitized = TableHelper.advancedFilteringSanitization(filter, this.columns)
      // merge new exact matches into one array (to support multiple)
      if (Array.isArray(this.filters.exact_match) && Array.isArray(sanitized.exact_match)) {
        sanitized.exact_match = [...this.filters.exact_match, ...sanitized.exact_match]
      }
      this.filters = { ...this.filters, ...sanitized }
      this.$store.dispatch(`${this.stateName}/setAdvancedFilters`, this.filters)
    },
    orderBy(column) {
      if (column.sortable !== false) {
        this.$store.dispatch(`${this.stateName}/setActivePage`, 1)
        const orderByField = column.order_key ? column.order_key : column.field
        if (this.order === null || this.order.orderBy !== orderByField) {
          this.order = { orderBy: orderByField, ascending: 0 }
        } else {
          const newOrder = this.order.ascending === 1 ? 0 : 1
          this.order = { orderBy: orderByField, ascending: newOrder }
        }
        this.$store.dispatch(`${this.stateName}/setOrder`, this.order)
      }
    },
    resetFilters() {
      this.$store.dispatch(`${this.stateName}/setSearchQuery`, '')
      this.filters = {}
      this.$store.dispatch(`${this.stateName}/setActivePage`, 1)
      this.$store.dispatch(`${this.stateName}/setAdvancedFilters`, {})
    },
    paginationClick(page) {
      this.$store.dispatch(`${this.stateName}/setActivePage`, page)
      this.search()
    },
    makeRequest(params = {}) {
      this.controller.abort()

      this.controller = new AbortController()

      const searchParams = Object.assign(this.searchParams, params)
      searchParams.page = this.activePage
      this.lastSearchParams = searchParams
      let response = axios.get(this.path, {
        params: searchParams,
        paramsSerializer: (params) => {
          return qs.stringify(params)
        },
        signal: this.controller.signal,
      })
      this.lastSearchParams.limit = this.activeLimit
      return response
    },
    async search(setActivePage = false) {
      if (!this.path) return
      this.$store.commit(`${this.stateName}/setIsLoading`, true)
      if (setActivePage) {
        this.$store.dispatch(`${this.stateName}/setActivePage`, 1)
      }
      await this.makeRequest()
        .then((response) => {
          if (response.data.success) {
            this.rows = response.data.result
            this.totalRows = response.data.count
          } else if (response.data.messages) {
            this.showErrorMessages(response.data.messages)
          }
        })
        .catch(this.showUnknownErrorMessage)
        .finally(() => {
          this.$store.commit(`${this.stateName}/setIsLoading`, false)
        })
    },
    setCustomActionButtonLoading(loading = false) {
      this.$store.dispatch(`${this.stateName}/setCustomActionButtonLoading`, loading)
    },
    setCustomSecondaryButtonLoading(loading = false) {
      this.$store.dispatch(`${this.stateName}/setCustomSecondaryButtonLoading`, loading)
    },
    deselectBulk() {
      this.bulkSelected = []
    },
    bulkToggleVisibleRows(checked) {
      const currentIds = this.rows.map((row) => row.id)
      this.bulkToggleAll = checked
      this.bulkSelected = _.uniq([...this.bulkSelected, ...currentIds])
    },
    bulkEditClick() {
      this.$emit('bulkClickByIds', this.bulkSelected, this.bulkSelectedAllItems, this.filters, this.totalRows)
    },
    async remove(row) {
      this.$store.commit(`${this.stateName}/setIsLoading`, true)
      if (this.removeClick) {
        this.removeClick(row)
        return
      }

      if (
        this.granular
          ? await confirmGranularDelete(this.extraDeleteInfo.title, this.extraDeleteInfo.message, row.id)
          : await confirmDelete(
              this.extraDeleteInfo.title,
              'Are you sure you want to delete this item? ' + this.extraDeleteInfo.message,
              this.extraDeleteInfo.extraMessage,
              this.extraDeleteInfo.boldText
            )
      ) {
        await this.serviceLoader()
          .then((service) => {
            return service.default.remove(row)
          })
          .then((response) => {
            if (this.model === 'cms/PageCustomHreflang') {
              this.$emit('updated-date')
            }
            if (response.data.messages) {
              this.showErrorMessages(response.data.messages)
            } else {
              this.showSuccessMessage('Item removed')
              this.search()
            }
          })
          .catch(this.showUnknownErrorMessage)
          .finally(() => {
            this.$store.commit(`${this.stateName}/setIsLoading`, false)
          })
      }
    },
    isScrollableModel() {
      const models = [
        'koala/Accounts',
        'koala/Campaign',
        'koala/KoalaCampaign',
        'koala/Monitoring',
        'koala/Operators',
        'koala/Repull',
        'AffiliateLink',
        'TopList',
        'widgets/odds/odds',
        'koala/AccountReports',
        'koala/GroupedMonitoring',
        'koala/AccountReports',
      ]

      return models.includes(this.model)
    },
  },
}
</script>

<style scoped>
.opacity-50 {
  opacity: 0.5;
}

.table {
  transition: all 0.5s ease-in-out;
  width: 100%;
  margin-left: unset !important;
}

.table tbody tr td {
  padding: 0.65rem;
}

.table.white-space-no-wrap tbody tr td {
  white-space: nowrap;
}

.table tbody tr td.width-full {
  width: 100%;
  white-space: break-spaces;
  word-break: break-all;
}

.table thead tr:not(.filter-row) th {
  padding: 16px 12px;
}

.table thead tr:not(.filter-row) th:first-child {
  padding: 16px 20px;
}

.scrollable-table table {
  width: 100% !important;
  table-layout: auto;
}

/* .scrollable-table table th,
.scrollable-table table td {
  max-width: 370px;
  min-width: 150px
} */

.scrollable-table div.card-body {
  overflow-x: auto;
}

div.card-body {
  padding: 24px 0;
}

.tracking-links-table div.card-body {
  min-height: calc(100vh - 500px);
}

.bulk-col {
  padding: 0 !important;
}
</style>
