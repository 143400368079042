<template>
  <div class="row d-flex justify-content-center">
    <div class="card col-12 col-xl-6">
      <div class="card-header">
        <h3 class="card-title">Purge Cloudflare Cache</h3>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-7">
            <base-select label="Site" v-model="site" :customOptions="domains" />
          </div>
        </div>
        <span class="callout callout-warning mt-2">
          Avoid purging the cache unnecessarily. This action will clear the cache on all site environments
          (test + live).
        </span>
      </div>
      <div class="card-footer">
        <button
          class="btn btn-primary float-right mr-1"
          v-bind:class="[{ loading: loadingPurge }, { disabled: site === null }]"
          @click="purgeCache()"
        >
          <i class="uil uil-cloud-wind" />Purge Cache
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import BaseSelect from '@/components/fields/base-select.vue'
import CloudflareService from '@services/devops/CloudflareService.js'

export default {
  data() {
    return {
      site: null,
      loadingPurge: false,
      domains: [],
    }
  },
  components: { BaseSelect },
  mounted() {
    this.setTitle()
    this.getDomains()
  },
  methods: {
    purgeCache() {
      this.loadingPurge = true
      CloudflareService.purge({ domain: this.site })
        .then((response) => {
          this.loadingPurge = false
          response.data.messages
            ? this.showErrorMessages(response.data.messages)
            : this.showSuccessMessage('Cloudflare purged successfully')
        })
        .catch(this.showUnknownErrorMessage)
        .finally(() => {
          this.loadingPurge = false
        })
    },
    getDomains() {
      CloudflareService.getDomains()
        .then((response) => {
          this.domains = response.data.result
        })
        .catch(() => {
          this.showErrorMessage('Sites not found')
        })
    },
  },
}
</script>
