<template>
  <div class="card">
    <dynamic-form
      ref="form"
      formType="create"
      :formFields="structure.list"
      :structure="structure"
      :object="object"
      :on-submit="submit"
      :path="config.url"
      :inCard="true"
      :customCreateActionButton="{
        action: submitAndCreateLink,
        title: 'Submit and Create Link',
        icon: 'uil uil-link-add',
        roles: [],
      }"
    />
  </div>
</template>

<script setup>
import { ref, reactive, getCurrentInstance } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import AffiliateOperator from '@models/AffiliateOperator.js'
import AffiliateOperatorsService from '@services/AffiliateOperatorsService.js'

const route = useRoute()
const router = useRouter()
const operatorId = ref(route.params.id)
const object = ref({})
const structure = reactive({ list: AffiliateOperator.getStructure(operatorId.value)?.list })
const config = AffiliateOperator.getConfig()

const form = ref(null)

const { proxy } = getCurrentInstance()

const submit = async (redirect = true) => {
  if (form.value) {
    form.value.isLoading = true
  }

  const submissionData = {
    ...object.value,
    operator_id: operatorId.value,
  }

  let response
  try {
    response = await AffiliateOperatorsService.post(submissionData)

    if (response.data.messages) {
      proxy.showErrorMessages(response.data.messages)
    } else {
      proxy.showSuccessMessage('Sites data created successfully')
      object.value = {}
      if (redirect) {
        router.push(`/operators/${route.params.id}/sites-data/edit/${response.data.result.id}`)
      }
    }
  } catch (error) {
    proxy.showUnknownErrorMessage()
  } finally {
    if (form.value) {
      form.value.isLoading = false
    }
  }

  return response
}

const submitAndCreateLink = async () => {
  const response = await submit(false)

  if (response && response.data && response.data.result && response.data.result.id) {
    const id = response.data.result.id
    router.push(`/operators/${operatorId.value}/sites-data/links/${id}`)
  }
}
</script>
