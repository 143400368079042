<template>
  <data-table
    ref="list"
    :path="`hercules/toplists/prefilled-lists/${this.$route.params.id}/prefilled?with=site,market`"
    :columns="columns()"
    :column-templates="columnTemplates()"
    :enableRowClick="false"
    :showRemove="false"
    :loadOnMount="true"
    :showCreate="false"
  />
</template>

<script lang="jsx">
import TableColumnTemplate from '@constants/table-column-template.js'
import ToplistEdit from '@atoms/table/cell/toplist-edit.vue'

export default {
  methods: {
    columns() {
      return [
        { field: 'site.name', type: 'text', filterable: true, label: 'Site' },
        { field: 'market.label', type: 'text', filterable: true, label: 'Market' },
        { field: 'label', type: 'text', filterable: true },
        {
          field: 'short_code',
          type: 'text',
          filterable: true,
          label: 'Short code',
          template: TableColumnTemplate.TEXT_TRUNCATE,
        },
        {
          field: 'updated_at',
          label: 'Updated',
          type: 'text',
          filterable: true,
          template: TableColumnTemplate.RELATIVE_DATE,
        },
      ]
    },
    columnTemplates() {
      return Object.assign({
        label: function (row) {
          return <ToplistEdit label={row.label} id={row.id} />
        },
      })
    },
  },
  components: { ToplistEdit },
}
</script>
