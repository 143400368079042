<template>
  <div>
    <div class="row">
      <div class="col">
        <sidebar-filter
          ref="sidebar"
          :show-is-past-events="true"
          :show-site="false"
          :show-date="false"
          :showMarkets="false"
        />
        <data-table
          ref="list"
          model="cms/Template"
          service-file="TemplatesService"
          :path="path()"
          :columns="columns()"
          :enableRowClick="false"
          :column-templates="columnTemplates()"
          :show-remove="false"
          :show-create="false"
          :extraComponentHeight="sidebarHeight"
        />
      </div>
    </div>
  </div>
</template>

<script>
import SidebarFilter from '@molecules/sports/sports-filter.vue'
import TableColumnTemplate from '@constants/table-column-template.js'

export default {
  created() {
    this.setTitle()
  },
  mounted() {
    this.sidebarHeight = this.$refs.sidebar.$el.clientHeight
  },
  data() {
    return {
      sidebarHeight: 0,
    }
  },
  computed: {
    sportId() {
      return this.$store.state.sidebar.sportId
    },
    showPastEvents() {
      return this.$store.state.sidebar.showPastEvents
    },
  },
  watch: {
    sportId() {
      this.applyFilters()
    },
    showPastEvents() {
      this.applyFilters()
    },
  },
  components: { SidebarFilter },
  methods: {
    path() {
      return `hercules/sports/events?${this.buildQueryString(
        Object.assign(
          {
            with: ['tournament,tournament.country,sport,home_team,away_team', 'providers'].join(','),
            hide_past_events: this.showPastEvents === true ? 0 : 1,
          },
          this.getFilters()
        )
      )}`
    },
    columns() {
      return [
        {
          field: 'id',
          type: 'text',
          order_key: 'id',
          search_key: 'id',
          label: 'ID',
          filterable: true,
          sortable: true,
        },
        {
          field: 'sport.name',
          search_key: 'sport__name',
          order_key: 'sport__name',
          type: 'text',
          label: 'Sport',
          filterable: true,
        },
        {
          field: 'tournament.country.code',
          search_key: 'tournament__country__code',
          order_key: 'tournament__country__code',
          type: 'text',
          label: 'Country',
          filterable: true,
        },
        {
          field: 'tournament.name',
          search_key: 'tournament__name',
          order_key: 'tournament__name',
          type: 'text',
          filterable: true,
          label: 'Tournament',
        },
        {
          field: 'home_team.name',
          search_key: 'home_team',
          order_key: 'home_team__name',
          type: 'text',
          filterable: true,
          label: 'Home team',
        },
        {
          field: 'away_team.name',
          search_key: 'away_team',
          order_key: 'away_team__name',
          type: 'text',
          filterable: true,
          label: 'Away team',
        },
        {
          field: 'date',
          type: 'text',
          filterable: true,
          label: 'Date',
        },
        {
          field: 'time',
          type: 'text',
          filterable: true,
          label: 'Time',
        },
        { field: 'updated_at', type: 'text', filterable: false, template: TableColumnTemplate.RELATIVE_DATE },
        {
          field: 'status',
          label: 'Status',
          type: 'text',
          filterable: true,
          template: TableColumnTemplate.EVENT_STATUS,
        },
        {
          field: 'providers',
          template: TableColumnTemplate.TEXT_TRUNCATE_TOOLTIP,
          label: 'Providers',
          type: 'text',
          filterable: true,
        },
      ]
    },
    columnTemplates() {
      return {
        'tournament.country.code': function (row) {
          if (row.tournament.country.code === 'international') {
            return 'INT'
          }
          return this.upperCase(row.tournament.country.code)
        },
        time: function (row) {
          return row.time.substr(0, 5)
        },
        providers: function (row) {
          return row.providers.length
        },
      }
    },
    applyFilters() {
      this.emitter.emit('data-table.applyFilters', this.getFilters())
    },
    getFilters() {
      let filters = {}
      if (this.sportId) filters['sport__id'] = this.sportId
      if (this.showPastEvents === true) filters['hide_past_events'] = 0
      return filters
    },
  },
}
</script>
