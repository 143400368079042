<template>
  <div class="flex-row">
    <base-input
      :label="label"
      :labelAction="labelAction"
      :labelActionTitle="labelActionTitle"
      :helpText="helpText"
      v-model="moduleField.value"
    />
    <button
      v-if="isYoutubeLink(moduleField.value)"
      class="btn btn-primary"
      v-on:click="refreshMetaData(moduleField.value)"
    >
      Refresh Meta Data
    </button>
  </div>
</template>

<script>
import BaseInput from '@atoms/fields/base-input.vue'
import Api from '@services/Api.js'

export default {
  name: 'iframe-component',
  components: {
    BaseInput,
  },
  data() {
    return {}
  },
  props: {
    label: {
      type: String,
    },
    labelActionTitle: {
      type: String,
    },
    labelAction: {
      type: Function,
    },
    helpText: {
      type: String,
    },
    value: {
      type: [String, Number],
      default: '',
    },
    moduleField: {
      required: false,
      default: () => null,
    },
    moduleValueFields: {
      required: false,
      default: () => null,
    },
  },
  methods: {
    refreshMetaData(url) {
      this.moduleValueFields.forEach((moduleValueFiled) => {
        if (moduleValueFiled.field.name === 'iframe_video_object_schema') {
          Api()
            .get(`youtube?videoId=${this.getYouTubeVideoId(url)}`)
            .then((response) => {
              moduleValueFiled.value = JSON.stringify(response.data)
            })
            .catch(() =>
              this.showErrorMessage(`Failed to load information for the video.
          Please check the YouTube link.`)
            )
        }
      })
    },
    isYoutubeLink(link) {
      const youtubeRegex =
        /^(?:https?:\/\/)?(?:m\.|www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(\?\S*)?$/

      let isAYouTubeLink = youtubeRegex.test(link)

      if (isAYouTubeLink) {
        this.refreshMetaData(link)
      }

      return isAYouTubeLink
    },
    getYouTubeVideoId(url) {
      const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/
      const match = url.match(regExp)

      return match && match[7].length === 11 ? match[7] : false
    },
  },
}
</script>
