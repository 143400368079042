<template>
  <div div class="card">
    <div class="card-header border-bottom d-flex justify-content-between align-items-center">
      <div class="justify-content-start align-self-center">
        <strong>{{ realTitle }}</strong>
      </div>
      <div class="text-right">
        <span :class="badgeClass">{{ $capitalize(menuItem.type) }}</span>
      </div>
    </div>
    <div class="card-body pt-3 pb-2">
      <div class="form-group">
        <base-radio
          label="Link type"
          name="type"
          :modelValue="menuItem.type"
          :data="['page', 'link', 'label']"
          @update:modelValue="(option) => typeChange(option.value)"
          :inlineLayout="true"
        />
      </div>
      <div class="form-group">
        <model-select
          v-if="menuItem.type === 'page'"
          :parameters="{ market_id: marketId, site_id: siteId, exclude_status: 'draft' }"
          label="Selected page"
          ref="modelSelect"
          modal-size-class="modal-lg"
          model="page"
          :loadOnMount="false"
          :value="menuItem.value === '' ? null : menuItem.value"
          :value-label="menuItem.page_title"
          @valuePicked="pageValuePicked"
        />
      </div>
      <div class="form-group">
        <base-input v-if="menuItem.type === 'link'" label="Link value" v-model="menuItem.value" />
      </div>
      <div class="form-group">
        <base-input :label="menuItem.type === 'page' ? 'Custom title' : 'Title'" v-model="menuItem.text" />
      </div>
      <div class="form-group">
        <base-input label="Label" v-model="menuItem.label" />
      </div>
      <div class="form-group">
        <base-checkbox
          v-if="(!menuItem.type === 'page') | 'label'"
          label="Nofollow"
          v-model="menuItem.nofollow"
        />
        <base-checkbox
          v-if="(!menuItem.type === 'page') | 'label'"
          label="Open in new tab"
          v-model="menuItem.open_in_new_tab"
        />
      </div>
      <div class="form-group">
        <media label="Image" v-model="menuItem.image_id" :siteSpecific="true" />
      </div>
    </div>
    <div class="card-footer border-top pt-3 text-right">
      <button
        v-if="hasPermission('delete.sites/menus')"
        :disabled="showColaborativeNotification"
        class="btn btn-danger mr-2"
        v-bind:class="{ loading: loading }"
        @click="remove"
      >
        <i class="uil uil-trash mr-2"></i>Delete
      </button>
      <button
        class="btn btn-primary mr-2"
        :disabled="showColaborativeNotification"
        v-bind:class="{ loading: loading }"
        @click="update"
      >
        Submit
      </button>
    </div>
  </div>
</template>
<script>
import ModelSelect from '@molecules/fields/model-select/base-model-select.vue'
import BaseInput from '@atoms/fields/base-input.vue'
import BaseCheckbox from '@atoms/fields/base-checkbox.vue'
import BaseRadio from '@atoms/fields/base-radio.vue'
import Media from '@molecules/fields/media.vue'
import MenusService from '@services/MenusService.js'

export default {
  components: { BaseInput, Media, ModelSelect, BaseRadio, BaseCheckbox },
  data() {
    return {
      showDetails: false,
      loading: false,
    }
  },
  computed: {
    realTitle() {
      if (this.menuItem.type === 'page') {
        return this.menuItem.text ? this.menuItem.text : this.menuItem.page_title
      }
      return this.menuItem.text
    },
    badgeClass() {
      let badgeClass = ['badge']
      if (this.menuItem.type === 'page') badgeClass.push('badge-light')
      if (this.menuItem.type === 'label') badgeClass.push('badge-success')
      if (this.menuItem.type === 'link') badgeClass.push('badge-info')
      return badgeClass.join(' ')
    },
  },
  props: {
    menuItem: {
      type: Object,
      required: true,
    },
    siteId: {
      type: Number,
      required: true,
    },
    marketId: {
      type: Number,
      required: true,
    },
    showColaborativeNotification: {
      type: Boolean,
    },
  },
  methods: {
    async update() {
      if (this.showColaborativeNotification) {
        return
      }
      this.loading = true
      await MenusService.update(this.$parent.menu)
        .then((response) => {
          this.menu = response.data.result
          this.$emit('updateItem', this.$parent.menu)
          this.showSuccessMessage('Menu item saved')
        })
        .catch(this.showUnknownErrorMessage)
        .finally(() => {
          this.loading = false
        })
    },
    remove() {
      this.$emit('delete', this.menuItem.id)
    },
    typeChange(type) {
      this.menuItem.value = null
      this.menuItem.type = type
      if (type == 'page') {
        this.menuItem.nofollow = false
        this.menuItem.open_in_new_tab = false
      }
      this.$nextTick(() => {
        if (type === 'page') {
          this.$refs.modelSelect.clearAndEnable()
        }
      })
    },
    pageValuePicked(value, label) {
      this.menuItem.value = value
      this.value = value
      this.menuItem.text = null
      if (value === null) {
        this.menuItem.title = null
        this.menuItem.page_title = null
      } else {
        this.menuItem.page_title = label
      }
    },
  },
}
</script>

<style scoped>
.card:hover {
  border-color: #969696;
}
</style>
