<template>
  <div class="row">
    <div class="col">
      <sidebar-filter ref="sidebar" :show-sports="false" :showMarkets="false" />
      <data-table
        v-if="siteId"
        model="sport/SiteSport"
        service-file="SiteSportsService"
        :extraDeleteInfo="{
          title: 'Careful, this is a destructive action!',
          message: 'All related event pages (and their content) will be deleted.',
        }"
        :enableRowClick="false"
        ref="list"
        :path="path()"
        :columns="columns()"
        :create-defaults="createDefaults()"
        :extraComponentHeight="sidebarHeight"
      />
    </div>
  </div>
</template>

<script>
import SidebarFilter from '@molecules/sports/sports-filter.vue'
import TableColumnTemplate from '@constants/table-column-template.js'

export default {
  created() {
    this.setTitle()
  },
  mounted() {
    this.sidebarHeight = this.$refs.sidebar.$el.clientHeight
  },
  data() {
    return {
      sidebarHeight: 0,
      site: null,
    }
  },
  components: { SidebarFilter },
  computed: {
    siteId() {
      return this.$store.state.sidebar.siteId
    },
  },
  watch: {
    siteId() {
      this.applyFilters()
    },
  },
  methods: {
    path() {
      return `hercules/sports/site-sports?with=sport,site&site_id=${this.siteId}`
    },
    columns() {
      return [
        { field: 'sport.name', label: 'Sport', type: 'text', filterable: true },
        { field: 'site.name', type: 'text', label: 'Site', filterable: true },
        { field: 'updated_at', type: 'text', filterable: true, template: TableColumnTemplate.RELATIVE_DATE },
      ]
    },
    applyFilters() {
      let filters = {}
      if (this.siteId) filters['site_id'] = this.siteId

      this.emitter.emit('data-table.applyFilters', filters)
    },
    createDefaults() {
      return {
        site_id: this.siteId,
      }
    },
  },
}
</script>
