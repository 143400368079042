export default {
  getForm() {
    return [
      {
        name: 'site_id',
        search_url: 'hercules/operators/affiliates',
        required: true,
        display_name: 'Site',
        type: 'list-one',
        selectLabel: 'website',
        value_key: 'site_name',
      },
      {
        name: 'title',
        required: true,
        display_name: 'Title',
        type: 'text',
        slug_field: 'short_code',
      },
      {
        name: 'short_code',
        required: true,
        display_name: 'Short code',
        type: 'slug',
        disabled: true,
      },
      {
        name: 'type',
        display_name: 'Type',
        type: 'list-one',
        trackBy: null,
        selectLabel: null,
        required: true,
        options: [
          'Bonus',
          'Toplist',
          'Comparison',
          'Bet Calculator',
          'Wagering Calculator',
          'Surebet Calculator',
          'Asian Handicap Line Calculator',
        ],
      },
      {
        name: 'style',
        required: false,
        type: 'text',
        value_modifier: function (value) {
          if (typeof value !== 'undefined') {
            value = value.toLowerCase()
            value = value.replace(/[^A-Za-z0-9\-\s]/g, '')
            value = value.replace(/[\s+]/g, '-')
            return value
          }
          return ''
        },
      },
      {
        name: 'show_more',
        type: 'checkbox',
        display_name: 'Show more options',
        switch: true,
        save_in_store: true,
      },
      {
        name: 'geo_located',
        type: 'checkbox',
        display_name: 'Enabled',
        display_name_two: 'Geo Targeted widget',
        switch: true,
        show_if: { field: 'show_more', value: [true, 1] },
      },
      {
        name: 'market_id',
        search_url: 'hercules/globals/markets?ids=11,17&bypass_market_restrictions=1',
        required: true,
        display_name: 'Show widget in',
        type: 'list-one',
        value_key: 'market.label',
        selectLabel: 'label',
        help_text: 'Widget will show only for users in designated areas',
        show_if: { field: 'show_more', value: [true, 1] },
      },
      {
        name: 'pretty_link',
        required: false,
        display_name: 'Pretty Link',
        type: 'text',
        show_if: { field: 'show_more', value: [true, 1] },
      },
      {
        name: 'star_rating_color',
        required: false,
        display_name: 'Star Rating Color',
        type: 'colour_picker',
        show_if: { field: 'show_more', value: [true, 1] },
      },
      {
        name: 'components',
        required: false,
        search_url: 'hercules/widgets/components?widget_type=toplist',
        display_name: 'Components Order',
        type: 'list-many-sortable',
        show_if: { field: 'show_more', value: [true, 1] },
      },
      {
        name: 'num_items_initial_load',
        required: false,
        display_name: 'Number of items on initial load',
        help_text: 'Use 0 for no limit',
        min: 0,
        type: 'number',
        show_if: { field: 'show_more', value: [true, 1] },
      },
      {
        name: 'num_items_load_more',
        required: false,
        display_name: 'Number of items on load more',
        help_text: 'Use 0 for no limit',
        min: 0,
        type: 'number',
        show_if: { field: 'show_more', value: [true, 1] },
      },
    ]
  },
  getConfig() {
    return {
      url: 'widgets/toplist',
      name: 'Widget',
      pagination: true,
    }
  },
  getStructure() {
    return {
      list: this.getForm(),
      config: this.getConfig(),
    }
  },
}
