<template>
  <div
    class="card"
    :class="['card', 'border', row.removed && 'border-danger', row.added && 'border-success']"
  >
    <div class="card-body d-flex flex-column flex-xl-row py-3 row-main-body">
      <div class="d-flex align-items-center justify-center mx-auto mx-xl-0 pr-4 mr-3">
        <div class="badge badge-light h1 row-drag">
          {{ index + 1 }}
        </div>
        <!-- <i class="uil uil-grip-horizontal-line"></i> -->
      </div>
      <div class="d-flex flex-column border-right-xl pr-xl-4 justify-content-center">
        <div class="d-flex align-items-center">
          <div class="font-weight-bold h5">From</div>
          <!-- <base-checkbox :switch="true" label="Group" class="ml-3" v-model="row.is_group" /> -->
        </div>
        <div class="d-flex align-items-end">
          <div>Location</div>
          <base-checkbox
            :switch="true"
            label="Group"
            class="mb-0"
            wrapperClass="ml-3"
            v-model="row.is_group"
            @update:modelValue="rowOrGroupChanged"
          />
        </div>
        <div class="mt-2">
          <base-select-joost-wip
            v-if="row.is_group"
            :customOptions="availableGroups"
            :loadOnMount="false"
            optionLabelKey="name"
            class="mb-0"
            v-model="row.affiliate_location_group_id"
            :modelValue="row.affiliate_location_group_id"
            :capitalizeLabel="false"
            placeholder="Select group"
          />
          <base-select-joost-wip
            v-else
            :customOptions="availableCountries"
            :loadOnMount="false"
            optionLabelKey="name"
            class="mb-0"
            v-model="row.country_id"
            :capitalizeLabel="false"
            :modelValue="row.country_id"
            placeholder="Select location"
          />
        </div>
        <!-- <button class="btn btn-info" v-if="row.country">
              {{ row.country.name }}
            </button> -->
        <!-- <button class="btn btn-info" @click="selectRuleLocation(row)" v-else>
              Select a location
            </button> -->
      </div>
      <div class="ml-0 ml-xl-4 d-flex flex-column justify-content-between mt-3 mt-xl-2">
        <div class="d-flex align-items-center">
          <div class="font-weight-bold h5 mb-0">Rules</div>
          <div v-if="isOverwritten" class="ml-4 text-danger border border-danger px-2 py-1 rounded">
            {{ isOverwritten }}
          </div>
        </div>
        <div class="d-flex flex-column flex-xl-row mt-2 justify-content-center align-items-center flex-wrap">
          <!-- <div class="d-flex justify-content-between align-items-center mr-2">
            <i class="uil uil-circle h1 text-muted"></i>
          </div> -->
          <div class="d-flex flex-column flex-xl-row mb-xl-3" v-for="(rule, index) in row.rules" :key="index">
            <affiliate-link-logic-row-rule
              :row="row"
              :groups="groups"
              :markets="markets"
              :countries="countries"
              :rule="rule"
              @removeRule="removeRule(index)"
            />
            <div class="d-flex justify-content-between align-items-center mx-auto mx-xl-1">
              <i class="uil uil-angle-double-right h1 text-muted" v-if="index < 2"></i>
              <span class="ui-span text-muted ml-2" v-if="index == 2"></span>
            </div>
          </div>
          <div v-if="row.rules.length === 0 || row.rules.length < 3">
            <button class="btn btn-outline-secondary" @click="addRule(row)">Add a new rule</button>
          </div>
        </div>
      </div>
      <div class="float-left ml-auto mt-3 mt-xl-0">
        <button class="btn btn-secondary icon-button" v-if="row.removed" @click="deleteRow">
          <i class="uil uil-redo" />
        </button>
        <button class="btn btn-danger icon-button" v-else @click="deleteRow">
          <i class="uil uil-trash-alt"></i>
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import { sample } from 'lodash'
import BaseCheckbox from '@atoms/fields/base-checkbox.vue'
import BaseSelectJoostWip from '@/components/fields/base-select-joost-wip.vue'
import AffiliateLinkLogicRowRule from './affiliate-link-logic-row-rule.vue'

export default {
  components: { BaseCheckbox, BaseSelectJoostWip, AffiliateLinkLogicRowRule },
  props: {
    row: {},
    rows: {},
    groups: {},
    index: {},
    countries: {},
    markets: {},
  },
  computed: {
    availableCountries() {
      let countriesUsedByOtherRules = this.row.rules.flatMap((item) => {
        if (item.country_id && item.based_on_row == false) {
          if (item.country_id == this.country_id) {
            return []
          }

          return item.country_id
        }
        return []
      })

      return this.countries.filter((item) => {
        if (countriesUsedByOtherRules.includes(item.id)) {
          return false
        }
        return true
      })
    },
    availableGroups() {
      let groupsUsedByOtherRules = this.rows.flatMap((item) => {
        if (item.affiliate_location_group_id == this.row.affiliate_location_group_id) {
          return []
        }
        if (item.affiliate_location_group_id) {
          return item.affiliate_location_group_id
        }
        return []
      })

      return this.groups.filter((item) => {
        if (groupsUsedByOtherRules.includes(item.id)) {
          return false
        }
        return true
      })
    },
    isGroup() {
      return this.row.is_group
    },
    isBeingDeleted() {
      return this.row.removed
    },
    isOverwritten() {
      let error = null

      this.rows.find((row, index) => {
        if (this.index < index) return
        if (this.row.id === row.id) return

        // country id is the same
        if (this.country_id !== null && row.country_id === this.row.country_id) {
          const country = this.countries.find((item) => item.id === row.country_id)
          if (country) {
            error = `Same country (${country.name}) is used in rule #${index + 1}`
          }

          return
        }

        // country id is in the group
        if (this.country_id !== null && row.affiliate_location_group_id) {
          const group = this.groups.find((item) => item.id === row.affiliate_location_group_id)
          const countryMatch = group.locations.find((item) => item.id === this.row.country_id)
          if (countryMatch) {
            error = `Same country (${countryMatch.name}) is part of group ${group.name} in rule #${index + 1}`
            return
          }
        }
      })

      return error
    },
  },
  methods: {
    rowOrGroupChanged(isGroup) {
      if (isGroup) {
        this.row.country_id = null
        this.row.country = null
      } else {
        this.row.affiliate_location_group_id = null
        this.row.rules.forEach((rule) => (rule.based_on_row = false))
      }
    },
    addRule(row) {
      row.rules.push({
        market_id: null,
        based_on_row: false,
        affiliate_link_logic_row_id: null,
      })
    },
    removeRule(index) {
      this.row.rules.splice(index, 1)
    },
    toggleBasedOnRow(rule) {
      rule.based_on_row = !rule.based_on_row
    },
    deleteRow() {
      this.$emit('deleteRow', this.index)
    },
    selectMarket(row) {
      const randomMarket = sample(this.markets)
      row.rules.push({
        market_id: randomMarket.id,
        market: randomMarket,
        affiliate_link_logic_row_id: row.id,
      })
    },
    selectRuleLocation(row) {
      const randomLocation = sample(this.countries)
      row.country_id = randomLocation.id
      row.country = randomLocation
    },
    mapAndSetData(data) {
      data.rows.forEach((row) => {
        row.removed = false
        row.added = false
      })
      this.data = data
    },
  },
}
</script>

<style scoped>
.ui-span {
  width: 0;
}
@media (min-width: 1200px) {
  .border-right-xl {
    border-right: 1px solid #dee2e6;
  }
  .ui-span {
    width: 22px;
  }
}
.row-drag {
  cursor: grab;
}
.row-main-body {
  cursor: auto;
}
.link-rule-column {
  width: 300px;
}
</style>
