<template>
  <div class="modal" ref="modal" tabindex="-1" role="dialog" v-click-outside="close">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Clone site</h5>
          <button class="close" v-on:click="close"></button>
        </div>
        <div class="modal-body">
          <p class="text-muted">
            <i class="uil uil-info-circle mr-1" /> Event pages are <strong>not</strong> created automatically.
          </p>
          <dynamic-form
            form-type="edit"
            v-if="object"
            ref="form"
            :formFields="structure.list"
            path="sites/sites"
            :structure="structure"
            :object="object"
            :on-submit="submit"
            submit-label="Clone"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SitesService from '@services/SitesService.js'
import DynamicForm from '@/components/fields/DynamicForm.vue'
import SiteClone from '@models/SiteClone.js'

export default {
  components: { DynamicForm },
  data() {
    return {
      loading: false,
      object: {},
      structure: {},
      config: SiteClone.getConfig(),
    }
  },
  mounted() {
    document.getElementById('modal').appendChild(this.$el)
  },
  methods: {
    show(object) {
      this.structure = SiteClone.getStructure()
      this.object = {
        source_site_id: object.id,
        source_site: object.name,
        name: 'https://',
      }
      this.$refs.modal.classList.add('show')
    },
    close() {
      this.$refs.modal && this.$refs.modal.classList.remove('show')
      this.object = null
    },
    submit() {
      SitesService.clone(this.object)
        .then((response) => {
          if (response.data.messages) {
            this.showErrorMessages(response.data.messages)
          } else {
            this.showSuccessMessage('Site cloned')
            this.$emit('refresh')
            this.close()
          }
          this.$refs.form.loading(false)
        })
        .catch(() => {
          this.showUnknownErrorMessage
          this.$refs.form.loading(false)
        })
    },
  },
}
</script>
