<template>
  <div>
    <div class="row">
      <div class="col">
        <div class="card">
          <div class="card-header">
            <h3>Event data</h3>
            <hr class="hr-fat mb-0" />
          </div>
          <div class="card-body modal-form-two-column" v-if="relation">
            <base-input
              v-for="(value, label) in getFields()"
              :key="label"
              :label="$prettyLabels(label)"
              :modelValue="findInObject(value.split('.'), relation)"
              disabled
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import AbstractService from '@services/AbstractService.js'
import BaseInput from '@atoms/fields/base-input.vue'

export default {
  components: { BaseInput },
  data() {
    return {
      relation: null,
    }
  },
  props: {
    model: {
      required: true,
    },
    id: { required: true },
  },
  mounted() {
    this.getRelation()
  },
  methods: {
    getFields() {
      if (this.model === 'site_event') {
        return {
          home_team: 'event.home_team.name',
          away_team: 'event.away_team.name',
          sport: 'event.sport.name',
          status: 'event.status',
          tournament: 'event.tournament.name',
          country: 'event.tournament.country.name',
          date: 'event.date',
          time: 'event.time',
          providers: 'providers',
          pushed_to_ram_at: 'pushed_to_ram_at',
          created_at: 'event.created_at',
          updated_at: 'event.updated_at',
          event_id: 'event.id',
          site_event_id: 'id',
        }
      }
    },
    getWith() {
      if (this.model === 'site_event') {
        return [
          'event',
          'event.home_team',
          'event.away_team',
          'event.tournament',
          'event.tournament.country',
          'event.sport',
          'providers',
        ]
      }
    },
    getPath() {
      if (this.model === 'operator') return 'hercules/operators/affiliate-operators'
      if (this.model === 'banner') return 'hercules/sites/banners'
      if (this.model === 'game') return 'hercules/games/games'
      if (this.model === 'site_event') return 'hercules/sports/site-events'
      if (this.model === 'crypto_brokers') return 'hercules/cryptos/brokers'
      if (this.model === 'crypto_exchanges') return 'hercules/cryptos/exchanges'
      if (this.model === 'crypto_wallets') return 'hercules/cryptos/wallets'

    },
    getRelation() {
      AbstractService.get(this.getPath(this.type), { id: this.id, with: this.getWith().join(',') })
        .then((response) => {
          if (response.data.success && response.data.result.length === 1) {
            this.relation = response.data.result[0]
          } else {
            this.showErrorMessage('Failed to fetch relational data')
          }
        })
        .catch(() => this.showErrorMessage('Failed to fetch relational data'))
    },
  },
}
</script>
