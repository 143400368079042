<template>
  <nav class="navbar navbar-expand-md mb-1">
    <ol class="breadcrumb">
      <li class="breadcrumb-item" v-for="(breadcrumb, key) in breadcrumbs" :key="key">
        <router-link :to="isValidRouteName(breadcrumb) ? { name: breadcrumb } : ''">
          {{ breadcrumb }}
        </router-link>
      </li>
    </ol>

    <div class="collapse navbar-collapse">
      <ul class="navbar-nav navbar-nav ml-auto">
        <li v-if="hasPermission('view.logs/activity-logs') && !maintenanceEnabled" class="nav-item">
          <router-link
            tag="span"
            :to="{ path: '/activity-logs' }"
            class="nav-link"
            active-class
            data-tooltip="Activity logs"
            data-tooltip-position="bottom"
          >
            <i class="uil uil-history"></i>
          </router-link>
        </li>

        <li v-if="hasPermission('view.logs/logs') && !maintenanceEnabled" class="nav-item">
          <router-link
            tag="span"
            :to="{ path: '/logs' }"
            class="nav-link"
            active-class
            data-tooltip="Listener logs"
            data-tooltip-position="bottom"
          >
            <i class="uil uil-list-ul"></i>
          </router-link>
        </li>

        <li class="nav-item" data-tooltip="Maintance" v-if="maintenanceEnabled && hasRole('admin')">
          <div
            tag="span"
            class="nav-link"
            data-tooltip="Maintenance mode is active"
            data-tooltip-position="bottom right"
          >
            <i class="uil uil-exclamation-circle text-danger"></i>
          </div>
        </li>

        <li class="nav-item" data-tooltip="Settings" v-if="hasRole('admin')">
          <router-link
            tag="span"
            :to="{ path: '/settings' }"
            class="nav-link"
            active-class
            data-tooltip="Admin settings"
            data-tooltip-position="bottom"
          >
            <i class="uil uil-setting"></i>
          </router-link>
        </li>

        <li
          v-if="$auth.impersonating()"
          class="nav-item"
          data-tooltip="Impersonate"
          v-on:click="unimpersonate()"
        >
          <div
            tag="span"
            class="nav-link"
            :data-tooltip="authUser && `Impersonating ${authUser.name}, click to deactivate`"
            data-tooltip-position="bottom right"
          >
            <i class="uil uil-user text-danger"></i>
          </div>
        </li>

        <li class="nav-item dropdown app-menu">
          <a class="nav-link dropdown-toggle" tabindex="0">
            <span class="avatar"></span>
          </a>

          <ul class="dropdown-menu dropdown-menu-right">
            <li tag="li" class="text-nowrap pointer-events-none" v-if="$auth.check()">
              <span class="title">
                Hello,
                <strong>{{ $auth.user().name }}</strong>
                !
              </span>
            </li>

            <li class="dropdown-divider"></li>

            <router-link tag="li" to="/profile" class="text-nowrap" v-if="$auth.check()">
              <i class="uil uil-user"></i>

              <span class="title">Profile & settings</span>
            </router-link>

            <li v-if="$auth.check()" v-on:click="logout">
              <i class="uil uil-exit"></i>

              <span class="title">Sign Out</span>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  </nav>
</template>

<script>
export default {
  computed: {
    maintenanceEnabled() {
      return this.$auth.user() && this.$auth.user().settings.maintenance_mode_enabled == 1
    },
    showNotification() {
      return this.$auth.user() && this.$auth.user().settings.notification_enabled == 1
    },
    authUser() {
      return this?.$auth?.user()
    },
    breadcrumbs() {
      let crumbs = []
      crumbs.push(this.$route.meta.parent)
      this.$route.matched.forEach((route) => {
        if (route.meta.name && this.isValidRouteName(route.meta.name)) {
          crumbs.push(route.meta.name)
        }
      })

      const operatorId = this.$route.params.id

      if (this.$route.path.includes(`/operators/${operatorId}`)) {
        if (this.$route.path === `/operators/${operatorId}`) {
          crumbs.push('General')
        } else if (this.$route.path.includes('/markets-data')) {
          crumbs.push('Markets Data')
        }

        if (this.$store.state.editPage?.model?.name) {
          crumbs.push(this.$store.state.editPage.model.name)
        }
      }
      return crumbs
    },
  },
  mounted() {
    this.emitter.on('toggle-maintenance-mode', (enabled) => {
      this.$auth.user().settings.maintenance_mode_enabled = enabled
    })
  },
  unmounted() {
    this.emitter.off('toggle-maintenance-mode')
  },

  methods: {
    isValidRouteName(name) {
      return this.$router.getRoutes().some((route) => route.name === name)
    },

    logout() {
      if (this.$auth.impersonating()) {
        this.$auth
          .unimpersonate({
            makeRequest: true,
            url: 'backoffice/users/unimpersonate',
            redirect: false,
          })
          .then(() => {
            this.doLogout()
          })
      } else {
        this.doLogout()
      }
    },
    doLogout() {
      this.$auth.logout({
        makeRequest: true,
        url: 'backoffice/auth/logout',
        redirect: '/login',
      })
    },
    unimpersonate() {
      this.$auth
        .unimpersonate({
          makeRequest: true,
          url: 'backoffice/users/unimpersonate',
          redirect: { path: '/' },
        })
        .then(
          () => this.showSuccessMessage('No longer impersonating'),
          this.$store.commit('sidebar/setSelectedSidebarItem', 'Home')
        )
        .catch(() => this.showErrorMessage('Failed to unimpersonate'))
    },
  },
}
</script>

<style scoped>
.navbar {
  transition: none;
}
</style>
