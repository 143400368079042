<template>
  <div class="list-group" v-if="category.length">
    <h4 class="sidebar-title" v-if="title">{{ title }}</h4>
    <div v-for="(child, index) in category" :key="index" class="mb-1">
      <router-link
        v-if="allowedToShow(child) && !child.subsection"
        tag="a"
        :to="{ path: child.to }"
        v-bind:class="{ 'text-blue-75': bestMatch && bestMatch.to === child.to }"
        class="d-flex justify-content-between list-group-item"
        style="height: 40px"
      >
        <span class="title">{{ child.name }}</span>
        <span @click.prevent="favoriteItem(child)" class="custom-star-icon">
          <div v-if="favorites.some((n) => n.to === child.to)">&#9733;</div>
          <div class="star-icon" v-else>&#9734;</div>
        </span>
      </router-link>
    </div>
  </div>
  <div class="list-group" v-else>
    <h4 class="sidebar-title" v-if="title">{{ title }}</h4>
    <router-link
      v-if="!category.subsection"
      tag="a"
      :to="{ path: category.to }"
      v-bind:class="{ 'text-blue-75': isActiveRoute(category.to), active: isActiveRoute(category.to) }"
      class="d-flex justify-content-between list-group-item"
      style="height: 40px"
    >
      <span class="title">{{ category.name }}</span>
      <span @click.prevent="favoriteItem(category)" class="custom-star-icon">
        <div v-if="favorites.some((n) => n.to === category.to)">&#9733;</div>
        <div class="star-icon" v-else>&#9734;</div>
      </span>
      <i
        @click.prevent="hideSubMenu()"
        class="mr-2"
        :class="[showSubmenu ? 'uil uil-angle-down' : 'uil uil-angle-up']"
        v-if="shouldShowOperatorLinks() && operatorId && isCategoryOperator(category)"
      ></i>
    </router-link>
    <div v-if="isCategoryOperator(category) && operatorId && showSubmenu && isOperatorRoute">
      <router-link
        v-for="childrens in operatorPages"
        :key="childrens.to"
        tag="a"
        :to="{ path: childrens.to }"
        v-bind:class="{
          'text-blue-75 bg-blue': isActiveRouteExact(childrens.to),
          active: isActiveRouteExact(childrens.to),
          'border-bottom-except-last': true,
        }"
        class="d-flex justify-content-between bg-none border-0 p-2 text-gray"
        :style="{ height: '40px' }"
      >
        <span class="title ml-4 d-flex justify-content-start align-items-center">{{ childrens.name }}</span>
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    category: {
      type: Object || Array,
    },
    favorites: {
      type: Array,
    },
    title: {
      type: String,
    },
  },
  data() {
    return {
      showSubmenu: true,
    }
  },
  computed: {
    isOperatorRoute() {
      return this.$route.path.startsWith('/operators/')
    },
    bestMatch() {
      return this.$store.getters['sidebar/bestMatch']
    },
    operatorId() {
      return this.$route?.params?.id
    },
    operatorPages() {
      return [
        {
          name: 'General',
          to: `/operators/${this.operatorId}`,
          permission: 'view.operators/operators',
        },
        {
          name: 'Markets Data',
          to: `/operators/${this.operatorId}/markets-data`,
          permission: 'view.operators/bonuses',
        },
        {
          name: 'Sites Data',
          to: `/operators/${this.operatorId}/sites-data`,
          permission: 'view.operators/affiliate-operators',
        },
      ]
    },
  },
  methods: {
    isCategoryOperator(cat) {
      return cat.name === 'Operators'
    },
    isActiveRoute(basePath) {
      return this.$route.path.startsWith(basePath)
    },
    isActiveRouteExact(basePath) {
      if (basePath === `/operators/${this.operatorId}`) {
        return this.$route.path === basePath
      }
      return this.$route.path.startsWith(basePath)
    },
    allowedToShow(child) {
      if (this.hasPermission(child.permission)) {
        return true
      }
    },
    favoriteItem(value) {
      this.$store.commit('sidebar/setFavoriteItem', value)
    },
    hideSubMenu() {
      this.showSubmenu = !this.showSubmenu
    },
    shouldShowOperatorLinks() {
      if (!this.operatorId) return false

      const marketDataPath = `/operators/${this.operatorId}/markets-data`
      const sitesDataPath = `/operators/${this.operatorId}/sites-data`

      const operatorPaths = [
        marketDataPath,
        `${marketDataPath}/create`,
        `${marketDataPath}/edit/${this.$route.params.marketId}`,
        sitesDataPath,
        `${sitesDataPath}/create`,
        `${sitesDataPath}/edit/${this.$route.params.siteId}`,
        `${sitesDataPath}/bonuses/${this.$route.params.bonusId}`,
        `${sitesDataPath}/links/${this.$route.params.linkId}`,
        `/operators/${this.operatorId}/screenshots`,
        `/operators/${this.operatorId}`,
      ]
      return operatorPaths.some((path) => this.$route.path.startsWith(path))
    },
  },
}
</script>
<style scoped>
.bg-blue {
  background-color: #ebf1ff !important;
}

.text-gray {
  font-size: 12px;
  color: #64748b;
}
.ml-4 {
  margin-left: 16px;
}
.border-bottom-except-last:not(:last-child) {
  border-bottom: 1px solid #cbd5e1 !important;
}
.hidden {
  display: none;
}
</style>
