<template>
  <div class="text-center" v-if="isLoading">
    <span>Loading...</span>
  </div>
  <div v-else>
    <div class="row">
      <div class="col">
        <h3>
          <router-link
            :to="{ path: '/prefilled-toplists' }"
            data-tooltip="Back to toplists"
            data-tooltip-position="right center"
          >
            <i class="uil uil-angle-left"></i>
          </router-link>
          <span class="mr-2 ml-2">{{ topList.label }}</span>
          <span class="text-muted mr-3 ml-2">{{ topList.short_code }}</span>
          <span class="text-muted mr-3" v-if="topList.protected">
            <span data-tooltip="Protected" class="ml-2 text-muted" data-tooltip-position="bottom center">
              <i class="fas fa-lock fa-xs" />
            </span>
          </span>
        </h3>
        <div class="additional mb-2">
          <span>Last updated: {{ $dateRelative(topList.updated_at) }}</span>
        </div>
      </div>
    </div>
    <ul class="nav nav-tabs mb-3">
      <router-link tag="li" :to="{ path: `/prefilled-toplists/${topList.id}` }" exact>
        <a>General</a>
      </router-link>
      <router-link tag="li" :to="{ path: `/prefilled-toplists/${topList.id}/items` }">
        <a>Operators</a>
      </router-link>
      <router-link tag="li" :to="{ path: `/prefilled-toplists/${topList.id}/pages` }" v-if="cmsSiteId">
        <a>Pages</a>
      </router-link>
      <router-link tag="li" :to="{ path: `/prefilled-toplists/${topList.id}/prefilled` }">
        <a>Toplists</a>
      </router-link>
    </ul>
    <router-view></router-view>
  </div>
</template>

<script>
import GeneralService from '@services/GeneralService.js'
import PrefilledTopListsService from '@services/PrefilledTopListsService.js'

export default {
  data() {
    return {
      cmsSiteId: null,
      title: '',
    }
  },
  async created() {
    if (this.isIdMismatch) {
      await this.getTopList()
    } else {
      this.$store.commit('editPage/setIsLoading', false)
    }
    this.title = this.topList?.label
  },
  computed: {
    topList() {
      return { ...this.$store.state.editPage.model }
    },
    isIdMismatch() {
      return Number(this.$route.params['id']) !== this.topList?.id
    },
    isLoading() {
      return this.$store.state.editPage.isLoading
    },
  },
  methods: {
    async getTopList() {
      await PrefilledTopListsService.getOne(this.$route.params['id'], {
        with: 'enabled_sites',
        list_type: 'prefilled_template',
      })
        .then((response) => {
          if (response.data.messages) {
            this.showErrorMessages(response.data.messages)
          } else {
            this.$store.commit('editPage/setModel', response.data.result[0])
            this.title = this.topList?.label
            this.getCmsSite()
          }
        })
        .catch(this.showUnknownErrorMessage)
        .finally(() => {
          this.$store.commit('editPage/setIsLoading', false)
        })
    },
    getCmsSite() {
      GeneralService.fetchItems('hercules/sites/sites', {
        affiliate_id: this.topList.affiliate_id,
      }).then((response) => {
        if (response.data.result.length === 1) {
          this.cmsSiteId = response.data.result[0].id
        }
      })
    },
  },
  unmounted() {
    if (this.isIdMismatch) {
      this.$store.commit('editPage/setModel', null)
      this.$store.commit('editPage/setIsLoading', true)
    }
  },
}
</script>
