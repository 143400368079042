<template>
  <data-table
    model="cms/SiteMarket"
    service-file="SiteMarketsService"
    :path="path()"
    :columns="columns()"
    :show-edit-modal="isMarketSectionsEdit === false"
    :show-remove="!isMarketSectionsEdit"
    :show-create="!isMarketSectionsEdit"
    :create-defaults="createDefaults()"
    :column-templates="columnTemplates()"
  />
</template>

<script>
import TableColumnTemplate from '@constants/table-column-template.js'
import BasicOptions from '@mixins/BasicOptions.js'

export default {
  created() {
    this.setTitle()
  },
  computed: {
    // If true, edit the sections of a value
    isMarketSectionsEdit() {
      return this.$route.meta.name === 'Market Sections'
    },
  },
  methods: {
    path() {
      if (this.isMarketSectionsEdit) {
        return `hercules/sites/site-markets?with=site,language,market`
      }
      return `hercules/sites/site-markets?with=site,language,market&site_id=${this.$route.params['id']}`
    },
    columns() {
      let columns = [
        { field: 'site', label: 'Site', type: 'text', filterable: true },
        { field: 'market', label: 'Market', type: 'text', filterable: true },
      ]
      if (!this.isMarketSectionsEdit) {
        columns.push(
          { field: 'path_prefix', type: 'text', filterable: true },
          { field: 'meta_title_suffix', type: 'text', filterable: true },
          {
            field: 'social_share_image_id',
            type: 'text',
            filterable: false,
            label: 'Social Share Image',
            template: TableColumnTemplate.IMAGE,
          }
        )
      }
      columns.push(
        Object.assign({}, BasicOptions.newActiveColumn(), { template: TableColumnTemplate.BOOLEAN }),
        { field: 'updated_at', type: 'text', filterable: true, template: TableColumnTemplate.RELATIVE_DATE }
      )
      return columns
    },
    columnTemplates() {
      return Object.assign(
        {
          market: function (row) {
            return row.market.label
          },
        },
        {
          site: function (row) {
            return row.site.name
          },
        }
      )
    },
    createDefaults() {
      return {
        site_id: parseInt(this.$route.params['id']),
      }
    },
  },
}
</script>
