import { createStore } from 'vuex'
import cms from './modules/cms.js'
import media from './modules/media.js'
import app from './modules/app.js'
import tiptap from './modules/tiptap.js'
import sidebar from './modules/sidebar.js'
import user from './modules/user.js'
import baseSelect from './modules/baseSelect.js'
import dynamicForm from './modules/dynamicForm.js'
import editPage from './modules/editPage.js'

//eslint-disable-next-line
const debug = import.meta.env.NODE_ENV !== 'production'

export const store = createStore({
  modules: {
    sidebar,
    app,
    tiptap,
    media,
    user,
    cms,
    baseSelect,
    dynamicForm,
    editPage,
  },
  strict: debug,
})
