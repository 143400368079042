<script>
import BaseRadio from '@atoms/fields/base-radio.vue'
import BaseMultiselect from '../../../../fields/base-multiselect.vue'
import BaseDate from '@atoms/fields/base-date.vue'
import RepullService from '@services/koala/RepullService.js'
import moment from 'moment/moment'
import FormFields from '@molecules/koala/repull/form-fields.vue'
import { openDialog } from 'vue3-promise-dialog'
import ConfirmDialog from '@atoms/misc/confirm-dialog.vue'

const confirmRepull = async (
  title,
  content,
  actionButtonTitle,
  actionButtonIconClass,
  actionButtonClass,
  actionButtonCancelTitle
) => {
  return await openDialog(ConfirmDialog, {
    title,
    content,
    actionButtonTitle,
    actionButtonIconClass,
    actionButtonClass,
    actionButtonCancelTitle,
  })
}

export default {
  components: { FormFields, BaseDate, BaseMultiselect, BaseRadio },
  data() {
    return {
      loading: false,
      params: [],
      isCentered: true,
      showModal: false,
      object: {
        type: null,
        operator_ids: [],
        platform_ids: [],
        account_ids: [],
        operator_platform_ids: [],
        business_unit_ids: [],
        report_ids: [],
        dates_range: null,
        dry_run: true,
      },
      initialObject: {},
      formDirty: false,
    }
  },
  created() {
    this.initialObject = JSON.parse(JSON.stringify(this.object))
  },
  watch: {
    object: {
      handler(newVal) {
        const originalObject = this.initialObject
        const filteredKeys = Object.keys(originalObject).filter(
          (key) => key !== 'dry_run' && key !== 'dates_range'
        )

        for (let key of filteredKeys) {
          if (JSON.stringify(originalObject[key]) !== JSON.stringify(newVal[key])) {
            this.formDirty = true
            return
          }
        }
        this.formDirty = false
      },
      deep: true,
    },
  },
  methods: {
    show(params) {
      this.params = params
      document.getElementById('modal').appendChild(this.$el)
      this.showModal = true
      this.$nextTick(() => {
        this.$refs.modal.classList.add('show')
      })
    },
    close() {
      if (this.formDirty) {
        if (confirm('You have unsaved changes. Are you sure you want to leave?')) {
          this.loading = false
          this.$refs.modal && this.$refs.modal.classList.remove('show')
          this.showModal = false
          this.$emit('closed')
        }
        return
      }
      this.loading = false
      this.$refs.modal && this.$refs.modal.classList.remove('show')
      this.showModal = false
      this.$emit('closed')
    },
    closeModalAfterSendingMessage() {
      if (this.$refs && typeof this.$refs.modal !== 'undefined') {
        this.close()
      }
    },
    clickOutside(event) {
      if (event.target === this.$refs.modal && event.target !== this.$refs.modalContent) {
        this.close()
      }
    },
    toggleModalPosition() {
      this.isCentered = !this.isCentered
    },
    async submit() {
      this.loading = true
      RepullService.post(this.object)
        .then((response) => {
          this.loading = false
          if (response.data.messages) {
            this.object.dry_run = true
            this.showErrorMessages(response.data.messages)
          } else {
            if (this.object.dry_run) {
              this.submitConfirm(response.data.result.accounts_queued)
            } else {
              this.object.dry_run = true
              this.showSuccessMessage('Repull created')
              this.$emit('refresh')
              this.formDirty = false
              this.close()
            }
          }
        })
        .catch((error) => this.showUnknownErrorMessage(error))
    },
    async submitConfirm(accounts) {
      if (
        await confirmRepull(
          'Confirmation',
          `Are you sure you want to repull ${accounts} account(s)?`,
          'Yes',
          '',
          'btn btn-success',
          'No'
        )
      ) {
        this.object.dry_run = false
        await this.submit()
      }
      this.formDirty = false
      this.close()
    },
    getDefaultDateRange() {
      let startMonth = moment().startOf('month').format('YYYY-MM-DD')
      let endMonth = moment().subtract(1, 'days').format('YYYY-MM-DD')

      return startMonth + ' to ' + endMonth
    },
    syncObject(object) {
      this.object = object
    },
  },
}
</script>

<template>
  <div
    class="modal"
    v-bind:class="{ show: showModal, 'modal-right': !isCentered }"
    v-click-outside="close"
    tabindex="-1"
    ref="modal"
    role="dialog"
    @click="clickOutside"
    v-if="showModal"
  >
    <div class="modal-dialog modal-lg2">
      <div class="modal-content" ref="modalContent">
        <div class="modal-header">
          <h5 class="modal-title">Create Repull</h5>
          <div class="modal-title-buttons">
            <button @click="toggleModalPosition" class="modal-title-button">
              <i class="uil" v-bind:class="isCentered ? 'uil-arrow-to-right' : 'uil-focus'" />
            </button>
            <button class="close modal-title-button" @click="closeModalAfterSendingMessage"></button>
          </div>
        </div>
        <div class="modal-body">
          <form class="form-style" v-on:submit.prevent="submit" :class="['form-changed']">
            <form-fields @syncData="syncObject" ref="formFields" />
          </form>
        </div>
        <div class="text-right modal-footer">
          <button @click="submit" class="btn btn-primary" v-bind:class="{ loading: loading }">
            <i class="uil uil-check"></i>
            Submit
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
