<template>
  <div class="text-center" v-if="isLoading">
    <span>Loading...</span>
  </div>
  <div v-else>
    <div class="row">
      <div class="col">
        <h3>
          <router-link
            :to="{ path: `/affiliate-platforms` }"
            data-tooltip="Back to platforms"
            data-tooltip-position="right center"
          >
            <i class="uil uil-angle-left"></i>
          </router-link>
          <span class="mr-3 ml-2">{{ $capitalize(platform.name) }}</span>
        </h3>
        <div class="additional mb-2">
          <span>Last updated: {{ $dateRelative(platform.updated_at) }}</span>
        </div>
      </div>
    </div>
    <ul class="nav nav-tabs mb-3">
      <router-link tag="li" :to="{ path: `/affiliate-platforms/${platform.id}` }" exact>
        <a>General</a>
      </router-link>
      <router-link tag="li" :to="{ path: `/affiliate-platforms/${platform.id}/domains` }" exact>
        <a>Domains</a>
      </router-link>
    </ul>
    <router-view></router-view>
  </div>
</template>

<script>
import PlatformServices from '@services/operators/AffiliatePlatformsService.js'

export default {
  async created() {
    if (this.isIdMismatch) {
      await this.getPlatform()
    } else {
      this.$store.commit('editPage/setIsLoading', false)
    }
    this.title = this.platform?.name
  },
  data() {
    return {
      title: '',
    }
  },
  computed: {
    platform() {
      return { ...this.$store.state.editPage.model }
    },
    isIdMismatch() {
      return Number(this.$route.params['id']) !== this.platform?.id
    },
    isLoading() {
      return this.$store.state.editPage.isLoading
    },
  },
  methods: {
    async getPlatform() {
      await PlatformServices.getOne(this.$route.params['id'])
        .then((response) => {
          if (response.data.messages) {
            this.showErrorMessages(response.data.messages)
            response.data.messages.some((message) => {
              if (message.indexOf('Platform not found') >= 0) {
                this.$router.push('/affiliate-platforms')
              }
            })
          } else {
            this.$store.commit('editPage/setModel', response.data.result[0])
            this.title = this.platform?.name
          }
        })
        .catch(this.showUnknownErrorMessage)
        .finally(() => {
          this.$store.commit('editPage/setIsLoading', false)
        })
    },
  },
  unmounted() {
    if (this.isIdMismatch) {
      this.$store.commit('editPage/setModel', null)
      this.$store.commit('editPage/setIsLoading', true)
    }
  },
}
</script>
