<template>
  <data-table
    model="cms/PageStyle"
    pusherModel="sites-page_styles"
    service-file="PageStylesService"
    :path="path"
    :columns="columns()"
    :column-templates="columnTemplates()"
    :create-defaults="createDefaults()"
    :serviceParams="[this.$parent.$parent.template.site_id]"
    extra-delete-message="All pages that use this style will be affected."
  />
</template>

<script>
import TableColumnTemplate from '@constants/table-column-template.js'
import BasicOptions from '@mixins/BasicOptions.js'

export default {
  created() {
    this.setTitle()
  },
  computed: {
    path() {
      return `hercules/sites/page-styles?page_type_id=${this.$route.params.id}`
    },
  },
  methods: {
    columns() {
      return [
        { field: 'name', type: 'text', filterable: true },
        { field: 'short_name', type: 'text', filterable: true },
        { field: 'markets', type: 'text', filterable: true },
        Object.assign({}, BasicOptions.newActiveColumn('default'), { template: TableColumnTemplate.BOOLEAN }),
        { field: 'updated_at', type: 'text', template: TableColumnTemplate.RELATIVE_DATE },
      ]
    },
    columnTemplates() {
      return Object.assign({
        markets: function (row) {
          return row.markets.map((market) => market.label).join(', ')
        },
      })
    },
    createDefaults() {
      return {
        site_id: this.$parent.$parent.template.site_id,
        page_type_id: parseInt(this.$route.params['id']),
      }
    },
  },
}
</script>
