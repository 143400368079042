<template>
  <data-table
    model="cms/PrefilledModule"
    service-file="PrefilledModulesService"
    path="hercules/sites/prefilled-modules?with=module,site"
    :columns="columns()"
    :column-templates="columnTemplates()"
    :create-defaults="createDefaults()"
    :show-edit-modal="false"
  />
</template>

<script>
import BasicOptions from '@mixins/BasicOptions.js'
import TableColumnTemplate from '@constants/table-column-template.js'

export default {
  created() {
    this.setTitle()
  },

  methods: {
    columns() {
      return [
        { field: 'name', type: 'text', filterable: true },
        {
          field: 'site',
          type: 'text',
          filterable: true,
        },
        { field: 'module', label: 'Module', type: 'text', filterable: true },
        Object.assign({}, BasicOptions.newActiveColumn(), { template: TableColumnTemplate.BOOLEAN }),
        { field: 'updated_at', type: 'text', filterable: true, template: TableColumnTemplate.RELATIVE_DATE },
      ]
    },
    columnTemplates() {
      return Object.assign(
        {
          site: function (row) {
            return row.site?.name
          },
        },
        {
          module: function (row) {
            return row.module?.label
          },
        }
      )
    },
    createDefaults() {
      return {
        site_id: parseInt(this.$route.params['id']),
      }
    },
  },
}
</script>
