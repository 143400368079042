import FieldType from '@constants/field-type.js'

export default {
  getForm() {
    return [
      {
        name: 'site_id',
        type: 'hidden',
      },
      {
        name: 'site_name',
        display_name: 'Site name',
        type: 'text',
        help_text: 'Add site name as you want it to appear in schema',
      },
      {
        name: 'alias_site_name',
        display_name: 'Alias Site Name',
        type: 'text',
        help_text: 'An alias for site name',
      },
      {
        name: 'description',
        type: 'text',
        help_text: 'Describe the website & its purpose',
      },
      {
        name: 'email',
        display_name: 'Contacts',
        type: 'list-view',
        fields: [
          {
            name: 'email',
            display_name: 'Email',
            showLabel: false,
            type: 'text',
            help_text: 'Specify email address to act as a Contact Point',
          },
          {
            name: 'contact_type',
            display_name: 'Contact Type',
            showLabel: false,
            type: 'text',
            help_text: 'Specify type of contact point (example: Support)',
          },
        ],
      },
      {
        name: 'knows_abouts',
        type: FieldType.SITES_KNOWS_ABOUT,
        display_name: 'About',
      },
      {
        name: 'phone_number',
        display_name: 'Phone',
        type: 'number',
        help_text: 'Add organization’s phone number',
      },
      {
        name: 'address',
        display_name: 'Address',
        type: 'textarea',
        help_text: 'Physical address of organization',
      },
      {
        name: 'owned_by',
        display_name: 'Owned By',
        type: 'text',
        help_text:
          'Add name of parent organization. Use only if different from site name and you want details to appear in schema.',
      },
      {
        name: 'organization_founding_date',
        display_name: 'Founding Date',
        type: 'date',
        help_text: 'Date when the organization was founded.',
      },
      {
        name: 'publishing_principles_page',
        display_name: 'Publishing Principles',
        display_button_name: 'Page',
        type: 'model_select',
        model: 'page',
        selectLabel: 'name',
        value_key: 'id',
      },
      {
        name: 'facebook',
        display_name: 'Facebook',
        type: 'text',
      },
      {
        name: 'twitter',
        display_name: 'Twitter',
        type: 'text',
      },
      {
        name: 'instagram',
        display_name: 'Instagram',
        type: 'text',
      },
      {
        name: 'youtube',
        display_name: 'Youtube',
        type: 'text',
      },
      {
        name: 'linkedin',
        display_name: 'LinkedIn',
        type: 'text',
      },
      {
        name: 'spotify',
        display_name: 'Spotify',
        type: 'text',
      },
      {
        name: 'wikipedia',
        display_name: 'Wikipedia',
        type: 'text',
      },
      {
        name: 'support_email',
        display_name: 'Support Email',
        type: 'text',
      },
      {
        name: 'video',
        display_name: 'Video',
        type: 'text',
      },
      {
        name: 'tik_tok',
        display_name: 'TikTok',
        required: false,
        type: 'text',
      },
    ]
  },
  getConfig() {
    return {
      url: 'sites/schema-information',
      name: 'Schema',
      pagination: true,
    }
  },
  getStructure() {
    return {
      list: this.getForm(),
      config: this.getConfig(),
    }
  },
}
