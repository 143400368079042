<template>
  <data-table
    ref="list"
    model="Operator"
    service-file="OperatorsService"
    :path="path()"
    :columns="columns()"
    :create-defaults="createDefaults()"
    :column-templates="columnTemplates()"
    :show-edit-modal="false"
  />
</template>

<script>
import TableColumnTemplate from '@constants/table-column-template.js'

export default {
  created() {
    this.setTitle('Operators')
  },
  methods: {
    columns() {
      return [
        { field: 'name', type: 'text', filterable: true },
        { field: 'short_name', type: 'text', filterable: true },
        {
          field: 'countries',
          type: 'text',
          class: 'col-countries',
          search_key: 'countries__id',
          advancedFilter: true,
          path: 'hercules/globals/countries',
          optionLabelKey: 'name',
          showModalAdvanced: true,
          basicFilterType: 'select',
        },
        { field: 'games_amount', type: 'text', filterable: true },
        { field: 'founded', type: 'text', filterable: true },
        { field: 'updated_at', type: 'text', filterable: true, template: TableColumnTemplate.RELATIVE_DATE },
      ]
    },
    createDefaults() {
      return {
        enabled: true,
      }
    },
    columnTemplates() {
      return Object.assign({
        countries: function (row) {
          if (row.countries && row.countries.length) {
            return row.countries
              .map((country) => {
                return country.name
              })
              .join(', ')
          } else {
            return 'N/A'
          }
        },
      })
    },
    path() {
      return `hercules/operators/operators?with=countries`
    },
  },
}
</script>
