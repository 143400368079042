<template>
  <div>
    <data-table
      model="cms/Site"
      service-file="SitesService"
      path="hercules/sites/sites"
      :extraDeleteInfo="{
        title: 'Careful, this is a destructive action!',
        message: 'All site related data (pages, menus, page types etc.) will be removed',
      }"
      :columns="columns()"
      :show-edit-modal="false"
      :rowActionButtons="[
        {
          action: cloneAction,
          title: 'Clone',
          icon: 'uil uil-share-alt',
          class: 'btn-warning',
        },
      ]"
      ref="table"
    />
    <site-clone ref="siteCloneModal" @refresh="() => $refs.table.search()" />
  </div>
</template>

<script>
import TableColumnTemplate from '@constants/table-column-template.js'
import SiteClone from '@atoms/cms/sites/clone-modal.vue'

export default {
  created() {
    this.setTitle()
  },
  components: { SiteClone },
  methods: {
    columns() {
      return [
        { field: 'id', type: 'text', filterable: true },
        { field: 'name', type: 'text', filterable: true },
        { field: 'type', type: 'text', filterable: true },
        { field: 'created_at', type: 'text', filterable: true, template: TableColumnTemplate.RELATIVE_DATE },
        { field: 'updated_at', type: 'text', filterable: true, template: TableColumnTemplate.RELATIVE_DATE },
      ]
    },
    cloneAction(row) {
      this.$refs.siteCloneModal.show(row)
    },
  },
}
</script>
