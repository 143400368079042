<template>
  <div class="d-flex border pl-2 pr-4 pt-3 pb-2 mb-4 bg-white">
    <div class="d-flex align-items-center">
      <span class="badge bg-black-25 mt-2 ml-3 mr-4" style="font-size: 14px">{{ index + 1 }}</span>
    </div>
    <div class="d-flex justify-content-even flex-fill" id="breadcrumb-edit-item">
      <model-select
        :parameters="pageParams()"
        model="page"
        :value="breadcrumb.page_id"
        :value-label="breadcrumb.page_title"
        :return-full-object="true"
        label="Select a page"
        ref="modelSelect"
        modal-size-class="modal-lg"
        @valuePicked="pageSelected"
      />
      <div class="ml-5">
        <base-input
          label="Anchor text"
          v-model="breadcrumb.label"
          class=""
          :id="`breadcrumb-label-${index}`"
        />
      </div>
    </div>
    <div class="d-flex align-items-center">
      <button class="btn btn-danger btn-sm ml-2" @click="$emit('remove')">
        <i class="uil uil-trash"></i> Delete
      </button>
    </div>
  </div>
</template>

<script>
import BaseSelect from '@/components/fields/base-select.vue'
import BaseInput from '@atoms/fields/base-input.vue'
import ModelSelect from '@molecules/fields/model-select/base-model-select.vue'

export default {
  components: { BaseSelect, ModelSelect, BaseInput },
  props: {
    breadcrumb: {
      required: true,
    },
    index: {
      required: true,
    },
  },
  methods: {
    pageSelected(value) {
      this.breadcrumb.page_id = value ? value.id : null
      this.breadcrumb.page_title = value ? value.title : null
      this.breadcrumb.page_path = value ? value.path : null
    },
    pageParams() {
      return {
        market_id: this.$store.state.sidebar.market,
        site_id: this.$store.state.sidebar.siteId,
        exclude_status: 'draft',
      }
    },
  },
}
</script>

<style lang="scss">
#breadcrumb-label {
  min-width: 230px !important;
}
#breadcrumb-edit-item {
  input,
  button {
    width: 310px;
  }
}
</style>
