<template>
  <data-table
    model="Market"
    service-file="MarketsService"
    path="hercules/globals/markets?with=currency"
    :columns="columns()"
    :column-templates="columnTemplates()"
  />
</template>

<script>
import TableColumnTemplate from '@constants/table-column-template.js'

export default {
  created() {
    this.setTitle()
  },
  methods: {
    columns() {
      return [
        { field: 'label', type: 'text', filterable: true },
        { field: 'country_code', type: 'text', filterable: true },
        { field: 'language_code', type: 'text', filterable: true },
        { field: 'currency', type: 'text', filterable: true },
        { field: 'state', type: 'text', filterable: true },
        { field: 'short_code', type: 'text', filterable: true },
        { field: 'updated_at', type: 'text', filterable: true, template: TableColumnTemplate.RELATIVE_DATE },
      ]
    },
    columnTemplates() {
      return Object.assign({
        currency: function (row) {
          return row.currency?.name
        },
      })
    },
  },
}
</script>
