<template>
  <div>
    <div class="row">
      <div class="col">
        <sidebar-filter ref="sidebar" :showSports="false" />
        <data-table
          v-if="siteId"
          model="sport/SiteCountry"
          service-file="SiteCountriesService"
          :path="path()"
          ref="list"
          :columns="columns()"
          :extraDeleteInfo="{
            title: 'Careful, this is a destructive action!',
            message: 'All related event pages (and their content) will be deleted.',
          }"
          :create-defaults="createDefaults()"
          :extraComponentHeight="sidebarHeight"
        />
      </div>
    </div>
  </div>
</template>

<script>
import SidebarFilter from '@molecules/sports/sports-filter.vue'
import TableColumnTemplate from '@constants/table-column-template.js'

export default {
  created() {
    this.setTitle()
  },
  mounted() {
    this.sidebarHeight = this.$refs.sidebar.$el.clientHeight
  },
  data() {
    return {
      sidebarHeight: 0,
    }
  },
  components: { SidebarFilter },
  computed: {
    siteId() {
      return this.$store.state.sidebar.siteId
    },
    marketId() {
      return this.$store.state.sidebar.market
    },
  },
  watch: {
    siteId() {
      this.applyFilters()
    },
    marketId() {
      this.applyFilters()
    },
  },
  methods: {
    path() {
      return `hercules/sports/site-countries?${this.buildQueryString(
        Object.assign({ with: 'country,site,market' }, this.getFilters())
      )}`
    },
    columns() {
      return [
        {
          field: 'country.name',
          type: 'text',
          label: 'Country',
          filterable: true,
          search_key: 'country__name',
          order_key: 'country__name',
        },
        { field: 'site.name', label: 'Site', type: 'text', filterable: true },
        {
          field: 'market.label',
          label: 'Market',
          type: 'text',
          filterable: true,
          search_key: 'market__label',
        },
        { field: 'updated_at', type: 'text', filterable: true, template: TableColumnTemplate.RELATIVE_DATE },
      ]
    },
    applyFilters() {
      let filters = {}
      if (this.siteId) filters['site_id'] = this.siteId
      if (this.marketId) filters['market_id'] = this.marketId

      this.emitter.emit('data-table.applyFilters', filters)
    },
    createDefaults() {
      return {
        site_id: this.siteId,
        market_id: this.marketId,
      }
    },
    getFilters() {
      let filters = {}
      if (this.siteId) filters['site_id'] = this.siteId
      return filters
    },
  },
}
</script>
