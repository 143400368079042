<template>
  <div class="card">
    <div class="card-header border-bottom">
      <div class="d-flex justify-content-between w-100">
        <div class="d-flex align-items-center">
          <base-select
            v-model="sportId"
            path="hercules/sports/sports"
            ref="sportSelect"
            option-label-key="name"
            class="mb-0"
            style="width: 200px"
          />
          <span class="text-muted ml-4 text-left">
            Save your changes
            <strong>before</strong> changing sport!
          </span>
        </div>
        <div class="ml-auto">
          <button
            class="btn btn-primary"
            v-bind:class="{ loading: loading }"
            @click="submit"
            :disabled="Object.keys(object).length === 0"
          >
            <i class="uil uil-check mr-2"></i>Save
          </button>
        </div>
      </div>
    </div>
    <div class="card-body pt-4">
      <dynamic-form
        ref="form"
        form-type="edit"
        :formFields="structure.list"
        :structure="structure"
        :object="object"
        :path="config.url"
        :showSubmit="false"
      />
    </div>
  </div>
</template>

<script>
import BaseSelect from '@/components/fields/base-select.vue'
import SidebarFilter from '@molecules/sports/sports-filter.vue'
import SiteSportProvider from '@models/sport/SiteSportProvider.js'
import SiteSportProvidersService from '@services/SiteSportProvidersService.js'
import DynamicForm from '@/components/fields/DynamicForm.vue'
import BaseCheckbox from '@atoms/fields/base-checkbox.vue'

export default {
  components: { SidebarFilter, DynamicForm, BaseCheckbox, BaseSelect },
  data() {
    return {
      object: {},
      structure: { list: [] },
      config: SiteSportProvider.getConfig(),
      loading: false,
    }
  },
  computed: {
    sportId: {
      get() {
        return this.$store.state.sidebar.sportId
      },
      set(sport) {
        this.$store.dispatch('sidebar/setSport', sport)
      },
    },
  },
  watch: {
    sportId(sportId) {
      this.structure = {}
      if (sportId) this.getSiteSportProvider()
    },
  },
  mounted() {
    this.getSiteSportProvider()
    this.setTitle(`${this.$parent.$parent.title ? this.$parent.$parent.title + ':' : ''}  Sports data `)
  },
  methods: {
    submit() {
      const self = this
      this.loading = true
      this.$refs.form
        .process(this.object)
        .then((response) => {
          return SiteSportProvidersService.update(response)
        })
        .then((response) => {
          if (response.data.messages) {
            self.showErrorMessages(response.data.messages)
          } else {
            self.showSuccessMessage(`${self.structure.config.name} updated`)
            self.getSchema()
          }
        })
        .catch(() => this.showUnknownErrorMessage)
        .finally(() => {
          this.loading = false
        })
    },
    getSiteSportProvider() {
      SiteSportProvidersService.get({
        site_provider_id: this.$route.params.id,
        sport_id: this.sportId,
        with: 'sport',
      }).then((response) => {
        if (response.data.result.length === 1) {
          this.object = response.data.result[0]
          this.getStructure()
        } else {
          this.object = {}
          this.structure = {}
          this.showErrorMessages('Selected sport has not yet been added to your site.')
        }
      })
    },
    getStructure() {
      this.structure = SiteSportProvider.getStructure()
    },
  },
}
</script>
