<template>
  <div>
    <data-table
      model="koala/Operators"
      service-file="koala/OperatorsService"
      :path="path()"
      permissionPath="koala/v1/accounts"
      :columns="columns()"
      :columnTemplates="columnTemplates()"
      :create-defaults="createDefaults()"
      @bulkClickByIds="bulkClickByIds"
      :rowActionButtons="rowActions()"
      :enableBulkSelect="true"
      ref="table"
      :tableLimits="[10, 50, 100]"
    />
    <dynamic-bulk-edit
      v-if="showBulkEdit"
      ref="bulkEdit"
      model="koala/Operators"
      service-file="koala/OperatorsService"
      @closed="() => (this.showBulkEdit = false)"
      @refresh="$refs.table.search()"
    />
  </div>
</template>

<script lang="jsx">
import TableColumnTemplate from '@constants/table-column-template.js'
import EnabledActionColumn from '@molecules/table/cell/enabled-action.vue'
import OperatorsService from '@services/koala/OperatorsService.js'
import DynamicBulkEdit from '@molecules/koala/dynamic-bulk-edit.vue'
import FilterChangedLogic from '@mixins/FilterChangedLogic.js'
import BasicOptions from '@mixins/BasicOptions.js'

export default {
  mixins: [FilterChangedLogic],
  created() {
    this.setTitle()
  },
  data() {
    return {
      showBulkEdit: false,
    }
  },
  components: { DynamicBulkEdit },
  methods: {
    columns() {
      return [
        Object.assign({}, BasicOptions.booleanColumn('platform.scrapable'), {
          search_key: 'platform_scrapable',
          template: TableColumnTemplate.BOOLEAN,
          label: 'Platform Scrapable',
          customOptions: [
            { label: 'Yes', id: 'true' },
            { label: 'No', id: 'false' },
          ],
          basicFilterType: 'select',
          optionLabelKey: 'label',
          clearable: true,
        }),
        {
          label: 'Platform',
          field: 'platform',
          type: 'text',
          filterable: true,
          class: 'col-platforms',
          search_key: 'platforms',
          advancedFilter: true,
          path: 'koala/v1/platforms?orderBy=name&ascending=1&limit=100',
          optionLabelKey: 'name',
          showModalAdvanced: true,
          basicFilterType: 'select',
          showEmptyNull: false,
          fetch_on_load: false,
          sortable: true,
          order_key: 'platform.name',
          searchBy: 'name',
          trackBy: 'id',
        },
        {
          field: 'name',
          type: 'text',
          search_key: 'name',
          filterable: true,
          class: 'col-operators',
          advancedFilter: true,
          showModalAdvanced: true,
          basicFilterType: 'text',
          showEmptyNull: false,
        },
        {
          field: 'base_url',
          type: 'text',
          search_key: 'base_url',
          filterable: true,
          advancedFilter: true,
          showModalAdvanced: true,
          basicFilterType: 'text',
          showEmptyNull: false,
          template: TableColumnTemplate.LINK_CELL,
        },
        {
          field: 'schedule_cron',
          type: 'text',
          search_key: 'schedule_cron',
          filterable: true,
          advancedFilter: true,
          showModalAdvanced: true,
          basicFilterType: 'text',
          showEmptyNull: false,
          sortable: false,
        },
        {
          field: 'schedule_active',
          label: 'Schedule Active',
          type: 'text',
          filterable: true,
          advancedFilter: true,
          clearable: true,
          allowNullOption: true,
          customOptions: [
            { label: 'Active', id: 'true' },
            { label: 'Inactive', id: 'false' },
          ],
          basicFilterType: 'select',
          optionLabelKey: 'label',
          order_key: 'schedule_active',
          sortable: true,
        },
        { field: 'updated', type: 'text', filterable: false, template: TableColumnTemplate.RELATIVE_DATE },
      ]
    },
    actionColumnClick(id, field, value) {
      this.$refs.table.isLoading = true
      let object = this.$refs.table.rows.find((item) => item.id === id)
      object = {
        id: object.id,
        schedule_active: object.schedule_active,
        name: object.name,
        base_url: object.base_url,
        schedule_cron: object.schedule_cron,
        schedule_args: {},
      }
      object[field] = value
      OperatorsService.update(object)
        .then(() => {
          this.$refs.table.search()
          this.showSuccessMessage('Column updated')
        })
        .catch(() => {
          this.showErrorMessage('Failed to update operator')
          this.$refs.table.isLoading = false
        })
    },
    columnTemplates() {
      const self = this
      return Object.assign({
        schedule_active: function (row) {
          return (
            <EnabledActionColumn row={row} field={'schedule_active'} onColumnClick={self.actionColumnClick} />
          )
        },
        platform: function (row) {
          return row.platform.name
        },
        name: function (row) {
          return row.name
        },
      })
    },
    rowActions() {
      return [
        {
          action: this.monitoringAction,
          title: 'Monitoring',
          icon: 'uil uil-align-justify',
          class: 'btn-warning',
        },
      ]
    },
    monitoringAction(row) {
      let routeData = this.$router.resolve({
        path: `/koala-monitoring?`,
        query: {
          operators: row.id,
        },
      })
      window.open(routeData.href, '_blank')
    },
    createDefaults() {
      return {
        schedule_active: true,
      }
    },
    bulkClickByIds(ids, extraData, filters, resultCount) {
      if (filters && Object.keys(filters).length > 0) {
        this.showBulkEdit = true
        this.$nextTick(() => this.$refs.bulkEdit.showByFilters(ids, filters, resultCount, extraData))
      } else {
        this.showBulkEdit = true
        this.$nextTick(() => this.$refs.bulkEdit.showByIds(ids))
      }
    },
    bulkClickByFilters(ids, filters, resultCount, extraData) {
      this.showBulkEdit = true
      this.$nextTick(() => this.$refs.bulkEdit.showByFilters(ids, filters, resultCount, extraData))
    },
    path() {
      return `koala/v1/operators`
    },
  },
}
</script>
