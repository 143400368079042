<template>
  <div>
    <data-table
      model="sport/Teams"
      service-file="SportsTeamService"
      path="hercules/sports/teams?with=country,sport"
      :columns="columns()"
      :show-remove="false"
      :show-create="false"
      :show-edit-modal="false"
    />
  </div>
</template>

<script>
import TableColumnTemplate from '@constants/table-column-template.js'

export default {
  created() {
    this.setTitle()
  },
  methods: {
    columns() {
      return [
        { field: 'id', type: 'text', filterable: true },
        { field: 'name', type: 'text', filterable: true },
        { field: 'gender', type: 'text', filterable: true },
        {
          field: 'sport.name',
          type: 'text',
          filterable: true,
          label: 'Sport',
          search_key: 'sport__name',
          order_key: 'sport__name',
        },
        {
          field: 'country.name',
          type: 'text',
          filterable: true,
          label: 'Country',
          search_key: 'country__name',
          order_key: 'country__name',
        },
        {
          field: 'logo',
          type: 'text',
          filterable: false,
          label: 'Logo',
          template: TableColumnTemplate.IMAGE,
        },
        {
          field: 'additional_logo_object.url',
          type: 'text',
          filterable: false,
          sortable: false,
          label: 'Additional Logo',
          template: TableColumnTemplate.IMAGE,
        },
      ]
    },
  },
}
</script>
