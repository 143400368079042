<template>
  <div>
    <div class="form-group" v-if="showField">
      <label v-if="showLabel">
        {{ $prettyLabels(customLabel ? customLabel : displayName ? displayName : model) }}
        <span v-if="required">&nbsp;*&nbsp;</span>

        <span v-if="valueLabel || disabled" class="text-muted clickable" @click="clearAndEnable()">
          clear
        </span>
      </label>

      <div v-if="disabled">
        <p class="text-danger mb-0">
          <strong>Relation not found:</strong>
          {{ $prettyLabels(model) }} with ID {{ value }} does not exist
        </p>
      </div>

      <!-- table that shows the selected items (for multi select only) -->

      <div v-else>
        <div v-bind:class="{ loading: loading }">
          <div v-if="multipleSelect === false && valueLabel" class="input-group">
            <input :value="valueLabel" type="text" class="form-control" readonly />
          </div>

          <data-table
            id="multi-selected-list"
            v-if="multipleSelect && value !== '' && value !== null"
            ref="list"
            stateName="model-select-items-selected"
            :path="selectedPathTable"
            :showCreate="false"
            :loadOnMount="false"
            :enableAdvancedSearch="false"
            :enableRowClick="false"
            :showCardHeader="false"
            :enableDataExport="false"
            :showCardFooter="false"
            :statefulColumns="false"
            :columnTemplates="modelColumnTemplates"
            :columns="modelColumns"
            :removeClick="removeItem"
            :applyTableSize="false"
          />
        </div>

        <button
          v-if="multipleSelect || !valueLabel"
          class="btn btn-secondary d-block"
          v-bind:class="{
            'mt-4': multipleSelect,
            disabled: isDisabled,
          }"
          @click="show"
          type="button"
          :disabled="isDisabled"
        >
          {{ selectLabel }}
        </button>

        <small v-if="helpText" class="form-text text-muted">{{ helpText }}</small>
      </div>
    </div>

    <!-- modal to pick new models -->

    <div class="modal" ref="modal" tabindex="-1" role="dialog" v-if="showModal">
      <div class="modal-dialog" :class="modalSizeClass">
        <div class="modal-content">
          <div class="modal-header pb-2">
            <h3>{{ modalTitle }}</h3>

            <button class="close" @click="close()"></button>
          </div>
          <div class="modal-body pb-0" v-bind:class="{ loading: loading }">
            <data-table
              id="data-selector-list"
              :path="modelPathTable"
              stateName="model-select-items-selector"
              :basicTable="basicTable"
              :showCreate="false"
              :columnTemplates="modelColumnTemplates"
              :statefulColumns="statefulColumns"
              :columns="modelColumns"
              :customRowClick="rowClick"
              :showEditModal="false"
              :enableBulkSelect="multipleSelect"
              :enableDataExport="false"
              @bulkClickByIds="bulkSelect"
              :tableLimits="basicTable"
              :enableTooltip="this.enableTooltip"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AbstractService from '@services/AbstractService.js'
import ModelSelectHelper from '@mixins/ModelSelectHelper.js'
import DataTable from '@templates/table/table.vue'

export default {
  components: {
    DataTable,
  },
  data() {
    return {
      loading: false,
      showModal: false,
      disabled: false,
      notifiedFieldsParams: {},
      selectedRow: {},
    }
  },
  watch: {
    value(newValue) {
      if (newValue === null && this.$refs.list) {
        this.$nextTick(() => {
          this.$refs.list.search()
        })
      }
    },
  },
  props: {
    required: {
      default: () => false,
      type: Boolean,
    },
    displayName: {
      type: String,
    },
    displayButtonName: {
      type: String,
    },
    loadOnMount: {
      type: Boolean,
      default: () => true,
    },
    showField: {
      type: Boolean,
      default: () => true,
    },
    showLabel: {
      type: Boolean,
      default: () => true,
    },
    returnFullObject: {
      type: Boolean,
      default: () => false,
    },
    modalSizeClass: {
      type: String,
      default: () => 'modal-lg2',
    },
    helpText: {
      type: String,
    },
    model: {
      validator: (prop) =>
        [
          'page',
          'operator',
          'menu',
          'game',
          'banner',
          'top_list',
          'software_provider',
          'payment_method',
          'game_category',
          'sport',
          'livegoal_event',
          'event',
          'events',
          'provider',
          'team',
          'tournament',
          'author',
          'gambling_company',
          'odds',
          'page_path',
        ].includes(prop),
    },
    parameters: {
      type: Object,
      default: () => {},
    },
    customColumns: {
      type: Array,
      default: () => [],
    },
    customColumnTemplates: {
      type: Object,
      default: () => {},
    },
    value: {},
    valueLabel: {
      default: () => null,
    },
    customLabel: {
      default: () => null,
    },
    multipleSelect: {
      type: Boolean,
      default: () => false,
    },
    notifiedByField: {
      type: Object,
      default: () => {},
    },
    fromParam: {
      type: String,
      default: () => null,
    },
    excludeStatus: {
      type: String,
      default: () => null,
    },
    valueKey: {
      default: () => null,
    },
    basicTable: {
      type: Boolean,
      default: () => true,
    },
    enableTooltip: {
      type: Boolean,
      default: () => false,
    },
  },
  mounted() {
    if (this.value && this.loadOnMount) {
      this.getValue()
    }
    document.addEventListener('keyup', this.escClose)
  },
  destroyed() {
    document.removeEventListener('keyup', this.escClose)
  },
  updated() {
    if (this.value && !this.valueLabel && !this.loading) {
      this.getValue()
    }
  },
  computed: {
    extraParams() {
      let fromParamObj = {
        without_homepage: this.fromParam,
        exclude_status: this.excludeStatus,
      }

      return Object.assign(
        {},
        this.filterObject(Object.assign({}, this.notifiedFieldsParams, fromParamObj), [undefined, null]),
        this.fromParam,
        this.parameters
      )
    },
    modalTitle() {
      return `Select ${this.$prettyLabels(this.model)}`
    },
    modelColumns() {
      let columns = ModelSelectHelper.getModelColumns(this.model)

      if (this.customColumns.length > 0) {
        columns = [...columns, ...this.customColumns]
      }

      return columns
    },
    modelColumnTemplates() {
      return Object.assign(ModelSelectHelper.getModelColumnTemplates(this.model), this.customColumnTemplates)
    },
    modelPath() {
      return ModelSelectHelper.getModelPath(this.model)
    },
    // path used in the modal table where models are selected
    modelPathTable() {
      let params = Object.assign(
        this.value ? { ids_not: this.value, hide_past_events: 1 } : { hide_past_events: 1 },
        this.extraParams,
        ModelSelectHelper.getParams(this.model, this.multipleSelect, null, false)
      )

      return `${this.modelPath}?${Object.entries(params)
        .map(([key, val]) => `${key}=${val}`)
        .join('&')}`
    },
    // path used in the table that shows the selected rows (if multi select)
    selectedPathTable() {
      let params = Object.assign(
        this.value ? { ids: this.value } : {},
        this.extraParams,
        ModelSelectHelper.getParams(this.model, this.multipleSelect, this.value, true)
      )
      let path = `${this.modelPath}?${Object.entries(params)
        .map(([key, val]) => `${key}=${val}`)
        .join('&')}`

      return path
    },
    selectLabel() {
      let displayName = this.displayName ? this.displayName : this.model
      displayName = this.displayButtonName ? this.displayButtonName : displayName
      return `Select ${this.$prettyLabels(displayName)}`
    },
    isDisabled() {
      let disabled = false
      if (this.parameters) {
        disabled = !this.parameters.affiliate_id && !this.parameters.site_id
      }
      return disabled
    },
    getBaseModelSelectLoadedPage() {
      return this.$store.getters['app/getBaseModelSelectLoadedPage']
    },
    statefulColumns() {
      return !this.basicTable
    },
  },
  methods: {
    escClose(event) {
      if (event.key === 'Escape') {
        this.close()
      }
    },
    updateParams() {
      let extraNotifiedFields = []
      if (this.notifiedByField && this.$parent.$parent.object) {
        extraNotifiedFields[this.notifiedByField.field] =
          this.$parent.$parent.object[this.notifiedByField.field]
      }
      this.notifiedFieldsParams = extraNotifiedFields
    },
    removeItem(row, event) {
      event && event.preventDefault()
      if (!this.value) {
        this.$emit('valuePicked', null)
        return
      }
      let newValue = this.value.toString().split(',')
      newValue = newValue.filter((item) => item != row.id)
      this.$emit('valuePicked', newValue.length > 0 ? newValue.join(',') : null)
    },
    show(event) {
      event && event.preventDefault()
      this.updateParams()
      this.showModal = true
      this.$nextTick(() => {
        document.getElementById('modal') && document.getElementById('modal').appendChild(this.$refs.modal)
        setTimeout(() => {
          this.$refs.modal.classList.add('show')
        }, 100)
        this.emitter.emit('foucsOnSearch', true)
      })
    },
    getValueLabel(model) {
      let valueKeyName = ModelSelectHelper.getValueKeyName(this.model)

      if (this.valueKey) {
        valueKeyName = this.valueKey
      }

      if (valueKeyName instanceof Array) {
        let valueNames = []
        valueKeyName.forEach((item) => {
          valueNames.push(this.findInObject(item.split('.'), model))
        })
        return valueNames.join('|')
      }
      return this.findInObject(valueKeyName.split('.'), model)
    },
    setValueHandler(value) {
      this.selectedRow = value
      if (this.multipleSelect) {
        let valueLabel = []
        let ids = []

        value.forEach((item) => {
          valueLabel.push(this.getValueLabel(item))
          ids.push(ModelSelectHelper.getValueKey(this.model, item))
        })

        this.$emit('valuePicked', ids, valueLabel)
        this.$refs.list.search()
      } else if (this.multipleSelect === false) {
        const valueLabel = this.getValueLabel(value)
        if (this.returnFullObject) {
          this.$emit('valuePicked', value, valueLabel)
        } else {
          this.$emit('valuePicked', ModelSelectHelper.getValueKey(this.model, value), valueLabel)
          this.$store.dispatch('app/setBaseModelSelectLoadedPage', value)
        }
      } else {
        this.disabled = true
      }
    },
    getValue() {
      // check if page is already loaded from modules
      const pageIndex = this.getBaseModelSelectLoadedPage.findIndex((value) => value.id === this.value)
      if (pageIndex !== -1) {
        this.setValueHandler(this.getBaseModelSelectLoadedPage[pageIndex])
        return
      }

      this.loading = true
      AbstractService.get(
        this.modelPath,
        Object.assign(
          {},
          this.extraParams,
          ModelSelectHelper.getParams(this.model, this.multipleSelect, this.value, true)
        )
      )
        .then((response) => {
          this.setValueHandler(this.multipleSelect ? response.data.result : response.data.result[0])
        })
        .catch(() => {
          this.disabled = true
        })
        .finally(() => {
          this.loading = false
        })
    },
    buildQueryParams() {
      return Object.entries(
        Object.assign(
          this.extraParams,
          ModelSelectHelper.getParams(this.model, this.multipleSelect, this.value, false)
        )
      )
        .map(([key, val]) => `${key}=${val}`)
        .join('&')
    },
    clearAndEnable() {
      //Cleared previous stored value for toplist items count
      this.disabled = false
      this.$emit('valuePicked', null, null)
      this.$store.commit('dynamicForm/removeToplist', this.value)
    },
    bulkSelect(ids) {
      this.$emit('valuePicked', this.value ? [...ids, ...this.value.toString().split(',')] : ids, null)
      this.close()
    },
    rowClick(event, row) {
      const valueLabel = this.getValueLabel(row)
      this.selectedRow = row
      if (this.returnFullObject) {
        this.$emit('valuePicked', row, valueLabel)
      } else {
        this.$emit('valuePicked', ModelSelectHelper.getValueKey(this.model, row), valueLabel)
      }
      this.close()
    },
    close() {
      this.$refs.modal && this.$refs.modal.classList.remove('show')
      setTimeout(() => {
        this.showModal = false
      }, 100)
    },
  },
}
</script>

<style scoped>
h1 {
  color: #0860a0 !important;
}
</style>
