<template>
  <data-table
    model="Permission"
    serviceFile="PermissionsService"
    path="backoffice/permissions"
    :showCreate="false"
    :showRemove="false"
    :showEditModal="false"
    :customRowClick="() => false"
    :columnTemplates="columnTemplates()"
    :columns="columns()"
  />
</template>

<script>
import TableColumnTemplate from '@constants/table-column-template.js'

export default {
  created() {
    this.setTitle()
  },
  methods: {
    columns() {
      return [
        { field: 'sub_group', type: 'text', label: 'Name', filterable: true },
        { field: 'group', type: 'text', filterable: true },
        { field: 'name', type: 'text', label: 'Method', filterable: false },
        { field: 'updated_at', type: 'text', filterable: true, template: TableColumnTemplate.RELATIVE_DATE },
      ]
    },
    columnTemplates() {
      return {
        name: function (row) {
          return row.name.split('.')[0]
        },
      }
    },
  },
}
</script>
