<template>
  <div class="sidebar-body-content mw-100 d-flex bg-white card">
    <div class="card-header p-2">
      <div class="w-50">
        <base-select
          label="Site"
          v-model="siteId"
          :clearable="false"
          path="hercules/sites/sites?orderBy=name&ascending=1"
          ref="siteSelect"
          option-label-key="name"
          :select-first-after-fetch="true"
          class="pr-2 pl-2 mb-0"
        />
      </div>
      <div class="w-50" v-if="siteId">
        <base-select
          label="Market"
          v-model="marketId"
          ref="marketSelect"
          placeholder="Choose market"
          :countryFlag="'country_code'"
          :loadOnMount="false"
          :select-first-after-fetch="true"
          :path="`hercules/sites/markets/by-site/${siteId}`"
          class="pr-2 pl-2 mb-0"
        />
      </div>
      <template v-if="!fromSchema">
        <div class="w-50" v-if="siteId && marketId && !fromSchema">
          <base-select
            label="Page type"
            v-model="templateId"
            ref="templateSelect"
            placeholder="Choose page type"
            :loadOnMount="false"
            :select-first-after-fetch="true"
            optionLabelKey="name"
            :path="`hercules/sites/templates?site_id=${siteId}&orderBy=name&ascending=1&market_id=${marketId}`"
            class="pr-2 pl-2 mb-0"
          />
        </div>
        <div class="w-50">
          <base-select
            v-if="siteId && marketId && templateId && !fromSchema"
            label="Categories"
            v-model="categoryId"
            ref="categorySelect"
            :select-first-after-fetch="true"
            optionLabelKey="name"
            :clearable="true"
            :path="`hercules/sites/categories?site_id=${siteId}&orderBy=name&ascending=1&market_id=${marketId}&page_type_id=${templateId}`"
            class="pl-2 mb-0 w-100"
          />
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import BaseSelect from '@/components/fields/base-select.vue'

export default {
  props: {
    fromSchema: {
      type: Boolean,
      default: false,
    },
  },
  components: { BaseSelect },
  data() {
    return {
      categories: [],
    }
  },
  computed: {
    siteId: {
      get() {
        return this.$store.state.sidebar.siteId
      },
      set(value) {
        this.$store.commit('sidebar/setSite', value)
        this.$nextTick(() => {
          this.$refs.marketSelect.getOptions()
          if (!this.fromSchema && this.marketId) {
            this.$refs.templateSelect.getOptions().then((options) => {
              if (options.length && this.templateId) {
                this.$refs.categorySelect.getOptions()
              }
            })
          }
        })
      },
    },
    marketId: {
      get() {
        return this.$store.state.sidebar.market
      },
      set(value) {
        this.$store.commit('sidebar/setMarket', value)
        if (!this.fromSchema && this.marketId) {
          this.$nextTick(() => {
            this.$refs.templateSelect.getOptions().then((options) => {
              if (options.length && this.templateId) {
                try {
                  this.$refs.categorySelect.getOptions()
                } catch (error) {
                  // console.log(error)
                }
              }
            })
          })
        }
      },
    },
    templateId: {
      get() {
        return this.$store.state.sidebar.pageType
      },
      set(value) {
        this.$store.commit('sidebar/setPageType', value)
        if (!this.fromSchema && this.templateId) {
          this.$nextTick(() => {
            try {
              this.$refs.categorySelect.getOptions()
            } catch (e) {
              // console.log(e)
            }
          })
        }
      },
    },
    categoryId: {
      get() {
        return this.$store.state.sidebar.category
      },
      set(value) {
        this.$store.commit('sidebar/setCategory', value)
      },
    },
    filters() {
      return {
        template_id: this.templateId,
        market_id: this.marketId,
        site_id: this.siteId,
        category_id: this.categoryId,
      }
    },
  },
  unmounted() {
    this.$store.commit('sidebar/setSite', null)
    this.$store.commit('sidebar/setMarket', null)
    this.$store.commit('sidebar/setPageType', null)
    this.$store.commit('sidebar/setCategory', null)
  },
}
</script>

<style scoped>
.type > li.active {
  margin-top: 0 !important;
}
</style>
