<template>
  <div>
    <data-table
      :path="path()"
      :columns="columns()"
      :column-templates="columnTemplates()"
      :rowActionButtons="rowActions"
      :showRemove="false"
      :showCreate="false"
      :enableRowClick="false"
      ref="table"
    />
    <clone-modal ref="cloneModal" @refresh="refreshList" :shortCodeDisabled="true" :labelDisabled="true" />
  </div>
</template>
<script>
import CloneModal from '@molecules/dms/toplists/clone-modal.vue'
import TableColumnTemplate from '@constants/table-column-template.js'
import WidgetOddsService from '@services/WidgetOddsService.js'

export default {
  created() {
    this.setTitle()
  },
  data() {
    return {
      title: `Widget Items`,
      rowActions: [
        {
          action: this.editList,
          title: 'Edit list',
          icon: 'uil uil-edit',
          class: 'bg-cobalt',
        },
        {
          action: this.clone,
          title: 'Clone',
          icon: 'uil uil-share-alt',
          class: 'bg-orange-75',
        },
      ],
    }
  },
  components: { CloneModal },
  methods: {
    columns() {
      return [
        { field: 'market', type: 'text', filterable: true },
        { field: 'short_code', type: 'text', filterable: false },
        { field: 'items_count', type: 'text', label: 'Operators', filterable: false, sortable: false },
        { field: 'updated_at', type: 'text', filterable: true, template: TableColumnTemplate.RELATIVE_DATE },
      ]
    },
    refreshList() {
      this.$refs?.table?.search()

      // Saving widget so that updated at of the odds widget table is also updated
      WidgetOddsService.update({
        id: this.$parent.$parent.widgetsList.id,
        affiliate_id: this.$parent.$parent.widgetsList.affiliate_id,
        type: this.$parent.$parent.widgetsList.type,
        toplist_shortcode: this.$parent.$parent.widgetsList.toplist_shortcode,
        odds: this.$parent.$parent.widgetsList.odds,
        event_ids: this.$parent.$parent.widgetsList.event_ids,
        geo_located: this.$parent.$parent.widgetsList.geo_located,
      }).then(() => {
        this.$parent.$parent.getWidget()
      })
    },
    clone(row) {
      this.$refs.cloneModal.show(row)
    },
    editList(row) {
      let route = this.$router.resolve({ path: `/toplists/${row.id}` })
      window.open(route.href, '_blank')
    },
    columnTemplates() {
      return Object.assign(
        {},
        {
          market: function (row) {
            if (row.market) {
              return row.market.label
            }
          },
        }
      )
    },
    path() {
      return `hercules/toplists/lists?short_code=${this.$parent.$parent.widgetsList.toplist_shortcode}&with=market&with_count=items&exact_match=short_code`
    },
  },
}
</script>
