<template>
  <div class="modal" ref="modal" tabindex="-1" role="dialog" v-click-outside="close" v-if="show">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Clone operator site data</h5>
          <button class="close" v-on:click="close"></button>
        </div>
        <dynamic-form
          :inModal="true"
          form-type="edit"
          v-if="object"
          ref="form"
          :formFields="structure.list"
          :path="structure.config.url"
          :structure="structure"
          :object="object"
          :on-submit="submit"
          submit-label="Clone"
        />
      </div>
    </div>
  </div>
</template>

<script>
import AffiliateOperatorsService from '@services/AffiliateOperatorsService.js'
import DynamicForm from '@/components/fields/DynamicForm.vue'
import OperatorSitesDataClone from '@models/OperatorSitesDataClone.js'

export default {
  components: { DynamicForm },
  data() {
    return {
      loading: false,
      object: null,
      structure: {},
      config: OperatorSitesDataClone.getConfig(),
    }
  },
  methods: {
    show(object) {
      document.getElementById('modal').appendChild(this.$el)
      this.structure = OperatorSitesDataClone.getStructure(object.operator_id)

      // clone object
      this.object = Object.assign({}, object)

      // let's reset review link and market
      this.object.review_link = ''
      this.object.affiliate = object.affiliate.website
      this.object.market = this.object.market_id = null
      this.object.target_operator_id = object.operator_id;

      this.$refs.modal.classList.add('show')
    },
    close() {
      this.$refs.modal && this.$refs.modal.classList.remove('show')
      this.object = null
    },
    async submit() {
      await AffiliateOperatorsService.clone(this.object)
        .then((response) => {
          if (response.data.messages) {
            this.showErrorMessages(response.data.messages)
            this.$refs.form.isLoading = false
          } else {
            this.showSuccessMessage('Site Data cloned')
            this.$refs.form.isLoading = false
            this.close()
          }
        })
        .catch(this.showUnknownErrorMessage)
        .finally(() => {
          this.$emit('refresh')
        })
    },
  },
}
</script>
