<template>
  <data-table
    model="cms/Banner"
    service-file="cms/BannersService"
    path="hercules/sites/banners?with=market,site"
    :columns="columns()"
    :show-edit-modal="false"
    :column-templates="columnTemplates()"
  />
</template>

<script>
import TableColumnTemplate from '@constants/table-column-template.js'
import BasicOptions from '@mixins/BasicOptions.js'

export default {
  created() {
    this.setTitle()
  },
  methods: {
    columns() {
      return [
        { field: 'name', type: 'text', filterable: true },
        { field: 'short_name', type: 'text', filterable: true },
        { field: 'orientation', type: 'text', filterable: true },
        { field: 'site', type: 'text', label: 'Site', filterable: true },
        { field: 'market', type: 'text', label: 'Market', filterable: true },
        Object.assign({}, BasicOptions.newActiveColumn(), { template: TableColumnTemplate.BOOLEAN }),
        { field: 'updated_at', type: 'text', filterable: true, template: TableColumnTemplate.RELATIVE_DATE },
      ]
    },
    columnTemplates() {
      return Object.assign(
        {
          market: function (row) {
            return row.market ? row.market.label : ''
          },
        },
        {
          site: function (row) {
            return row.site.name
          },
        }
      )
    },
  },
}
</script>
