<template>
  <div class="card">
    <dynamic-form
      ref="form"
      :inCard="true"
      form-type="edit"
      :path="config.url"
      :formFields="structure.list"
      :structure="structure"
      :object="menu"
      :showRemove="false"
      :on-submit="update"
      pusherModel="sites-menus"
    />
  </div>
</template>
<script>
import Menu from '@models/cms/Menu.js'
import DynamicForm from '@/components/fields/DynamicForm.vue'
import MenusService from '@services/MenusService.js'

export default {
  data() {
    return {
      templateOptions: [],
      structure: { list: [] },
      config: Menu.getConfig(),
      menu: null,
    }
  },
  computed: {
    menuModel() {
      return { ...this.$store.state.editPage.model }
    },
  },
  mounted() {
    this.structure = Menu.getStructure()
    this.menu = this.menuModel ?? this.getMenu()
    this.setTitle(`${this.$parent.$parent.title ? this.$parent.$parent.title + ':' : ''} General`)
  },
  methods: {
    getMenu() {
      MenusService.get({
        id: this.$route.params.id,
        with: ['children.children', 'site', 'market'].join(),
      })
        .then((response) => {
          if (response.data.messages) {
            this.showErrorMessages(response.data.messages[0])
            this.$router.push('/site-menus')
          } else {
            this.menu = response.data.result[0]
          }
        })
        .catch(this.showUnknownErrorMessage)
    },
    async update() {
      this.$refs.form.isLoading = true
      await MenusService.update(this.menu)
        .then((response) => {
          this.menu = response.data.result
          this.showSuccessMessage('Menu saved')
        })
        .catch(this.showUnknownErrorMessage)
        .finally(() => {
          this.$refs.form.isLoading = false
        })
    },
  },
  components: {
    DynamicForm,
  },
}
</script>
