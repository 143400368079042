<template>
  <div>
    <ul class="nav nav-tabs mb-3" v-if="currentSiteId">
      <router-link
        tag="li"
        :to="{ path: `/operators/${route.params.id}/sites-data/edit/${currentSiteId}` }"
        exact
        active-class="active"
      >
        <a>Sites Data</a>
      </router-link>
      <router-link
        tag="li"
        :to="{ path: `/operators/${route.params.id}/sites-data/bonuses/${currentSiteId}` }"
        exact
        active-class="active"
      >
        <a>Bonuses</a>
      </router-link>
      <router-link
        tag="li"
        :to="{ path: `/operators/${route.params.id}/sites-data/links/${currentSiteId}` }"
        exact
        active-class="active"
      >
        <a>Links</a>
      </router-link>
      <router-link
        v-if="toplistQueryParams"
        tag="li"
        :to="{ path: `/operators/${route.params.id}/toplists`, query: toplistQueryParams }"
        exact
        active-class="active"
      >
        <a>Toplists</a>
      </router-link>
    </ul>

    <!-- Dynamic content -->
    <router-view></router-view>
  </div>
</template>

<script setup>
import { ref, watch, computed } from 'vue'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'

const store = useStore()
const route = useRoute()
const currentSiteId = ref(null)

const toplistParams = computed(() => store.state['data-table'] && store.state['data-table'].toplistParams)

const toplistQueryParams = computed(() => {
  if (!toplistParams.value) return null

  return {
    markets_label: toplistParams.value.markets_label || '',
    sites_name: toplistParams.value.sites_name || '',
    lists_type: toplistParams.value.lists_type || '',
  }
})

watch(
  () => route.params,
  (newParams) => {
    if (newParams.siteId) {
      currentSiteId.value = newParams.siteId
    } else if (newParams.bonusId) {
      currentSiteId.value = newParams.bonusId
    } else if (newParams.linkId) {
      currentSiteId.value = newParams.linkId
    }
  },
  { immediate: true }
)
</script>
