<template>
  <data-table
    model="CryptoService"
    service-file="CryptoServicesService"
    path="hercules/cryptos/services"
    :rowActionButtons="rowActions()"
    :columns="columns()"
  />
</template>

<script>
import TableColumnTemplate from '@constants/table-column-template.js'

export default {
  created() {
    this.setTitle()
  },
  methods: {
    rowActions() {
      return [
        {
          action: this.translationAction,
          title: 'Translations',
          icon: 'uil uil-adjust',
          class: 'btn-info',
        },
      ]
    },
    translationAction(row) {
      let routeData = this.$router.resolve('translations?key=' + row.short_name)
      window.open(routeData.href, '_blank')
    },
    columns() {
      return [
        { field: 'id', type: 'text', filterable: true },
        { field: 'short_name', type: 'text', filterable: true },
        { field: 'name', type: 'text', filterable: true },
        { field: 'updated_at', filterable: true, template: TableColumnTemplate.RELATIVE_DATE },
      ]
    },
  },
}
</script>
