import { Plugin, PluginKey } from '@tiptap/pm/state'
import TableCell from '@tiptap/extension-table-cell'
import Component from './CustomTableComponent.vue'
import { VueNodeViewRenderer } from '@tiptap/vue-3'
import { styleAttribute } from './tiptapHelpers'

export default TableCell.extend({
  addProseMirrorPlugins() {
    const self = this
    return [
      new Plugin({
        key: new PluginKey('eventHandler'),
        props: {
          handleKeyDown(view, event) {
            if (
              event.key === 'Enter' &&
              (self.editor.isActive('tableCell') || self.editor.isActive('tableHeader'))
            ) {
              // Manually insert a hard break node
              self.editor.chain().focus().insertContent({ type: 'hardBreak' }).run()

              event.preventDefault()
              return true
            }
            return false
          },
        },
      }),
    ]
  },
  content: '(text | image | hardBreak)*',
  addAttributes() {
    return {
      ...this.parent?.(),
      dir: {
        default: null,
        parseHTML: (element) => {
          return element.getAttribute('dir')
        },
        renderHTML: (attributes) => {
          if (attributes.dir === null || attributes.dir === 'ltr') {
            return {}
          }
          return { dir: attributes.dir }
        },
      },
      style: styleAttribute(['color', 'background-color', 'text-align', 'vertical-align']),
    }
  },
  addNodeView() {
    return VueNodeViewRenderer(Component)
  },
})
