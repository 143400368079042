<template>
  <div class="sidebar-body-content mw-100 d-flex bg-white card">
    <div class="card-header p-2">
      <div class="w-50">
        <base-select
          class="w-100 pr-2 pl-2 mb-0 bg-select"
          :path="'hercules/operators/bonuses?with=market&operator_id=' + operatorId"
          optionLabelKey="market_label"
          v-model="market"
          label="Market"
          :loadOnMount="false"
          trackBy="market_label"
          placeholder="Select a market"
          selectLabel="market_label"
          :countryFlag="'market.country_code'"
          :clearable="true"
          :searchBy="'market_label'"
          :filterUnique="true"
          @update:modelValue="onFilterChange"
          @isValueClearedListener="isCleared"
          :key="reRenderBaseSelect"
        />
      </div>
      <div class="w-50">
        <base-select
          class="w-100 pr-2 pl-2 mb-0 bg-select"
          :path="operatorTypePath"
          :loadOnMount="false"
          :clearable="true"
          :filterUnique="true"
          optionLabelKey="type"
          trackBy="type"
          :searchBy="'type'"
          v-model="type"
          label="Type"
          @isValueClearedListener="isTypeCleared"
          @update:modelValue="onFilterChange"
          :key="reRenderTypeSelect"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, defineEmits, computed, watch } from 'vue'
import BaseSelect from '@/components/fields/base-select.vue'
import { useRoute } from 'vue-router'
import { useStore } from 'vuex'

const store = useStore()
const route = useRoute()
const isMarketPreSelected = computed(() => store.state.editPage.marketId)
const market = ref(isMarketPreSelected.value)
const type = ref()
const reRenderBaseSelect = ref(0)
const reRenderTypeSelect = ref(0)
const emit = defineEmits(['filterChange'])

const operatorId = computed(() => {
  return route.params.id
})

const operatorTypePath = computed(() => {
  return `hercules/operators/bonuses?with=market&operator_id=${operatorId.value}`
})

const onFilterChange = () => {
  emit('filterChange', market.value, type.value)
  store.commit('editPage/setMarketId', market.value || null)
}

const isCleared = () => {
  reRenderBaseSelect.value++
}

const isTypeCleared = () => {
  reRenderTypeSelect.value++
}

watch([market, type], () => {
  onFilterChange()
})
</script>
<style scoped>
.rounded-md {
  border-radius: 8px;
}
</style>
