<template>
  <div class="modal in" ref="modal" tabindex="-1" role="dialog" v-click-outside="hide" @click="clickOutside">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-body p-3" :style="{ minHeight: '44px' }">
          <div class="d-flex justify-content-between mb-3">
            <div class="input-group">
              <div class="input-group-prepend">
                <div class="input-group-text">
                  <img v-if="loading" src="/placeholder-spin.svg" style="height: 26px" />
                  <i v-else class="uil uil-search"></i>
                </div>
              </div>
              <input
                type="text"
                ref="input"
                v-model="search"
                class="form-control"
                @keydown.enter.exact.prevent="selectFirstAndBlur"
                @keydown.down.exact.prevent="goDown"
                placeholder="Search for anything"
              />
              <div class="input-group-append">
                <div class="input-group-text" :class="[loading && 'faded']">
                  <span v-if="timing">{{ timing }} ms</span>
                </div>
              </div>
            </div>
          </div>
          <div
            class="overflow-auto border search-results-wrapper"
            ref="resultsWrapper"
            v-if="hasResults"
            :style="{ maxHeight: '30rem' }"
          >
            <div
              v-for="(indexResults, headIndex) in indexesWithResults"
              :key="headIndex"
              class="px-2 py-2 search-index-wrapper"
            >
              <h6 class="mt-1 px-1">{{ getConfig(headIndex).name }}</h6>
              <div class="">
                <search-result-item
                  v-for="result in indexResults"
                  :key="result.index"
                  :index="result.index"
                  :result="result"
                  :activeResultIndex="activeResultIndex"
                  @close="hide"
                  :config="getConfig(headIndex)"
                />
              </div>
            </div>
          </div>
          <div v-else-if="!loading && noResultsFound">No results were found</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BaseInput from '@atoms/fields/base-input.vue'
import BaseSelect from '@/components/fields/base-select.vue'
import SearchResultItem from '@atoms/misc/search/search-result-item.vue'
import _ from 'lodash'
import hotkeys from 'hotkeys-js'

const indexes = [
  {
    name: 'All',
  },
  {
    name: 'Operators',
    index: 'operators_operator',
    link: '/operators/{id}',
    image: true,
  },
  {
    name: 'Pages',
    index: 'sites_page',
    link: '/site-pages/{id}',
  },
  {
    name: 'Games',
    index: 'games_game',
    link: '/games/{id}',
    image: true,
    timestamps: true,
  },
  {
    name: 'Toplists',
    index: 'toplists_toplist',
    link: '/toplists/{id}',
  },
]

export default {
  components: { BaseInput, SearchResultItem, BaseSelect },
  data() {
    return {
      search: null,
      loading: false,
      noResultsFound: false,
      results: [],
      timing: null,
      activeIndex: indexes[0],
      activeResultIndex: null,
      resultsByIndex: [],
    }
  },
  computed: {
    hasResults() {
      return Object.keys(this.indexesWithResults).length > 0 && !this.loading
    },
    numberOfResults() {
      return Object.keys(this.indexesWithResults).reduce((acc, index) => {
        return acc + this.indexesWithResults[index].length
      }, 0)
    },
    indexesWithResults() {
      let result = {}
      let index = -1
      indexes
        .map((i) => i.index)
        .forEach((indexKey) => {
          let indexResults = []
          if (this.results[indexKey] === undefined || this.results[indexKey].length === 0) {
            return
          }
          this.results[indexKey].forEach((result) => {
            indexResults.push({ ...result, ...{ index: ++index } })
          })
          result[indexKey] = indexResults
        })
      return result
    },
    tabOptions() {
      return indexes.map((i) => {
        return {
          ...i,
          active: i.index === this.activeIndex.index,
        }
      })
    },
  },
  mounted() {
    hotkeys('backspace', 'search', (event) => {
      event.preventDefault()
      this.$refs?.input.focus()
    })
    hotkeys('down', 'search', this.goDown)
    hotkeys('up', 'search', this.goUp)
    hotkeys('escape', 'search', this.hide)
    hotkeys('enter', 'search', this.navigateToResult)
    hotkeys.setScope('search')
    setTimeout(() => {
      this.$refs?.input.focus()
    }, 100)
  },
  beforeDestroy() {
    hotkeys.deleteScope('search', 'all')
  },
  watch: {
    search: _.debounce(function () {
      if (this.search.length === 0) {
        this.results = []
        this.timing = null
        this.resultsByIndex = null
        return
      }
      this.doSearch()
    }, 200),
    activeIndex() {
      this.doSearch()
    },
  },
  methods: {
    navigateToResult() {
      if (!this.activeResultIndex) {
        this.activeResultIndex = 0
      }

      let theResult = null

      // Iterating over keys of 'this.indexesWithResults'
      // eslint-disable-next-line no-unused-vars
      for (const index in this.indexesWithResults) {
        if (Object.hasOwn(this.indexesWithResults, index)) {
          // Finding the result using the 'find' method
          theResult = this.indexesWithResults[index].find((result) => result.index === this.activeResultIndex)
          if (theResult) break // Exit loop if 'theResult' is found
        }
      }

      if (!theResult) return

      const theIndex = indexes.find((i) => i.index === theResult._index)

      if (!theIndex) return

      if (this.$route.path === theIndex.link.replace('{id}', theResult._id)) {
        this.hide()
        return
      }

      this.$router.push(theIndex.link.replace('{id}', theResult._id))

      this.hide()
    },
    getConfig(index) {
      return indexes.find((i) => i.index === index)
    },
    goDown(event) {
      event.preventDefault()
      if (this.numberOfResults === 0) return

      // cannot go down if we are at the last result
      if (this.numberOfResults > 1 && this.numberOfResults === this.activeResultIndex + 1) return

      // nothing has been selected, select first one
      if (this.activeResultIndex === null) {
        this.$refs.input.blur()
        this.activeResultIndex = 0
        return
      }

      this.$refs.input.blur()
      this.activeResultIndex++
      this.scrollToResult()
    },
    isVisible(ele, container) {
      const eleRect = ele.getBoundingClientRect()
      const containerRect = container.getBoundingClientRect()

      return eleRect.top >= containerRect.top && eleRect.bottom <= containerRect.bottom
    },
    scrollToResult() {
      const nextElement = document.getElementById(`search-result-${this.activeResultIndex}`)
      // if element is not visible, scroll to it
      if (!this.isVisible(nextElement, this.$refs.resultsWrapper)) {
        nextElement.scrollIntoView()
      }
    },
    goUp(event) {
      event.preventDefault()
      if (this.numberOfResults === 0) return
      if (this.activeResultIndex === 0) {
        this.$refs?.input.focus()
        this.activeResultIndex = null
      } else {
        this.activeResultIndex--
        if (this.activeResultIndex === 0) {
          this.$refs.resultsWrapper.scrollTop = 0
        } else {
          this.scrollToResult()
        }
      }
    },
    doSearch() {
      const start = Date.now()
      this.loading = true
      let params = { query: this.search }
      if (this.activeIndex.name !== 'All') {
        params.index = this.activeIndex.index
      }
      this.$http
        .get(`hercules/search/search?query=${this.search}`, { params: params })
        .then((response) => {
          if (response.data.success === false) {
            this.showErrorMessage(response.data.messages[0])
            return
          }
          this.results = response.data.result
          this.activeResultIndex = null
          this.noResultsFound = Object.keys(this.results).every((key) => this.results[key].length === 0)
        })
        .finally(() => {
          this.loading = false
          this.timing = Date.now() - start
        })
        .catch(() => {
          this.showErrorMessage('Failed to search')
        })
    },
    selectFirstAndBlur() {
      this.activeResultIndex = 0
      this.$refs.input.blur()
    },
    clickOutside(event) {
      if (event.target === this.$refs.modal && event.target !== this.$refs.modalContent) {
        this.hide()
      }
    },
    hide() {
      this.$emit('close')
    },
  },
  props: {},
}
</script>
<style lang="scss">
.search-results-wrapper {
  max-height: 500px;
}
.search-index-wrapper {
  // :not(:last-child) {
  border-bottom: 1px solid #efefef;
  // }
}
</style>
