<template>
  <div v-if="template">
    <div class="row">
      <div class="col">
        <h3>
          <router-link
            :to="{ path: `/site-templates` }"
            data-tooltip="Back to templates"
            data-tooltip-position="right center"
          >
            <i class="uil uil-angle-left"></i>
          </router-link>
          <span class="mr-3 ml-2">{{ $capitalize(template.name) }}</span>
        </h3>
        <div class="additional mb-2">
          <span>Last updated: {{ $dateRelative(template.updated_at) }}</span>
          <span class="badge bg-azure ml-2">{{ template.site?.name }}</span>
          <span class="badge bg-green ml-2" v-if="template.active">active</span>
          <span class="badge bg-black-25 ml-2" v-if="!template.active">inactive</span>
        </div>
      </div>
    </div>
    <ul class="nav nav-tabs mb-3">
      <router-link tag="li" :to="{ path: `/site-templates/${template.id}` }" exact>
        <a>General</a>
      </router-link>
      <router-link tag="li" :to="{ path: `/site-templates/${template.id}/blocks` }" exact>
        <a>Template Blocks</a>
      </router-link>
      <router-link tag="li" :to="{ path: `/site-templates/${template.id}/sections` }" exact>
        <a>Section configurations</a>
      </router-link>
      <router-link tag="li" :to="{ path: `/site-templates/${template.id}/page-styles` }" exact>
        <a>Page Styles</a>
      </router-link>
      <router-link tag="li" :to="{ path: `/site-templates/${template.id}/extra-fields` }" exact>
        <a>Extra fields</a>
      </router-link>
      <router-link tag="li" :to="{ path: `/site-templates/${template.id}/seo` }" exact>
        <a>Default SEO</a>
      </router-link>
      <router-link
        tag="li"
        :to="{ path: `/site-templates/${template.id}/autogenerated-content` }"
        exact
        v-if="toggleTab"
      >
        <a>Autogenerated Content</a>
      </router-link>
    </ul>
    <router-view></router-view>
  </div>
</template>

<script>
import TemplatesService from '@services/TemplatesService.js'

export default {
  data() {
    return {
      template: null,
      show: false,
    }
  },
  computed: {
    toggleTab: {
      get: function () {
        return this.show
      },
      set: function (newValue) {
        this.show = newValue
      },
    },
  },
  mounted() {
    this.getSiteLanguage()
  },
  methods: {
    getSiteLanguage() {
      TemplatesService.getOne(this.$route.params['id'], { with: 'market,site' })
        .then((response) => {
          if (response.data.messages) {
            this.showErrorMessages(response.data.messages)
            response.data.messages.some((message) => {
              if (message.indexOf('Template not found') >= 0) {
                this.$router.push('/site-templates')
              }
            })
          } else {
            this.template = response.data.result[0]
            this.toggleTab = this.template.enable_autogenerated_content_tab
          }
        })
        .catch(this.showUnknownErrorMessage)
    },
  },
}
</script>
