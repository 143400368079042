<template>
  <div class="modal" ref="modal" tabindex="-1" role="dialog" v-click-outside="close">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Translations Upload</h5>
          <button class="close" v-on:click="close"></button>
        </div>
        <div class="modal-body">
          <div class="form-group">
            <label>File (.csv)</label>
            <a class="d-inline ml-2 form-text text-muted" href="/translations_example.csv" target="_blank"
              ><small>Example CSV file</small></a
            >
            <input
              type="file"
              ref="file"
              class="form-control-file mt-2"
              accept="text/csv"
              @change="onChange"
            />
          </div>

          <div v-if="message" class="notification">
            <div class="alert" v-bind:class="messageClass">
              {{ message }}
            </div>

            <table
              v-if="csvErrors && csvErrors.length > 0"
              ref="errorList"
              class="table table-sm errors summary"
            >
              <thead>
                <tr>
                  <th>Row</th>
                  <th>Error</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(error, index) in csvErrors" :key="index">
                  <td>{{ error.split(':')[0] }}</td>
                  <td style="white-space: normal">{{ error.split(':')[1] }}</td>
                </tr>
                <tr>
                  <td colspan="2">
                    <a
                      ref="showMore"
                      class="font-italic d-flex align-items-center justify-content-center"
                      v-if="csvErrors.length > 2"
                      @click="showAllErrors"
                      >Show More</a
                    >
                  </td>
                </tr>
              </tbody>
            </table>
            <div v-if="csvSuccess" ref="successList" class="table table-sm errors summary">
              <div v-if="result.created > 0">
                <label class="alt">Created Translations</label>
                <span class="float-right additional">{{ result.created }}/{{ result.total }}</span>
                <div class="progress">
                  <div
                    class="progress-bar bg-success"
                    :data-progress="getPercentage(result.created, result.total)"
                  ></div>
                </div>
              </div>
              <div v-if="result.updated > 0" class="mt-3">
                <label class="alt">Updated Translations</label>
                <span class="float-right additional">{{ result.updated }}/{{ result.total }}</span>
                <div class="progress">
                  <div
                    class="progress-bar bg-warning"
                    :data-progress="getPercentage(result.updated, result.total)"
                  ></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button class="btn btn-secondary" v-bind:class="{ loading: loading }" @click="close()">
            Close
          </button>
          <button class="btn btn-primary ml-3" v-bind:class="{ loading: loading }" @click="submit()">
            Submit
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TranslationsServcie from '@services/TranslationsService.js'

export default {
  data() {
    return {
      formData: new FormData(),
      csvErrors: null,
      csvSuccess: false,
      message: null,
      loading: false,
      file: null,
      isWarning: false,
      result: null,
    }
  },
  computed: {
    messageClass: function () {
      return this.message.includes('Success') ? 'alert-success' : 'alert-error'
    },
  },
  watch: {
    file: function () {
      this.formData.append('file', this.file, this.file.name)
    },
  },
  mounted() {
    document.getElementById('modal').appendChild(this.$el)
    this.$refs.modal.classList.add('show')
  },
  methods: {
    getError(error) {
      return error.split(':')
    },
    async submit() {
      this.loading = true
      this.csvSuccess = false
      this.csvErrors = null
      this.message = null
      this.result = null
      await TranslationsServcie.csv(this.formData)
        .then((response) => {
          this.formData = new FormData()
          if (response.data.messages) {
            this.csvErrors = response.data.messages.splice(1, response.data.messages.length)
            this.message = response.data.messages[0]
            this.$refs.file.value = null
            this.$emit('refresh')
          } else {
            this.result = response.data.result.messages
            this.message =
              this.result.total > 0
                ? 'Success: Import completed.'
                : 'Success: Import completed, but there are no translations added or updated.'

            this.csvSuccess = true
            this.$refs.file.value = null
            this.$emit('refresh')
          }
        })
        .catch(() => {
          this.showUnknownErrorMessage
        })
        .finally(() => {
          this.loading = false
        })
    },
    close() {
      this.loading = false
      this.$parent.showCsvTranslationModal = false
    },
    onChange() {
      this.file = this.$refs.file.files[0]
    },
    showAllErrors() {
      this.$refs.errorList.classList.add('full')
      this.$refs.showMore.classList.add('d-none')
      this.$refs.showMore.classList.remove('d-flex')
    },
    getPercentage(value, total) {
      return total > 0 ? (value / total) * 100 : 0
    },
  },
}
</script>

<style scoped>
.errors tbody tr {
  display: none;
}

.errors.summary tbody tr:nth-child(-n + 2),
.errors.summary.full tbody tr:not(:last-child),
.errors.summary tbody tr:last-child {
  display: table-row;
}
</style>
