<template>
  <div class="modal" ref="modal" tabindex="-1" role="dialog" v-click-outside="close">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Bulk editing {{ ids.length }} affiliate {{ operatorsCountText }}</h5>
          <button class="close" v-on:click="close"></button>
        </div>
        <div class="modal-body">
          <div>
            <base-checkbox v-model="changeStatus" name="changeStatus" label="Change status" />
            <base-radio
              class="ml-3"
              :data="['Coming soon', 'Active', 'Inactive', 'Blacklisted', 'Not recommended']"
              v-model="status"
              name="status"
              v-if="changeStatus"
              :inlineLayout="false"
            />
            <base-input label="Notes" v-model="note" helpText="Write down notes on why are you changing status for selected operators"/>
          </div>
          <div class="callout callout-info">
            Bulk updating many operators at the same time can take up to 10 seconds to load
          </div>
          <div class="modal-footer d-flex justify-content-between">
            <div>
              <button class="btn btn-secondary" :class="{ loading: loading }" @click="close()">Cancel</button>
              <button class="btn btn-primary ml-2" :class="{ loading: loading }" @click="submit()">
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BaseCheckbox from '@atoms/fields/base-checkbox.vue'
import BaseRadio from '@atoms/fields/base-radio.vue'
import BaseDate from '@atoms/fields/base-date.vue'
import BaseSelect from '@/components/fields/base-select.vue'
import BaseCheckboxList from '@atoms/fields/base-checkbox-list.vue'
import BaseInput from '@atoms/fields/base-input.vue'
import AffiliateOperatorsService from '@services/AffiliateOperatorsService.js'

export default {
  components: { 
    BaseCheckbox, 
    BaseRadio, 
    BaseSelect, 
    BaseDate, 
    BaseCheckboxList,
    BaseInput
  },
  data() {
    return {
      ids: [],
      changeStatus: true,
      status: null,
      loading: false,
      note: null
    }
  },
  watch: {
    changeStatus: function () {
      if (!this.changeStatus) this.status = null
    },
  },
  props: {
    stateName: {
      type: String,
      default: () => 'data-table',
    },
  },
  mounted() {
    document.getElementById('modal').appendChild(this.$el)
  },
  computed: {
    operatorsCountText() {
      return `${this.ids.length === 1 ? 'operator' : 'operators'}`
    },
  },
  methods: {
    show(ids) {
      this.ids = ids
      this.$refs.modal.classList.add('show')
    },
    close() {
      this.loading = false
      this.$refs.modal && this.$refs.modal.classList.remove('show')
      this.$emit('closed')
    },
    buildUpdateObject() {
      let object = {}
      if (this.changeStatus && this.status) {
        object.status = this.status.value.toLowerCase()
        object.note = this.note
      }
      return object
    },
    clearBulkSelection() {
      this.$store.dispatch(`${this.stateName}/resetBulk`)
    },
    async submit() {
      if (Object.keys(this.buildUpdateObject()).length === 0) {
        this.showErrorMessages('Please add/choose values for bulk actions')
      } else {
        this.loading = true
        await AffiliateOperatorsService.bulkUpdate(
          Object.assign(this.buildUpdateObject(), {
            affiliate_operators_ids: this.ids,
            status: this.status.value.toLowerCase().replace(' ', '_'),
            note: this.note
          })
        )
          .then((response) => {
            if (response.data.messages) {
              this.showErrorMessages(response.data.messages)
            } else {
              this.clearBulkSelection()
              this.showSuccessMessage('Affiliate operators updated')
              this.$emit('refresh')
              this.close()
            }
          })
          .catch(() => {
            this.showUnknownErrorMessage
          })
          .finally(() => {
            this.loading = false
          })
      }
    },
  },
}
</script>
