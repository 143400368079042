<template>
  <div>
    <div class="row">
      <div class="col">
        <data-table
          model="cms/Page"
          service-file="PagesService"
          :path="path()"
          :createDefaults="createDefaults()"
          post-create-navigation-route="site-pages"
          :columns="columns()"
          :show-edit-modal="false"
          :customRowClick="rowClick"
          :show-create="false"
          :show-remove="false"
          @bulkClickByIds="bulkClick"
          :enableBulkSelect="!hasPermission('misc.sites/limited-to-own-pages')"
          ref="table"
        />
      </div>
      <page-edit-bulk
        v-if="showBulkEdit"
        ref="bulkEdit"
        :siteId="siteId"
        @refresh="$refs.table.search()"
        @closed="() => (showBulkEdit = false)"
        :showStatus="false"
        :showDelete="false"
        :showCategories="false"
      />
    </div>
  </div>
</template>

<script>
import TableColumnTemplate from '@constants/table-column-template.js'
import PageEditBulk from '@molecules/cms/pages/edit-bulk.vue'

export default {
  mounted() {
    this.setTitle(`${this.$parent.$parent.title ? this.$parent.$parent.title + ':' : ''} Author Pages`)
  },
  data() {
    return {
      siteId: this.$parent.$parent.author.site_id,
      title: `Author Pages`,
      showBulkEdit: false,
    }
  },
  components: { PageEditBulk },
  methods: {
    columns() {
      return [
        { field: 'title', type: 'text', filterable: true },
        { field: 'path', type: 'text', filterable: true, template: TableColumnTemplate.CLIPBOARD },
        { field: 'market.label', type: 'text', label: 'Market', filterable: true },
        { field: 'status', type: 'text', filterable: true, template: TableColumnTemplate.STATUS },
        { field: 'updated_at', type: 'text', filterable: true, template: TableColumnTemplate.RELATIVE_DATE },
      ]
    },
    path() {
      return `hercules/sites/pages?author_id=${this.$route.params['id']}&with=market`
    },
    rowClick(event, row) {
      window.open(`/site-pages/${row.id}`, '_blank')
    },
    bulkClick(ids) {
      if (ids.length === 1) {
        this.$router.push(`/site-pages/${ids[0]}`)
      } else if (ids.length > 1) {
        this.showBulkEdit = true
        this.$nextTick(() => {
          this.$refs.bulkEdit.show(ids)
        })
      }
    },
    createDefaults() {
      return {
        author_id: parseInt(this.$route.params['id']),
      }
    },
  },
}
</script>
