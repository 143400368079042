<template>
  <div class="row d-flex justify-content-center">
    <div class="card col-12 col-xl-6">
      <div class="card-header">
        <h3 class="card-title">Generate pages report</h3>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-10">
            <base-select
              label="Site"
              option-label-key="name"
              path="hercules/sites/sites?orderBy=name&ascending=1"
              v-model="site"
              :required="true"
              trackBy="id"
              @update:modelValue="changeSiteId"
            />
          </div>
        </div>
        <div class="row">
          <base-multiselect
            ref="marketSelect"
            :multiple="true"
            field="market"
            :loadOnMount="false"
            label="Markets"
            selectLabel="label"
            trackByOption="id"
            :required="true"
            :path="marketsPath()"
            :value="market"
            class="col-10 pr-2 pl-2 mb-0"
            @sync="syncMarket"
            @input="changeMarketId"
          />
        </div>
        <div class="row">
          <base-multiselect
            ref="pageTypeSelect"
            :multiple="true"
            field="pageType"
            :loadOnMount="false"
            label="Page Type"
            selectLabel="name"
            trackByOption="id"
            :path="`hercules/sites/templates?site_id=${site}&orderBy=name&ascending=1&market_id=${market}&fields_only[]=id,&fields_only[]=name`"
            :value="pageType"
            class="col-10 pr-2 pl-2 mb-0"
            @input="changePageTypeId"
            @sync="syncTemplate"
          />
        </div>
        <div class="row">
          <base-multiselect
            ref="categoriesSelect"
            :multiple="true"
            field="category"
            :loadOnMount="false"
            label="categories"
            selectLabel="name"
            trackByOption="id"
            :path="categoriesPath()"
            :value="category"
            class="col-10 pr-2 pl-2 mb-0"
            @sync="syncCategory"
          />
        </div>
        <div class="row">
          <div class="col-10">
            <base-select
              label="Data Section"
              option-label-key="label"
              v-model="section"
              :required="true"
              :customOptions="dataSection"
            />
          </div>
        </div>
      </div>
      <div class="card-footer">
        <button :class="{ loading: loading }" class="btn btn-primary icon-btn" @click="exportCsv">
          <i class="uil uil-file-export"></i>Export to CSV
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import BaseSelect from '@/components/fields/base-select.vue'
import BaseMultiselect from '@/components/fields/base-multiselect.vue'
import moment from 'moment'
import { ExportToCsv } from 'export-to-csv'

export default {
  components: { BaseSelect, BaseMultiselect },
  data() {
    return {
      site: null,
      market: [],
      pageType: [],
      category: [],
      section: null,
      dataSection: [
        { id: 'general', label: 'General' },
        { id: 'sections', label: 'Sections' },
      ],
      loading: false,
    }
  },
  created() {
    this.setTitle()
  },
  methods: {
    marketsPath() {
      if (this.site) {
        return `hercules/sites/markets/by-site/${this.site}?fields_only[]=id,&fields_only[]=label`
      }
    },
    categoriesPath() {
      const marketIds = this.market.map((item) => `market_id[]=${item}`).join('&')

      return `hercules/sites/categories?site_id=${this.site}&orderBy=name&ascending=1&${marketIds}&page_type_id=${this.pageType}&pages_report=true&fields_only[]=id&fields_only[]=name&fields_only[]=market_id`
    },
    syncMarket(_field, value, key) {
      this.market = value && Array.isArray(value) ? value.map((item) => item[key]) : null
    },
    syncTemplate(_field, value, key) {
      this.pageType = value && Array.isArray(value) ? value.map((item) => item[key]) : null
    },
    syncCategory(_field, value, key) {
      this.category = value && Array.isArray(value) ? value.map((item) => item[key]) : null
    },
    changeSiteId() {
      this.$nextTick(() => {
        this.$refs.marketSelect.getOptions()
      })
    },
    exportCsv() {
      this.loading = true
      const filename = `pages-report-${this.section}-data-${moment().format('YYYY-MM-DD-HHmm')}`

      let params = {
        siteId: this.site,
      }
      if (this.market) {
        params.markets = this.market
      }
      if (this.pageType) {
        params.pageTypes = this.pageType
      }
      if (this.category) {
        params.categories = this.category
      }
      if (this.section) {
        params.dataSection = this.section
      }

      this.$http
        .get(`hercules/sites/page-report`, { params })
        .then((response) => {
          if (response.data.messages) {
            this.showErrorMessages(response.data.messages)
            this.loading = false
          } else {
            const items = response.data.result
            if (items.length === 0) {
              this.showErrorMessages('No results for selected fields')
              this.loading = false
              return
            }
            const csvExporter = new ExportToCsv({
              useKeysAsHeaders: true,
              filename: filename,
            })
            csvExporter.generateCsv(items)
            this.loading = false
          }
        })
        .catch(() => {
          this.showUnknownErrorMessage
          this.loading = false
        })
    },
    changeMarketId() {
      this.$nextTick(() => {
        this.$refs.pageTypeSelect.getOptions()
      })
    },
    changePageTypeId() {
      this.$nextTick(() => {
        this.$refs.categoriesSelect.getOptions()
      })
    },
  },
}
</script>
