<script>
import BaseRadio from '@atoms/fields/base-radio.vue'
import BaseMultiselect from '../../../../fields/base-multiselect.vue'
import BaseDate from '@atoms/fields/base-date.vue'
import moment from 'moment/moment'

export default {
  components: { BaseDate, BaseMultiselect, BaseRadio },
  data() {
    return {
      object: {
        type: null,
        operator_ids: [],
        platform_ids: [],
        account_ids: [],
        business_unit_ids: [],
        operator_platform_ids: [],
        report_ids: [],
        dates_range: null,
        dry_run: true,
      },
      placeholders: {
        account_ids: 'Select one or multiple options',
        report_ids: 'Select one or multiple options',
      },
    }
  },
  methods: {
    reportsPath() {
      if (this.object.operator_ids.length === 1) {
        this.object.operator_platform_ids = this.$refs['operator_ids'].activeOptions[0].platform.id
      }
    },
    clear(withType = false) {
      this.object = {
        type: this.object.type,
        operator_ids: [],
        platform_ids: [],
        account_ids: [],
        business_unit_ids: [],
        report_ids: [],
        operator_platform_ids: [],
        dates_range: this.object.dates_range,
        dry_run: true,
      }

      if (withType) {
        this.object.type = null
      }

      this.$refs.business_unit_ids.deselectAll()
      this.$emit('syncData', this.object)
    },
    getDefaultDateRange() {
      let today = moment()
      let startMonth = today.clone().startOf('month')
      let endMonth = today.clone()

      if (startMonth.format('YYYY-MM-DD') === endMonth.format('YYYY-MM-DD')) {
        startMonth = startMonth.subtract(1, 'days')
      } else {
        endMonth = endMonth.subtract(1, 'days')
      }

      return startMonth.format('YYYY-MM-DD') + ' to ' + endMonth.format('YYYY-MM-DD')
    },
    getDatepickerConfig() {
      return {
        defaultDate: this.object.dates_range,
        maxDate: moment().format('YYYY-MM-DD'),
        mode: 'range',
      }
    },
    syncValues(field, value, key = 'id') {
      if (value) {
        if (Array.isArray(value)) {
          this.object[field] = value.map((item) => item[key])
        } else {
          this.object[field] = value
        }
      } else if (value === null) {
        this.object[field] = null
      }
      this.$emit('syncData', this.object)
    },
    watchField(field, value) {
      if (!value || !this.$refs[field]) {
        return
      }

      if (value.length > 1) {
        this.object[field] = []
        this.$refs[field].deselectAll()
        this.placeholders[field] = 'No options available'
      } else {
        this.placeholders[field] = 'Select one or multiple options'
      }

      this.$refs[field].activeOptions = []
      if (value.length === 1) {
        this.$refs[field].getOptions()
      }
    },
  },
  watch: {
    'object.type': function () {
      this.clear()
    },
    'object.operator_ids': function (value) {
      this.watchField('account_ids', value)
      this.watchField('report_ids', value)
      this.reportsPath()
    },
    'object.platform_ids': function (value) {
      this.watchField('report_ids', value)
    },
  },
}
</script>

<template>
  <div>
    <base-radio
      label="Type"
      :data="[
        { value: 1, label: 'Platform' },
        { value: 0, label: 'Operator' },
      ]"
      v-model="object.type"
      :useNameValue="false"
      :inlineLayout="true"
      :required="true"
      @sync="syncValues"
    />
    <base-multiselect
      key="operator_ids"
      :multiple="true"
      ref="operator_ids"
      id="operator_ids"
      path="koala/v1/operators?orderBy=name&ascending=1&limit=100"
      field="operator_ids"
      name="operator_ids"
      label="Operators"
      selectLabel="name"
      trackByOption="id"
      v-if="object.type === 0"
      searchBy="name"
      :value="object.operator_ids"
      @sync="syncValues"
      :max="-1"
    />
    <base-multiselect
      key="platform_ids"
      :multiple="true"
      ref="platform_ids"
      path="koala/v1/platforms?orderBy=name&ascending=1&scrapable=true&limit=100"
      field="platform_ids"
      name="platform_ids"
      label="Platforms"
      selectLabel="name"
      trackByOption="id"
      v-if="object.type === 1"
      searchBy="name"
      :value="object.platform_ids"
      @sync="syncValues"
    />
    <base-multiselect
      key="account_ids"
      :multiple="true"
      ref="account_ids"
      id="account_ids"
      path="koala/v1/accounts?orderBy=username&ascending=1&limit=100"
      field="account_ids"
      name="account_ids"
      label="Usernames"
      selectLabel="username"
      trackByOption="id"
      :notified-by-field="[
        {
          field: 'operator_ids',
          label: 'operators',
          param: 'operator_ids',
          request_field_name: 'operators',
          multi_filter_separated_by_comma: true,
        },
      ]"
      help-text="Add one operator to enable this field"
      :placeholder="placeholders.account_ids"
      v-if="object.type === 0"
      searchBy="username"
      :read-only="object.operator_ids.length !== 1"
      :value="object.account_ids"
      @sync="syncValues"
    />
    <base-multiselect
      key="report_ids"
      :multiple="true"
      ref="report_ids"
      id="report_ids"
      :path="`koala/v1/reports?platforms=${this.object.operator_platform_ids}`"
      field="report_ids"
      name="report_ids"
      label="Reports"
      selectLabel="name"
      trackByOption="id"
      help-text="Add one operator to enable this field"
      :placeholder="placeholders.report_ids"
      v-if="object.type === 0"
      searchBy="name"
      :read-only="object.operator_ids.length !== 1"
      :value="object.operator_platform_ids"
      @sync="syncValues"
    />
    <base-multiselect
      key="business_unit_ids"
      :multiple="true"
      ref="business_unit_ids"
      id="business_unit_ids"
      path="koala/v1/business-units?orderBy=name&ascending=1&limit=100"
      field="business_unit_ids"
      name="business_unit_ids"
      label="Business Units"
      selectLabel="name"
      trackByOption="id"
      searchBy="name"
      :value="object.business_unit_ids"
      @sync="syncValues"
    />
    <base-multiselect
      key="report_ids"
      :multiple="true"
      ref="report_ids"
      id="report_ids"
      path="koala/v1/reports"
      field="report_ids"
      name="report_ids"
      label="Reports"
      selectLabel="name"
      trackByOption="id"
      :notified-by-field="[
        {
          field: 'platform_ids',
          label: 'platforms',
          request_field_name: 'platforms',
          multi_filter_separated_by_comma: true,
        },
      ]"
      help-text="Add one platform to enable this field"
      :placeholder="placeholders.report_ids"
      v-if="object.type === 1"
      searchBy="name"
      :read-only="object.platform_ids.length !== 1"
      :value="object.report_ids"
      @sync="syncValues"
    />
    <base-date
      label="Dates"
      ref="dates_range"
      :config="getDatepickerConfig()"
      :defaultDate="getDefaultDateRange()"
      v-model="object.dates_range"
      @sync="syncValues"
      help-text="If the start and end date are the same, double-click on the date to choose it"
    />
  </div>
</template>
