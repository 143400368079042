<template>
  <data-table
    model="GameScreenshot"
    service-file="GameScreenshotsService"
    :path="path()"
    :columns="columns()"
    :create-defaults="createDefaults()"
  />
</template>

<script>
import TableColumnTemplate from '@constants/table-column-template.js'
import BasicOptions from '@mixins/BasicOptions.js'

export default {
  created() {
    this.setTitle(
      `${this.$parent.$parent.title ? this.$parent.$parent.title + ':' : ''} ${this.$route.meta.name}`
    )
  },
  data() {
    return {
      game_id: this.$route.params['id'],
    }
  },
  methods: {
    columns() {
      return [
        {
          field: 'image.url',
          type: 'text',
          filterable: false,
          label: 'Preview',
          template: TableColumnTemplate.IMAGE,
        },
        Object.assign({}, BasicOptions.newActiveColumn(), { template: TableColumnTemplate.BOOLEAN }),
        { field: 'updated_at', type: 'text', filterable: true, template: TableColumnTemplate.RELATIVE_DATE },
      ]
    },
    path() {
      return `hercules/games/game-screenshots?game_id=${this.$route.params['id']}&with=game`
    },
    createDefaults() {
      return {
        game_id: this.$route.params['id'],
        active: true,
      }
    },
  },
}
</script>

<style scoped></style>
