<template>
  <data-table
    ref="list"
    model="Operator"
    service-file="OperatorsService"
    :path="path"
    :columns="columns"
    :create-defaults="createDefaults"
    :column-templates="columnTemplates"
    :rowActionButtons="rowActions"
    :show-edit-modal="false"
  />
</template>

<script setup lang="jsx">
import { onMounted, getCurrentInstance } from 'vue'
import { useRouter } from 'vue-router'
import TableColumnTemplate from '@constants/table-column-template.js'

const router = useRouter()
const { proxy } = getCurrentInstance()

const path = `hercules/operators/operators?with=countries`

const createDefaults = {
  enabled: true,
}

const columns = [
  { field: 'id', type: 'text', filterable: true, sortable: true },
  { field: 'name', type: 'text', filterable: true },
  { field: 'short_name', type: 'text', filterable: true },
  {
    field: 'countries',
    type: 'text',
    class: 'col-countries',
    search_key: 'countries__id',
    advancedFilter: true,
    path: 'hercules/globals/countries',
    optionLabelKey: 'name',
    showModalAdvanced: true,
    basicFilterType: 'select',
    sortable: false,
  },
  { field: 'games_amount', type: 'text', filterable: true },
  { field: 'founded', type: 'text', filterable: true },
  {
    field: 'standardised_logo.url',
    type: 'text',
    filterable: false,
    label: 'Logo',
    template: TableColumnTemplate.IMAGE,
  },
  {
    field: 'updated_at',
    type: 'text',
    filterable: true,
    template: TableColumnTemplate.RELATIVE_DATE,
  },
]

const columnTemplates = {
  countries(row) {
    if (!row.countries || !row.countries.length) {
      return <span>N/A</span>
    }

    let countries = row.countries.map((country) => country?.name)

    if (countries.length <= 6) {
      return <span>{countries.join(', ')}</span>
    }

    let displayedCountries = [...countries].splice(0, 6).join(', ') + ' ...'
    let tooltipCountries = countries.join(', ')
    return (
      <span
        data-tooltip={tooltipCountries}
        class={`tooltipCountries${row.countries.length > 200 ? '-200' : ''}`}
      >
        {displayedCountries}
      </span>
    )
  },
}

const marketsData = (row) => {
  const routeData = router.resolve({
    path: `/operators/${row.id}/markets-data`,
  })
  window.open(routeData.href)
}

const sitesData = (row) => {
  const routeData = router.resolve({
    path: `/operators/${row.id}/sites-data`,
  })
  window.open(routeData.href)
}

const rowActions = [
  {
    action: marketsData,
    title: 'Markets Data',
    icon: '',
    class: 'btn-extra rounded',
  },
  {
    action: sitesData,
    title: 'Sites Data',
    icon: '',
    class: 'btn-info rounded',
  },
]

onMounted(() => {
  if (typeof proxy.setTitle === 'function') {
    proxy.setTitle('Operators')
  }
})
</script>

<style lang="scss">
.tooltipCountries-200 {
  &::after {
    width: 600px !important;
    white-space: normal;
    bottom: unset !important;
    top: 100%;
  }
  &::before {
    bottom: unset !important;
    top: 100% !important;
  }
}

.tooltipCountries {
  &::after {
    width: 500px !important;
    white-space: normal;
  }
}
</style>
