<template>
  <div>
    <PageDescriptions
      description="Template blocks are placeholder modules that are not editable in Hercules. They serve the purpose of
      telling the website developers which hard coded data should go in which part of the page. Once created
      in the table below, they can be used in pages of this page type."
    />
    <data-table
      model="cms/TemplateBlock"
      service-file="TemplateBlocksService"
      :path="`hercules/sites/template-blocks?with=template,section&template_id=${$route.params.id}`"
      :serviceParams="[this.$parent.$parent.template.site_id]"
      :columns="columns()"
      :create-defaults="createDefaults()"
      pusherModel="sites-template_blocks"
    />
  </div>
</template>
<script>
import TableColumnTemplate from '@constants/table-column-template.js'
import BasicOptions from '@mixins/BasicOptions.js'
import PageDescriptions from '@/components/PageDescriptions.vue'

export default {
  components: { PageDescriptions },
  created() {
    this.setTitle()
  },
  methods: {
    columns() {
      return [
        { field: 'name', type: 'text', filterable: true },
        { field: 'short_name', type: 'text', filterable: true },
        { field: 'section.name', type: 'text', label: 'Section', filterable: true },
        { field: 'anchor_label', type: 'text', filterable: true },
        Object.assign({}, BasicOptions.newActiveColumn(), { template: TableColumnTemplate.BOOLEAN }),
        { field: 'updated_at', type: 'text', filterable: true, template: TableColumnTemplate.RELATIVE_DATE },
      ]
    },
    createDefaults() {
      return {
        template_id: parseInt(this.$route.params['id']),
        site_id: this.$parent.$parent.template.site_id,
      }
    },
  },
}
</script>
