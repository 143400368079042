import Api from './Api'

export default {
  getFilters(data) {
    return Api().get(`hercules/sites/pages/filters`, {
      params: data,
    })
  },
  get(data) {
    return Api().get(`hercules/sites/pages`, {
      params: data,
    })
  },
  getOne(id, data) {
    return Api().get(`hercules/sites/pages/${id}`, {
      params: data,
    })
  },
  post(data) {
    const { content_type, path, site_id, site_market_id, status, template_id, title, agency, inhouse_user } =
      data

    let payload = {
      path,
      site_id,
      site_market_id,
      status,
      template_id,
      title,
    }

    if (content_type) {
      payload.content_type = content_type

      if (content_type === 'inhouse') {
        payload.inhouse_user = inhouse_user || ''
      } else if (content_type === 'agency') {
        payload.agency = agency || ''
      }
    }
    return Api().post(`hercules/sites/pages`, payload)
  },

  bulkUpdate(data) {
    return Api().put('hercules/sites/pages/bulk', data)
  },
  bulkRemove(data) {
    return Api().delete('hercules/sites/pages/bulk', {
      params: data,
    })
  },
  clone(data) {
    return Api().post(`hercules/sites/pages/clone`, data)
  },
  changePageType(data) {
    return Api().post(`hercules/sites/pages/change-page-type`, data)
  },
  changePagePath(data) {
    return Api().put(`hercules/sites/pages/change-page-path`, data)
  },
  update(data) {
    return Api().put(`hercules/sites/pages/${data.id}`, data)
  },
  remove(row) {
    return Api().delete(`hercules/sites/pages/${row.id}`)
  },
  fetchStructure() {
    return Api().get(`hercules/sites/pages/structure`)
  },
  rebuildSportsPage(data) {
    return Api().get(`hercules/sites/commands/create-event-pages`, {
      params: data,
    })
  },
}
