<template>
  <div
    class="modal"
    ref="modal"
    tabindex="-1"
    role="dialog"
    v-click-outside="close"
    v-bind:class="{ show: isActive }"
  >
    <div class="modal-dialog modal-lg2">
      <div class="modal-content">
        <div class="modal-header border-bottom pb-3">
          <div class="w-100 d-flex justify-content-between align-items-center">
            <div class="d-flex flex-column">
              <h2>{{ title }}</h2>
              <div v-if="author" class="text-muted">Created by: {{ author }}</div>
              <div v-if="when" class="text-muted">Last updated: {{ when }}</div>
            </div>
            <div class="d-flex">
              <a v-if="link" :href="link" target="_blank" class="btn btn-sm btn-secondary"
                >Open in Confluence<i class="uil uil-external-link-alt ml-3"></i
              ></a>
              <div class="modal-title-buttons ml-4">
                <button class="close modal-title-button" @click="close"></button>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-body p-4">
          <div class="html-content" v-html="data"></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ConfluenceService from '@services/stats/backoffice/ConfluenceService.js'

export default {
  components: {
    ConfluenceService,
  },
  data() {
    return {
      isActive: false,
      data: '',
      title: 'Information',
      link: '',
      author: '',
      when: '',
    }
  },
  props: {
    id: {
      type: Number,
      required: false,
    },
  },
  mounted() {
    document.getElementById('modal')?.appendChild(this.$el)
  },
  methods: {
    getData() {
      ConfluenceService.get(this.id)
        .then((response) => {
          if (response.data.success) {
            if (response.data.result.body && response.data.result.body.view.value.length > 0) {
              this.data = response.data.result.body.view.value
              //replace links on content
              this.data = this.data.replaceAll(
                'href="/wiki',
                'target="_blank" href="https://gaminginnovationgroup.atlassian.net/wiki'
              )
              this.data = this.data.replaceAll(
                'src="/wiki',
                'src="https://gaminginnovationgroup.atlassian.net/wiki'
              )
              this.title = response.data.result.title
              this.link =
                'https://gaminginnovationgroup.atlassian.net/wiki' + response.data.result._links.webui
              this.author = response.data.result.history.createdBy.displayName
              this.when = response.data.result.version.friendlyWhen
            } else {
              this.title = 'Information'
              this.link = ''
              this.author = ''
              this.data = response.data.result
              this.when = ''
            }
            this.isActive = true
          }
        })
        .catch(this.showUnknownErrorMessage)
    },
    show() {
      this.getData()
    },
    close() {
      this.isActive = false
    },
  },
}
</script>
<style lang="scss">
.html-content {
  img {
    margin-bottom: 1rem;
  }
  h2,
  h3,
  h4,
  h5 {
    margin-bottom: 1.2rem;
  }
}
</style>
<style>
.modal-body img.confluence-embedded-image {
  width: 100%;
  object-fit: cover;
}
</style>
