<template>
  <div>
    <div class="modal" ref="modal" tabindex="-1" role="dialog" v-click-outside="close" v-if="showModal">
      <div class="modal-dialog modal-lg3">
        <div class="modal-content">
          <div class="modal-header d-flex border-bottom py-3 justify-content-between align-items-center">
            <div class="d-flex align-items-center">
              <h2 class="mb-0">Tracking links edit</h2>
              <a
                v-if="showNewLinkModal"
                class="btn btn-info btn-sm ml-4"
                v-bind:class="{ loading: loading }"
                @click="openNewLinkModal"
              >
                <i class="uil uil-plus mr-2"></i> Add new link
              </a>
            </div>
            <div class="d-flex align-items-center">
              <base-checkbox
                name="nofollow"
                :switch="true"
                v-model="showMobileLinks"
                wrapperClass="mr-4"
                label="Show mobile links"
              />
              <button class="btn btn-secondary" v-bind:class="{ loading: loading }" @click="close()">
                <i class="uil uil-times mr-2"></i> Close
              </button>
              <button class="btn btn-primary ml-4" v-bind:class="{ loading: loading }" @click="submit()">
                <i class="uil uil-check mr-2"></i> Save changes
              </button>
            </div>
          </div>
          <div class="modal-body mt-3">
            <table class="table">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Name</th>
                  <th>Link Type</th>
                  <th>Operator / type</th>
                  <th>Market / site</th>
                  <th>Updated</th>
                  <th>Link</th>
                  <th v-if="showMobileLinks">Mobile Link</th>
                  <th v-if="showDelete">Delete</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(row, index) in rows"
                  :key="index"
                  :class="[row.delete && 'is-invalid', linkHasChanged(row) && 'is-valid']"
                >
                  <td width="10" class="text-muted">{{ index + 1 }}</td>
                  <td width="70">{{ row.name }}</td>
                  <td width="90">
                    <span :class="['badge', linkType(row, true)]">{{ linkType(row) }}</span>
                  </td>
                  <td width="140">
                    <div>{{ row.affiliate_operator.operator.name }}</div>
                    <div class="text-muted">{{ row.affiliate_operator.type }}</div>
                  </td>
                  <td width="200">
                    <div>{{ row.affiliate_operator.market.label }}</div>
                    <div class="text-muted">{{ row.affiliate_operator.affiliate.name }}</div>
                  </td>
                  <td width="180">{{ $dateRelative(row.updated_at) }}</td>
                  <td>
                    <base-input v-model="row.link" :showLabel="false" :defaultInputClass="null" />
                  </td>
                  <td v-if="showMobileLinks">
                    <base-input v-model="row.custom_link" :showLabel="false" :defaultInputClass="null" />
                  </td>
                  <td width="20" v-if="showDelete">
                    <button
                      class="btn btn-link icon-button"
                      :class="[row.delete && 'text-red']"
                      @click="deleteLink(row)"
                    >
                      <i class="uil uil-trash"></i>
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <affiliate-link-form ref="linkForm" @refresh="getLinks" v-if="showNewLinkModal && newLinkModalVisible" />
  </div>
</template>

<script>
import BaseInput from '@atoms/fields/base-input.vue'
import BaseCheckbox from '@atoms/fields/base-checkbox.vue'

import AffiliateLinksService from '@services/AffiliateLinksService.js'
import AffiliateLinkForm from '@atoms/dms/operator/sites-data/link-form.vue'

export default {
  components: { BaseInput, AffiliateLinkForm, BaseCheckbox },
  data() {
    return {
      loading: false,
      params: [],
      showModal: false,
      showMobileLinks: false,
      newLinkModalVisible: false,
      rows: [],
    }
  },
  props: {
    showNewLinkModal: {
      default: false,
      type: Boolean,
    },
    showDelete: {
      default: true,
      type: Boolean,
    },
  },
  methods: {
    getLinks() {
      return new Promise((resolve, reject) => {
        AffiliateLinksService.get({
          ...this.params,
          with: [
            'affiliate_operator',
            'affiliate_operator.operator',
            'affiliate_operator.market',
            'affiliate_operator.affiliate',
          ].join(','),
        })
          .then((response) => {
            this.rows = this.mapLinksArray(response.data.result)
            resolve()
          })
          .catch((error) => reject(error))
      })
    },
    show(params) {
      this.params = params
      this.getLinks().then(() => {
        document.getElementById('modal').appendChild(this.$el)
        this.showModal = true
        this.$nextTick(() => {
          this.$refs.modal && this.$refs.modal.classList.add('show')
        })
      })
    },
    async submit() {
      this.loading = true
      await AffiliateLinksService.bulkUpdate({ links: this.rows })
        .then((response) => {
          if (response.data.messages) {
            this.showErrorMessages(response.data.messages)
          } else if (response.data.success) {
            const wording = response.data.result.length === 1 ? 'Link' : 'Links'
            this.showSuccessMessage(`${wording} updated`)
            this.rows = this.mapLinksArray(response.data.result)
            setTimeout(() => {
              this.$emit('refresh', response.data.result)
              this.close()
            }, 200)
          }
        })
        .catch(this.showUnknownErrorMessage)
        .finally(() => {
          this.loading = false
        })
    },
    mapLinksArray(links) {
      return links.map((link) => {
        link.delete = false
        link.link = link.link === null ? '' : link.link
        link.original_value = link.link
        link.original_custom_value = link.custom_link
        return link
      })
    },
    linkHasChanged(link) {
      return link.original_value !== link.link || link.original_custom_value !== link.custom_link
    },
    linkType(row, returnClass = false) {
      if (row.name === 'main') {
        return returnClass ? 'bg-red-75' : 'default'
      } else if (row.granular_tracking_link_id) {
        return returnClass ? 'bg-blue-75' : 'granular'
      }
      return returnClass ? 'bg-black-50' : 'custom'
    },
    deleteLink(row) {
      if (row.name === 'main') {
        return this.showErrorMessage('Main link cannot be deleted.')
      } else if (row.granular_tracking_link_id) {
        return this.showErrorMessage('Granular tracking links cannot be deleted.')
      } else {
        row.delete = !row.delete
      }
    },
    openNewLinkModal() {
      const pendingChanges = this.rows.filter((row) => {
        return row.original_value !== row.link || row.delete
      })
      if (pendingChanges.length > 0) {
        return this.showErrorMessage('Cannot add a new link when you have unsaved changes')
      }
      this.newLinkModalVisible = true
      this.$nextTick(() => {
        this.$refs.linkForm.show(this.params.affiliate_operator_id)
      })
    },
    close() {
      this.loading = false
      this.$refs.modal && this.$refs.modal.classList.remove('show')
      this.showModal = true
      this.$emit('closed')
    },
  },
}
</script>

<style lang="scss" scoped>
.table td {
  padding: 6px 16px;
}

.table tbody tr.is-valid td {
  background: #e7ffea;
}

.table tbody tr.is-invalid td {
  background: #fff1f1;
}

.is-valid:hover td {
  background: darken(#e7ffea, 2) !important;
}

.is-invalid:hover td {
  background: darken(#ffecec, 2) !important;
}
</style>
