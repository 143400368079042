<template>
  <div
    class="modal modal-center"
    ref="modal"
    tabindex="-1"
    role="dialog"
    v-click-outside="close"
    v-if="showModal"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" v-if="object">Edit variable field</h5>
          <button class="close" v-on:click="close"></button>
        </div>
        <div
          class="modal-body pb-2 loading-opacity"
          v-bind:class="{ 'is-loading': loading }"
          v-if="object != null"
        >
          <base-input label="Name" :modelValue="object.extra_field.name" disabled />
          <base-textarea
            label="Value"
            ref="input"
            rows="3"
            v-model="object.value"
            v-on:input="renderExample"
          />
          <div>
            <label class="d-block">Available variables</label>
            <span
              class="btn btn-sm badge badge-light mr-2 mb-3"
              v-for="(key, variable) in variables"
              v-on:click="addVariable(variable)"
              v-bind:key="variable"
              >{{ variable }}</span
            >
          </div>
          <div class="form-group mt-2">
            <label>Value preview</label>
            <div class="oneliner-preview" v-html="example" />
          </div>
        </div>
        <div class="modal-footer pt-0">
          <div class="d-flex align-items-center">
            <div class="text-muted">This will not save the values until the parent popup is saved</div>
            <div class="w-25 text-right">
              <button type="submit" class="btn btn-primary" @click="submit" :disabled="loading">
                <i class="uil uil-check"></i>
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AffiliateExtraFieldValuesService from '@services/AffiliateExtraFieldValuesService.js'
import AffiliateExtraFieldsService from '@services/AffiliateExtraFieldsService.js'
import BaseTextarea from '@atoms/fields/base-textarea.vue'
import BaseInput from '@atoms/fields/base-input.vue'
import BaseCheckbox from '@atoms/fields/base-checkbox.vue'
import BaseDate from '@atoms/fields/base-date.vue'

export default {
  components: { BaseInput, BaseCheckbox, BaseDate, BaseTextarea },
  data() {
    return {
      object: null,
      variables: null,
      loading: false,
      showModal: false,
      example: '',
    }
  },
  props: {
    field: { required: true, type: Object },
  },
  watch: {
    object() {
      this.renderExample()
    },
  },
  mounted() {
    document.getElementById('modal').appendChild(this.$el)
  },
  methods: {
    addVariable(variable) {
      if (this.object.value && this.object.value.length > 0) {
        const position = this.$refs.input.getInput().selectionStart
        this.object.value = [
          this.object.value.slice(0, position),
          `[${variable}]`,
          this.object.value.slice(position),
        ].join('')
      } else {
        this.object.value = `[${variable}]`
      }
      this.$refs.input.getInput().focus()
      this.renderExample()
    },
    renderExample() {
      if (this.object && this.object.extra_field.type === 'variable_text' && this.object.value) {
        let example = this.object.value
        Object.keys(this.variables).forEach((key) => {
          example = example.split(`[${key}]`).join(`<code>${this.variables[key]}</code>`)
        })
        this.example = example
      } else {
        this.example = ''
      }
    },
    submit() {
      this.$emit('storeVariableField', this.object, this.field)
      this.close()
    },
    close() {
      if (this.$refs && typeof this.$refs.modal !== 'undefined') {
        this.$refs.modal && this.$refs.modal.classList.remove('show')
        this.object = null
      }
    },
    show(affiliateOperatorId, rowId) {
      AffiliateExtraFieldValuesService.getOne(affiliateOperatorId, rowId, {
        with: 'variables,extra_field',
      })
        .then((response) => {
          this.object = response.data.result[0]
          this.variables = response.data.result[0].variables
          this.showModal = true
          this.$nextTick(() => {
            setTimeout(() => {
              this.$refs.modal.classList.add('show')
            }, 100)
          })
        })
        .catch(this.showUnknownErrorMessage)
    },
    showVariableFieldValue(variableField) {
      this.object = variableField
      this.variables = variableField.variables
      this.$nextTick(() => {
        this.$refs.modal.classList.add('show')
      })
    },
    showVariableField(id, operatorId, marketId, type) {
      AffiliateExtraFieldsService.getOne(id, {
        operator_id: operatorId,
        market_id: marketId,
        type: type,
        with: 'variables',
      }).then((response) => {
        this.object = response.data.result[0]
        this.object.extra_field = response.data.result[0]
        this.variables = response.data.result[0].variables
        this.$nextTick(() => {
          this.$refs.modal.classList.add('show')
        })
      })
    },
  },
}
</script>
