<template>
  <data-table
    model="Comments"
    service-file="CommentService"
    path="hercules/content/comments"
    :columns="columns()"
    :column-templates="columnTemplates()"
    :show-create="false"
  />
</template>
<script>
import TableColumnTemplate from '@constants/table-column-template.js'

export default {
  created() {
    this.setTitle()
  },
  data() {
    return {
      title: `User Comments`,
    }
  },
  methods: {
    columns() {
      return [
        { field: 'site_name', type: 'text', filterable: true },
        { field: 'reference_name', type: 'text', filterable: true },
        { field: 'market_short_code', type: 'text', filterable: true },
        { field: 'name', type: 'text', filterable: true },
        { field: 'email', type: 'text', filterable: true },
        { field: 'rate', type: 'text', label: 'Rating', filterable: true },
        { field: 'approved', type: 'checkbox', filterable: false, template: TableColumnTemplate.BOOLEAN },
        { field: 'created_at', type: 'text', filterable: true, template: TableColumnTemplate.RELATIVE_DATE },
      ]
    },
    columnTemplates() {
      return {
        reference_name: function (row) {
          if (row.reference_name) {
            return row.reference_name
          }
          return row.reference_id
        },
      }
    },
  },
}
</script>
