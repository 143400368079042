<template>
  <div>
    <div class="card-body bg-white" v-if="prefilledModule">
      <div v-if="activeIndex == null" class="text-center">
        No markets are active for this prefilled module
      </div>
      <ul class="nav nav-tabs mb-3" v-if="activeIndex !== null">
        <li
          v-for="module in activeMarkets"
          v-bind:class="{ active: activeIndex === module.id }"
          v-bind:key="module.id"
        >
          <a @click="tabClick(module)">{{ module.site_market.market.label }}</a>
        </li>
      </ul>
      <section-edit-modules
        v-for="prefilledModuleMarket in activePrefilledModule"
        v-bind:key="prefilledModuleMarket.id"
        :allowPrefilledModules="false"
        :allowModuleDelete="false"
        :allowModules="false"
        :siteId="prefilledModule.site_id"
        :affiliate-id="prefilledModule.site.affiliate_id"
        :showCardHeader="false"
        :openModuleValues="true"
        :marketId="prefilledModuleMarket.site_market.market_id"
        :section="prefilledModuleMarket"
        moduleOwnerType="prefilled_module_market"
      />
    </div>
    <div class="card-footer text-right">
      <button
        class="btn btn-primary mr-2"
        @click="update(false)"
        :disabled="loading"
        v-bind:class="{ loading: loading }"
      >
        <i class="uil uil-check"></i>
        Save
      </button>
    </div>
  </div>
</template>
<script>
import PrefilledModulesService from '@services/PrefilledModulesService.js'
import SectionEditModules from '@templates/cms/modules/modules.vue'
import ColorsService from '@services/ColorsService.js'
import StyleService from '@services/ModuleStylesService.js'

export default {
  components: { SectionEditModules },
  props: { prefilledModule: {} },
  data() {
    return {
      activeIndex: null,
      loading: false,
    }
  },
  mounted() {
    this.activeIndex = this.prefilledModule.prefilled_module_markets.filter((item) => item.active)[0]?.id
    ColorsService.get({ site_id: this.prefilledModule.site_id, orderBy: 'label', ascending: 1 })
      .then((response) => {
        if (response.data.success) {
          this.$store.dispatch('cms/setColors', response.data.result)
        }
      })
      .catch(this.showUnknownErrorMessage)

    StyleService.get({ site_id: this.prefilledModule.site_id, orderBy: 'name', ascending: 1 })
      .then((response) => {
        if (response.data.success) {
          if (response.data.result.length > 0) {
            this.$store.dispatch('cms/setStyles', response.data.result)
          }
        }
      })
      .catch(this.showUnknownErrorMessage)
  },
  computed: {
    activePrefilledModule() {
      return this.prefilledModule.prefilled_module_markets.filter(
        (item) => item.active && item.id === this.activeIndex
      )
    },
    activeMarkets() {
      return this.prefilledModule.prefilled_module_markets.filter((item) => item.active)
    },
  },
  methods: {
    tabClick(prefilledModuleMarket) {
      this.activeIndex = prefilledModuleMarket.id
    },
    async update() {
      this.loading = true
      this.prefilledModule.prefilled_module_markets = this.prefilledModule.prefilled_module_markets.filter(
        (item) => item.active
      )
      await PrefilledModulesService.update(this.prefilledModule)
        .then((response) => {
          if (response.data.messages) {
            this.showErrorMessages(response.data.messages)
          } else {
            this.prefilledModule.prefilled_module_markets = response.data.result.prefilled_module_markets
            this.showSuccessMessage('Prefilled module saved')
          }
        })
        .catch(this.showUnknownErrorMessage)
        .finally(() => {
          this.loading = false
        })
    },
  },
}
</script>
