<template>
  <div class="sidebar-body-content mw-100 d-flex bg-white card">
    <div class="card-header p-2">
      <div class="w-50">
        <base-select
          label="Sport"
          v-model="sportId"
          :clearable="false"
          @update:modelValue="changeSportId"
          path="event-streams/v1/sports"
          ref="sportSelect"
          option-label-key="name"
          class="pr-2 pl-2 mb-0"
        />
      </div>
      <div class="w-50">
        <base-date
          label="Date"
          v-model="date"
          :clearable="false"
          @update:modelValue="changeScheduledAt"
          ref="dateSelect"
          :config="datePickerConfig()"
          option-label-key="name"
        />
      </div>
      <div class="w-50">
        <base-select
          label="Provider"
          :clearable="false"
          v-model="stream_provider"
          ref="providerSelect"
          :loadOnMount="false"
          :path="`event-streams/v1/stream-providers`"
          option-label-key="name"
          class="pr-2 pl-2 mb-0"
        />
      </div>
      <div class="w-50">
        <div class="mb-2 ml-2 d-flex justify-content-between">
          <span class="font-weight-semi-bold text-black-75">Competition</span>
          <span class="text-muted clickable mr-4" @click="clearCompetition" v-if="this.competitionId"
            >clear</span
          >
        </div>
        <base-select
          :clearable="false"
          v-model="competitionId"
          ref="competitionSelect"
          :loadOnMount="false"
          :path="`event-streams/v1/competitions?sport=${sportId}&date=${date}`"
          option-label-key="name"
          class="pr-2 pl-2 mb-0"
        />
      </div>
    </div>
  </div>
</template>

<script>
import BaseSelect from '@/components/fields/base-select.vue'
import BaseDate from '@atoms/fields/base-date.vue'
import moment from 'moment'

export default {
  components: { BaseSelect, BaseDate },
  mounted() {
    if (this.cacheConfig) {
      this.competitionId = this.cacheSettings.competitionId

      this.$nextTick(() => {
        this.$refs.competitionSelect.getOptions()
        this.$refs.sportSelect.getOptions()
      })
    }
    this.$emit('filterChanged', this.getFilter())
  },
  data() {
    return {
      cacheSettings: this.$cookies.get('event_mapper_filter'),
      sportId: 1,
      competitionId: null,
      collapsed: false,
      date: moment().format('YYYY-MM-DD'),
      stream_provider: 1,
    }
  },
  computed: {
    showSportClear() {
      return this.competitionId
    },
    cacheConfig() {
      return this.cacheSettings
    },
    filters() {
      return {
        competition: this.competitionId,
        sport: this.sportId,
        stream_provider: this.stream_provider,
        date: this.date,
      }
    },
  },
  watch: {
    filters() {
      this.$emit('filterChanged', this.getFilter())
    },
  },
  methods: {
    clearCompetition() {
      this.competitionId = null
    },
    changeScheduledAt() {
      this.$refs.competitionSelect.getOptions()
    },
    datePickerConfig() {
      return {
        date: moment().format('YYYY-MM-DD'),
        altInput: true,
        altFormat: 'd/m/Y',
        dateFormat: 'Y-m-d',
        time_24hr: false,
        enableTime: false,
        minDate: moment().format(),
      }
    },
    changeSportId() {
      this.competitionId = null
      this.$nextTick(() => {
        this.$refs.competitionSelect.getOptions()
      })
    },
    collapseClick() {
      this.collapsed = !this.collapsed
    },
    getFilter() {
      let filter = {
        competition: this.competitionId,
        sport: this.sportId,
        stream_provider: this.stream_provider,
        date: this.date,
      }
      this.$cookies.set('event_mapper_filter', Object.assign({}, this.cacheSettings, filter))
      return filter
    },
  },
}
</script>

<style scoped>
.type > li.active {
  margin-top: 0 !important;
}
</style>
