<template>
  <div class="additional mb-2" v-if="operatorData?.affiliate?.id || operatorData?.market?.id">
    <span>Last updated: {{ $dateRelative(operator.updated_at) }}</span>
    <router-link
      tag="span"
      class="badge bg-azure ml-2 clickable"
      :to="{ path: `/affiliates/${operatorData?.affiliate?.id}` }"
    >
      {{ operatorData?.affiliate?.name }}
    </router-link>
    <router-link
      tag="span"
      class="badge bg-orange ml-2 clickable"
      :to="{ path: '/markets', query: { id: operatorData?.market?.id } }"
    >
      {{ operatorData?.market?.label }}
    </router-link>
    <span class="badge bg-purple ml-2">{{ operatorData?.type }}</span>
  </div>
  <div class="additional mb-2" v-else>
    <span>Last updated: {{ $dateRelative(operator.updated_at) }}</span>
    <router-link
      tag="span"
      class="badge bg-azure ml-2 clickable"
      :to="{ path: `/affiliates/${bonusesAndLinks?.affiliate?.id}` }"
    >
      {{ bonusesAndLinks?.affiliate?.name }}
    </router-link>
    <router-link
      tag="span"
      class="badge bg-orange ml-2 clickable"
      :to="{ path: '/markets', query: { id: bonusesAndLinks?.market?.id } }"
    >
      {{ bonusesAndLinks?.market?.label }}
    </router-link>
    <span class="badge bg-purple ml-2">{{ bonusesAndLinks?.type }}</span>
  </div>
</template>
<script setup>
import { ref, defineProps, watch, onMounted, computed } from 'vue'
import { useRoute } from 'vue-router'
import { useStore } from 'vuex'
import AffiliateOperatorsService from '@services/AffiliateOperatorsService.js'

const props = defineProps({
  operator: { type: Object, required: true },
})
const store = useStore()
const route = useRoute()
const operatorData = computed(() => ({ ...store.state.dynamicForm.formObject }))
const bonusesAndLinksId = ref()
const bonusesAndLinks = ref()

const getSitesData = (id) => {
  AffiliateOperatorsService.getOne(id, {
    with: 'affiliate,market,',
  }).then((response) => {
    bonusesAndLinks.value = response.data.result[0]
  })
}

watch(
  () => route.params,
  (newParams) => {
    if (newParams.bonusId) {
      bonusesAndLinksId.value = newParams.bonusId
    } else if (newParams.linkId) {
      bonusesAndLinksId.value = newParams.linkId
    }
  },
  { immediate: true }
)

onMounted(() => {
  if (bonusesAndLinksId.value) {
    getSitesData(bonusesAndLinksId.value)
  }
})
</script>
