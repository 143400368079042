<template>
  <div class="row" v-if="page.template.feedback_counter">
    <div class="col">
      <div class="card">
        <div class="card-header d-flex flex-wrap justify-content-between">
          <h3 class="flex-grow-1">User feedback</h3>
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col">
              <base-input
                type="number"
                label="Website Likes"
                v-model="this.websiteTrueVotes"
                :hideNumberSpinner="true"
                readOnly="true"
              />
            </div>
            <div class="col">
              <base-input
                type="number"
                label="Website Dislikes"
                v-model="this.websiteFalseVotes"
                :hideNumberSpinner="true"
                readOnly="true"
              />
            </div>
          </div>
          <div class="row">
            <div class="col">
              <base-input
                type="number"
                :min="adminTrueVotesMin"
                step="1"
                label="Admin Likes"
                v-model="adminTrueVotes"
              />
            </div>
            <div class="col">
              <base-input
                type="number"
                :min="adminFalseVotesMin"
                step="1"
                label="Admin Dislikes"
                v-model="adminFalseVotes"
              />
            </div>
          </div>
          <div class="row">
            <div class="col">
              <hr />
            </div>
          </div>
          <div class="row">
            <div class="col">
              <base-input type="number" min="0" step="1" label="Total Likes" v-model="totalTrueVotes" />
            </div>
            <div class="col">
              <base-input type="number" min="0" step="1" label="Total Dislikes" v-model="totalFalseVotes" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import BaseInput from '@atoms/fields/base-input.vue'

export default {
  data() {
    return {
      websiteTrueVotes: 0,
      websiteFalseVotes: 0,
    }
  },
  components: {
    BaseInput,
  },
  props: {
    page: {
      type: Object,
      required: true,
    },
  },
  created() {
    this.websiteTrueVotes = parseInt(this.page.total_true_votes) - parseInt(this.page.admin_true_votes)
    this.websiteFalseVotes = parseInt(this.page.total_false_votes) - parseInt(this.page.admin_false_votes)
  },
  computed: {
    totalTrueVotes: {
      get() {
        return parseInt(this.websiteTrueVotes) + parseInt(this.page.admin_true_votes)
      },
      set(newVal) {
        this.page.total_true_votes = newVal
        this.page.admin_true_votes = parseInt(newVal) - parseInt(this.websiteTrueVotes)
      },
    },
    totalFalseVotes: {
      get() {
        return parseInt(this.websiteFalseVotes) + parseInt(this.page.admin_false_votes)
      },
      set(newVal) {
        this.page.total_false_votes = newVal
        this.page.admin_false_votes = parseInt(newVal) - parseInt(this.websiteFalseVotes)
      },
    },
    adminTrueVotes: {
      get() {
        return this.page.admin_true_votes
      },
      set(newVal) {
        this.page.admin_true_votes = newVal
        this.page.total_true_votes = parseInt(this.websiteTrueVotes) + parseInt(newVal)
      },
    },
    adminFalseVotes: {
      get() {
        return this.page.admin_false_votes
      },
      set(newVal) {
        this.page.admin_false_votes = newVal
        this.page.total_false_votes = parseInt(this.websiteFalseVotes) + parseInt(newVal)
      },
    },
    adminTrueVotesMin() {
      return parseInt(this.websiteTrueVotes) * -1
    },
    adminFalseVotesMin() {
      return parseInt(this.websiteFalseVotes) * -1
    },
  },
}
</script>
