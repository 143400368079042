<template>
  <div>
    <data-table
      :enableRowClick="false"
      :path="path()"
      :columns="columns()"
      :columnTemplates="columnTemplates()"
      :rowActionButtons="rowActions"
      :enable-bulk-select="true"
      @bulkClickByIds="bulkClick"
      :customActionButton="{
        action: addToToplists,
        title: 'Add to multiple toplists',
      }"
      ref="list"
    />
    <toplist-edit-bulk
      v-if="showBulkEdit"
      ref="bulkEdit"
      :removeOperators="true"
      @refresh="$refs.list && $refs.list.search()"
      @closed="() => (showBulkEdit = false)"
    />
    <add-to-toplist-bulk
      v-if="showBulkAdd"
      ref="addToToplists"
      @refresh="$refs.list && $refs.list.search()"
      @closed="() => (showBulkAdd = false)"
    />
  </div>
</template>

<script lang="jsx">
import TableColumnTemplate from '@constants/table-column-template.js'
import ToplistEdit from '@atoms/table/cell/toplist-edit.vue'
import ConfirmDialog from '@atoms/misc/confirm-dialog.vue'
import ToplistEditBulk from '@molecules/dms/toplists/edit-bulk.vue'
import AddToToplistBulk from '@molecules/dms/toplists/add-to-toplist-bulk.vue'
import { openDialog } from 'vue3-promise-dialog'
import TopListsService from '@/services/TopListsService.js'

const confirmDelete = async (title, content) => {
  return await openDialog(ConfirmDialog, { title, content })
}
export default {
  components: { ToplistEdit, ToplistEditBulk, AddToToplistBulk },
  data() {
    return {
      operator_id: this.$route.params['id'],
      showBulkEdit: false,
      showBulkAdd: false,
      rowActions: [
        {
          action: this.deleteOperator,
          title: 'Delete',
          icon: 'uil uil-trash',
          class: 'btn-danger',
        },
      ],
    }
  },
  mounted() {
    this.setTitle(
      `${this.$parent.$parent.title ? this.$parent.$parent.title + ':' : ''} ${this.$route.meta.name}`
    )
  },
  methods: {
    columns() {
      return [
        { field: 'sites_name', type: 'text', filterable: true, label: 'Site' },
        { field: 'markets_label', type: 'text', filterable: true, label: 'Market' },
        {
          field: 'lists_label',
          type: 'text',
          filterable: true,
          label: 'Label',
        },
        {
          field: 'lists_short_code',
          type: 'text',
          filterable: true,
          label: 'Short code',
          template: TableColumnTemplate.TEXT_TRUNCATE,
        },
        { field: 'lists_operator_type', type: 'text', filterable: true, label: 'Type' },

        { field: 'sort_nr', type: 'text', filterable: true, label: 'Pos' },
        {
          field: 'lists_updated_at',
          type: 'text',
          label: 'Last updated',
          filterable: false,
          template: TableColumnTemplate.RELATIVE_DATE,
        },
      ]
    },
    path() {
      return `hercules/toplists/list-items?operator_id=${this.$route.params['id']}&orderBy=updated_at&ascending=0`
    },
    columnTemplates() {
      return Object.assign(
        {
          lists_label: function (row) {
            return <ToplistEdit label={row.lists_label} id={row.list_id} />
          },
        },
        {
          site: function (row) {
            return row.site?.name
          },
        },
        {
          sort_nr: function (row) {
            let className = 'bg-blue-10'
            let listCount = row.list_count
            if (row.sort_nr === 0) className = 'bg-gold'
            if (row.sort_nr === 1) className = 'bg-silver'
            if (row.sort_nr === 2) className = 'bg-bronze'
            if (row.scheduled === 1) listCount = row.list_count_scheduled
            return (
              <div style={{ width: '54px' }} class={['badge text-black counter-badge', className]}>
                {row.sort_nr}/{listCount}
              </div>
            )
          },
        }
      )
    },
    async deleteOperator(row) {
      if (
        await confirmDelete(
          'Warning',
          `Are you sure want to delete ${this.$parent.$parent.title} from this toplist?`
        )
      ) {
        TopListsService.deleteOperator({ ids: [row.id] })
          .then((response) => {
            if (response.data.messages) {
              this.showErrorMessages(response.data.messages)
            } else {
              this.showSuccessMessage(`${this.$parent.$parent.title} removed from toplist.`)
            }
          })
          .catch(this.showUnknownErrorMessage)
          .finally(() => {
            this.$refs.list.search()
          })
      }
    },
    bulkClick(ids) {
      if (ids.length > 0) {
        this.showBulkEdit = true
        this.$nextTick(() => {
          this.$refs.bulkEdit.show(ids)
        })
      }
    },
    addToToplists() {
      this.showBulkAdd = true
      this.$nextTick(() => {
        this.$refs.addToToplists.show(this.$parent.$parent.operator)
      })
    },
  },
}
</script>
