<template>
  <div class="d-block border p-3 border-bottom-0" :data-anchor="pageModule.id">
    <div
      class="d-flex mb-3 justify-content-between"
      :id="`${pageModule.anchor_label && anchorify(pageModule.anchor_label)}`"
    >
      <div>
        <h2 v-if="isTemplateBlock || isPrefilledModule">{{ preview }}</h2>
        <title-tag :level="pageModule.title_tag || 'h2'" class="border-bottom pb-2" v-if="!isTemplateBlock">
          <span class="text-muted">{{ $capitalize(capitalize) }}</span> {{ previewTitle }}
        </title-tag>
      </div>
      <div>
        <span
          v-if="isTemplateBlock || isPrefilledModule"
          :class="`badge badge-secondary ${editableClass()}`"
          >{{ editableLabel() }}</span
        >
        <button v-if="canEdit" class="btn btn-secondary btn-sm pl-3" @click="$emit('editModule', index)">
          Edit module <i class="uil uil-edit ml-2"></i>
        </button>
        <span class="badge badge-secondary ml-3">{{ moduleName }}</span>
      </div>
    </div>
    <div v-if="isTemplateBlock || isPrefilledModule">
      <p class="text-muted d-flex">
        <i class="uil uil-frown mr-2"></i>Sorry, it's not yet possible to preview template blocks and
        prefilled modules.
      </p>
    </div>
    <div v-else-if="renderFields.includes(pageModule.module.name)">
      <div v-html="pageModule.module_introduction"></div>
      <page-edit-module-field-preview
        v-bind="getModuleSpecificAttributes()"
        v-for="(moduleField, index) in pageModule.module_value_fields"
        v-bind:key="index"
        :index="index"
        :siteId="siteId"
        :affiliateId="affiliateId"
        :marketId="marketId"
        :moduleField="moduleField"
      />
    </div>
    <div v-else-if="renderModule.includes(pageModule.module.name)">
      <div v-html="pageModule.module_introduction"></div>
      <component
        v-bind="getModuleSpecificAttributes()"
        v-bind:is="getModuleComponent()"
        :siteId="siteId"
        :affiliateId="affiliateId"
        :marketId="marketId"
        :pageModule="pageModule"
      />
    </div>
    <div v-else>
      <p class="text-muted d-flex">
        <i class="uil uil-frown mr-2"></i>Sorry, it's not yet possible to preview this module.
      </p>
    </div>
  </div>
</template>
<script>
import PageEditModuleFieldPreview from '@templates/forms/module-dynamic-fields-preview.vue'
import ContentPreview from '@molecules/cms/modules/preview/content.vue'
import CardsPreview from '@atoms/cms/modules/preview/cards.vue'
import ImagePreview from '@molecules/cms/modules/preview/image.vue'
import AnchorPreview from '@atoms/cms/modules/preview/anchor.vue'
import ProsConsPreview from '@atoms/cms/modules/preview/pros-cons.vue'
import iFramePreview from '@atoms/cms/modules/preview/iframe.vue'
import TopListPreview from '@molecules/cms/modules/preview/top-list.vue'
import TitleTag from '@atoms/cms/modules/preview/title-tag.vue'
import ModulePreviewBase from '@organisms/cms/modules/module-preview-base.vue'

export default {
  extends: ModulePreviewBase,
  components: {
    PageEditModuleFieldPreview,
    AnchorPreview,
    CardsPreview,
    TopListPreview,
    ImagePreview,
    iFramePreview,
    TitleTag,
  },
  data() {
    return {
      renderFields: ['faq', 'accordion'],
      renderModule: ['content', 'anchor', 'pros_and_cons', 'cards_v2', 'top_list', 'image', 'iframe'],
    }
  },
  props: {
    allowModuleDelete: {},
    openModuleValues: {},
    moduleOwnerType: {},
    pageModule: {
      type: Object,
      required: true,
    },
    siteId: {
      required: true,
    },
    affiliateId: {
      required: true,
    },
    marketId: {},
    index: {},
  },
  computed: {
    isTemplateBlock() {
      return this.pageModule.value_type === 'template_block'
    },
    isPrefilledModule() {
      return this.pageModule.value_type === 'prefilled_module_market'
    },
    hasModuleTitle() {
      return this.pageModule.title !== null && this.pageModule.title !== ''
    },
    titleFromContent() {
      const contentField = this.pageModule.module_value_fields.find(
        (item) => item.field.name === 'content_value'
      )
      if (contentField) {
        return this.extractTitleAndTagFromContent(contentField.value)
      }
      return { text: 'Title is empty', tag: null }
    },
    previewTag() {
      return this.pageModule.title_tag ? this.pageModule.title_tag : this.titleFromContent.tag
    },
    previewTitle() {
      return this.pageModule.title ? this.pageModule.title : this.titleFromContent.text
    },
  },
  methods: {
    getModuleComponent() {
      switch (this.pageModule.module.name) {
        case 'anchor':
          return AnchorPreview
        case 'content':
          return ContentPreview
        case 'cards_v2':
          return CardsPreview
        case 'pros_and_cons':
          return ProsConsPreview
        case 'top_list':
          return TopListPreview
        case 'image':
          return ImagePreview
        case 'iframe':
          return iFramePreview
      }
      return null
    },
    getModuleSpecificAttributes() {
      let object = { fieldSpecificAttributes: {}, anchors: [] }
      switch (this.pageModule.module.name) {
        case 'spotlights':
          object.fieldSpecificAttributes.mode = this.findFieldByName('spotlights_mode').value
      }
      return object
    },
    findFieldByName(name) {
      return this.pageModule.module_value_fields.find((item) => item.field.name === name)
    },
    extractTitleAndTagFromContent(content) {
      const parser = new DOMParser()
      const doc = parser.parseFromString(content.trim(), 'text/html')
      const headings = doc.querySelectorAll('h1, h2, h3, h4, h5, h6')
      if (headings.length > 0) {
        const firstHeading = Array.from(headings)[0]
        return {
          text: firstHeading.textContent.substring(0, 70).trim(),
          tag: firstHeading.tagName.toLowerCase(), // get the tag name in lowercase
        }
      }
      const firstParagraph = doc.querySelector('p')
      if (firstParagraph) {
        return {
          text: firstParagraph.textContent.substring(0, 70).trim(),
          tag: 'p',
        }
      }
      return { text: 'Title is empty', tag: null }
    },
  },
}
</script>

<style scoped>
.card:hover {
  border-color: #969696;
}

.card-body {
  overflow-x: inherit;
}

.module-drag {
  cursor: grab;
}
</style>
