<template>
  <div>
    <pages-sidebar @filterChanged="filterChanged" ref="sidebar" />
    <data-table
      model="event-stream/StreamProviders"
      service-file="event-stream/StreamProviders"
      :path="path()"
      :columns="columns()"
      :columnTemplates="columnTemplates()"
      :customRowClick="() => false"
      :enableAdvancedSearch="false"
      ref="table"
      :showEditModal="false"
      :enableQuickSearch="false"
      :tableLimits="[10, 50, 100]"
      :extraComponentHeight="sidebarHeight"
    />
  </div>
</template>

<script lang="jsx">
import StreamProviders from '@services/event-stream/StreamProviders.js'
import PagesSidebar from '@molecules/cms/pages/event-stream-filter.vue'
import EnabledActionColumn from '@molecules/table/cell/enabled-action.vue'

export default {
  created() {
    this.setTitle()
  },
  mounted() {
    this.sidebarHeight = this.$refs.sidebar.$el.clientHeight
  },
  data() {
    return {
      sidebarHeight: 0,
      sport: null,
      stream_provider: null,
      date: null,
    }
  },
  components: { PagesSidebar },
  methods: {
    columns() {
      return [
        { field: 'time', type: 'text', sortable: false },
        { field: 'competition.name', label: 'Competition', type: 'text', sortable: false },
        { field: 'name', label: 'Event', type: 'text', sortable: false },
        { field: 'streaming', label: 'Enabled', type: 'switch', sortable: false },
      ]
    },
    filterChanged(filter) {
      this.sport = filter.sport
      this.date = filter.date
      this.provider = filter.stream_provider
      this.$store.dispatch('data-table/setSiteFilters', filter)
    },
    columnTemplates() {
      const self = this
      return {
        streaming: function (row) {
          return <EnabledActionColumn row={row} field={'streaming'} onColumnClick={self.actionColumnClick} />
        },
      }
    },
    actionColumnClick(id, field, value) {
      let object = { event_id: id, enabled: value, stream_provider_id: this.provider }

      StreamProviders.update(object)
        .then((response) => {
          this.$refs.table.isLoading = true
          if (response.data.success) {
            this.showSuccessMessage(`Column updated`)
            this.$refs.table.search()
          } else {
            this.showErrorMessage(response.data.messages[0])
            this.$refs.table.isLoading = false
          }
        })
        .catch(() => {
          this.showErrorMessage('Failed to update streams')
          this.$refs.list.isLoading = false
        })
    },

    path() {
      return `event-streams/v1/schedules`
    },
  },
}
</script>
