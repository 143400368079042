<script lang="jsx">
import TableColumnTemplate from '@constants/table-column-template.js'
import Clipboard from '@atoms/table/cell/clipboard.vue'
import Status from '@atoms/table/cell/status.vue'
import OperatorStatus from '@atoms/table/cell/operator-status.vue'
import Badges from '@atoms/table/cell/badges.vue'
import Image from '@atoms/table/cell/image.vue'
import ToplistEdit from '@atoms/table/cell/toplist-edit.vue'
import Boolean from '@atoms/table/cell/boolean.vue'
import OddsStatus from '@atoms/table/cell/odds-status.vue'
import SportsStatus from '@atoms/table/cell/sports-status.vue'
import Color from '@atoms/table/cell/color.vue'
import TextTruncate from '@atoms/table/cell/text-truncate.vue'
import EventStatusColumn from '@atoms/table/cell/event-status.vue'
import ListArray from '@atoms/table/cell/list-array.vue'
import MarketLabel from '@atoms/table/cell/market-label.vue'
import AccountStatus from '@atoms/table/cell/account-status.vue'
import moment from 'moment'
import tableColumnTemplate from '@constants/table-column-template.js'
import LinkCell from '@atoms/table/cell/link-cell.vue'
import LinksForRedirects from '@atoms/table/cell/links-for-redirects.vue'
import LogsStatus from '@atoms/table/cell/logs-status.vue'

export default {
  props: {
    column: {},
    row: {},
    template: {},
  },
  render(h) {
    return <td class={this.column.class}>{this.getValue(h)}</td>
  },
  computed: {
    value() {
      if (this.column.field.includes('.')) {
        return this.findInObject(this.column.field.split('.'), this.row)
      }
      return this.row[this.column.field]
    },
  },
  methods: {
    // eslint-disable-next-line no-unused-vars
    getValue: function () {
      switch (this.column.template) {
        case TableColumnTemplate.IMAGE:
          return <Image value={this.value} />
        case TableColumnTemplate.BADGES:
          return <Badges labels={this.value} limit={2} type={this.row.type} />
        case TableColumnTemplate.PRETTY_LABELS:
          return this.$prettyLabels(this.value)
        case TableColumnTemplate.RELATIVE_DATE:
          return Date.parse(this.value) ? moment(this.value).fromNow(false) : this.value
        case TableColumnTemplate.FORMATTED_DATE_TIME:
          return Date.parse(this.value) ? moment(this.value).format('YYYY-MM-DD HH:mm:ss') : this.value
        case TableColumnTemplate.STATUS:
          return <Status status={this.value} />
        case TableColumnTemplate.OPERATOR_STATUS:
          return <OperatorStatus status={this.value} />
        case TableColumnTemplate.LOGS_STATUS:
          return <LogsStatus action={this.row.event} />
        case TableColumnTemplate.ACCOUNT_STATUS:
          return <AccountStatus status={this.value} />
        case TableColumnTemplate.CLIPBOARD:
          return <Clipboard value={this.value} customClass={this.column.templateClipboardClass} />
        case TableColumnTemplate.LINK_CELL:
          return <LinkCell value={this.value} customClass={this.column.templateClipboardClass} />
        case TableColumnTemplate.LINK_FOR_REDIRECTS:
          return <LinksForRedirects value={this.value} customClass={this.column.templateClipboardClass} />
        case TableColumnTemplate.BOOLEAN:
          return <Boolean value={this.value} />
        case TableColumnTemplate.TOPLIST:
          return <ToplistEdit value={this.value} />
        case TableColumnTemplate.ODDS_STATUS:
          return <OddsStatus status={this.value} />
        case TableColumnTemplate.SPORTS_STATUS:
          return <SportsStatus status={this.value} />
        case TableColumnTemplate.EVENT_STATUS:
          return <EventStatusColumn status={this.value} />
        case TableColumnTemplate.COLOR:
          return <Color value={this.value} />
        case TableColumnTemplate.TEXT_TRUNCATE:
          return <TextTruncate value={this.value} />
        case TableColumnTemplate.TEXT_TRUNCATE_TOOLTIP:
          return <TextTruncate value={this.value} limit={20} tooltip={true} />
        case TableColumnTemplate.LIST_FROM_ARRAY:
          return (
            <ListArray
              items={this.value}
              columnKey={this.column.key}
              limit={this.column.limit}
              bgColor={this.column.bgColor}
            />
          )
        case tableColumnTemplate.MARKET_LABEL:
          return <MarketLabel value={this.row} />
      }
      if (this.template) {
        return this.template(this.row)
      }
      if (this.column.field.includes('.')) {
        return this.findInObject(this.column.field.split('.'), this.row)
      }

      return this.row[this.column.field]
    },
  },
}
</script>
