<template>
  <data-table
    model="cms/Module"
    service-file="ModulesService"
    path="hercules/sites/modules"
    :show-create="false"
    :columns="columns()"
    :showRemove="false"
    :show-edit-modal="false"
  />
</template>

<script>
import TableColumnTemplate from '@constants/table-column-template.js'

export default {
  created() {
    this.setTitle()
  },

  methods: {
    columns() {
      return [
        { field: 'name', type: 'text', filterable: true },
        { field: 'created_at', type: 'text', filterable: true, template: TableColumnTemplate.RELATIVE_DATE },
      ]
    },
  },
}
</script>
