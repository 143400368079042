export default {
  getForm(operatorId) {
    return [
      {
        name: 'casino_name',
        required: false,
        display_name: 'Casino Name',
        type: 'display_only',
      },
      {
        name: 'country_name',
        required: false,
        display_name: 'Country',
        type: 'display_only',
      },
      {
        name: 'operator_id',
        required: true,
        display_name: 'Casino',
        display_field: 'name',
        type: 'hidden',
      },
      {
        name: 'type',
        search_url: `hercules/operators/game-types?operator_id=${operatorId}`,
        required: true,
        display_name: 'Type',
        display_field: 'name',
        type: 'list-one',
        selectLabel: 'name',
        value_key: 'type',
      },
      {
        name: 'market_id',
        search_url: 'hercules/globals/markets',
        required: true,
        display_name: 'Market',
        type: 'list-one',
        selectLabel: 'label',
        country_flag: 'country_code',
        value_key: 'market.label',
      },
      {
        name: 'default_tracker',
        display_name: 'Default Tracker Link',
        type: 'hidden',
        required: false,
      },
      {
        name: 'currencies',
        search_url: 'hercules/globals/currencies?orderBy=name&ascending=1&with_no_assets=1',
        required: true,
        display_name: 'Currencies',
        display_field: 'iso_code',
        type: 'list-many',
        fetch_on_load: false,
        validate_changes: true,
        help_text: 'Inactive currencies are shown in grey.',
      },
      {
        name: 'best_game_id',
        search_url: 'hercules/games/games?orderBy=name&fields_only[]=id&fields_only[]=name&ascending=1',
        required: false,
        display_name: 'Best Game',
        type: 'list-one',
        selectLabel: 'name',
        value_key: 'name',
      },
      {
        name: 'games_amount',
        required: false,
        type: 'text',
      },
      {
        name: 'payment_limits',
        required: false,
        display_name: 'Payment limits',
        type: 'average_number',
        inputs: ['min_deposit', 'min_withdrawal', 'max_withdrawal'],
        show_average: false,
        field_type: 'text',
        sanitize_label: false,
        allow_text: true,
      },
      {
        name: 'description',
        required: false,
        display_name: 'Description',
        type: 'hidden',
      },
      {
        name: 'tax_free',
        required: false,
        display_name: 'Tax Free',
        type: 'hidden',
      },
      {
        name: 'rating',
        required: false,
        display_name: 'Ratings',
        type: 'average_number',
        average_input: 'rating',
        inputs: ['rating_casino', 'rating_games', 'rating_bonuses', 'rating_customer', 'rating_payout'],
        min: 0,
        max: 5,
        step: 0.1,
      },
      {
        name: 'deposit_methods',
        search_url: 'hercules/globals/deposit-methods?with_no_assets=1',
        required: false,
        display_name: 'Deposit Payment Methods',
        display_field: 'name',
        type: 'list-many',
        fetch_on_load: false,
        validate_changes: true,
      },
      {
        name: 'withdrawal_methods',
        search_url: 'hercules/globals/deposit-methods?with_no_assets=1',
        required: false,
        display_name: 'Withdrawal Payment Methods',
        display_field: 'name',
        type: 'list-many',
        fetch_on_load: false,
        validate_changes: true,
      },
      {
        name: 'licenses_objects',
        required: false,
        display_name: 'Licenses',
        display_field: 'name',
        type: 'list-many',
        fetch_on_load: false,
        validate_changes: true,
        search_url: 'hercules/operators/licenses?with_no_assets=1',
      },
      {
        name: 'software',
        required: false,
        search_url: 'hercules/globals/software?with_no_assets=1',
        display_name: 'Providers',
        display_field: 'name',
        type: 'list-many',
        fetch_on_load: false,
        validate_changes: true,
      },
      {
        name: 'bonus-types',
        search_url: 'hercules/operators/bonuses/%s/types',
        required: false,
        display_name: 'Bonus Types',
        type: 'link',
      },
      {
        name: 'operator_name',
        required: false,
        type: 'text',
        help_text: 'Override name from general data',
      },
      {
        name: 'operator_url',
        required: false,
        type: 'text',
        help_text: 'Override URL from general data',
      },
      {
        name: 'support_email',
        required: false,
        type: 'text',
        help_text: 'Override support email from general data',
      },
      {
        name: 'withdrawal_times',
        required: false,
        display_name: 'Withdrawal Times',
        type: 'average_number',
        inputs: ['e_wallets', 'bank_transfers', 'cheques', 'card_payments'],
        show_average: false,
        field_type: 'text',
        sanitize_label: false,
      },
      {
        name: 'bet_builder',
        type: 'radio',
        help_text: 'Sportsbook field',
        values: [
          { value: 1, label: 'True' },
          { value: 0, label: 'False' },
          { value: 2, label: 'Unset' },
        ],
        show_if: { field: 'type', value: 'sportsbook' },
      },
      {
        name: 'live_streaming',
        type: 'radio',
        values: [
          { value: 1, label: 'True' },
          { value: 0, label: 'False' },
          { value: 2, label: 'Unset' },
        ],
        show_if: { field: 'type', value: 'sportsbook' },
      },
      {
        name: 'cash_out',
        type: 'radio',
        values: [
          { value: 1, label: 'True' },
          { value: 0, label: 'False' },
          { value: 2, label: 'Unset' },
        ],
        show_if: { field: 'type', value: 'sportsbook' },
      },
      {
        name: 'in_play_betting',
        type: 'radio',
        values: [
          { value: 1, label: 'True' },
          { value: 0, label: 'False' },
          { value: 2, label: 'Unset' },
        ],
        show_if: { field: 'type', value: 'sportsbook' },
      },
      {
        name: 'acca_insurance',
        type: 'radio',
        values: [
          { value: 1, label: 'True' },
          { value: 0, label: 'False' },
          { value: 2, label: 'Unset' },
        ],
        show_if: { field: 'type', value: 'sportsbook' },
      },
      {
        name: 'sport_categories',
        search_url: 'hercules/operators/sport-categories',
        display_name: 'Sport Categories',
        type: 'list-many',
        fetch_on_load: false,
        show_if: { field: 'type', value: 'sportsbook' },
      },
      {
        name: 'betting_types',
        search_url: 'hercules/operators/betting-types',
        display_name: 'Betting Types',
        type: 'list-many',
        fetch_on_load: false,
        show_if: { field: 'type', value: 'sportsbook' },
      },
      {
        name: 'odd_types',
        display_name: 'Odds Type',
        type: 'list-many',
        selectLabel: 'name',
        display_field: 'name',
        options: [
          { id: 'Fractional odds', name: 'Fractional odds' },
          { id: 'Decimal odds', name: 'Decimal odds' },
          { id: 'American odds', name: 'American odds' },
        ],
        show_if: { field: 'type', value: 'sportsbook' },
      },
    ]
  },
  getConfig() {
    return {
      url: 'operators/bonuses',
      name: 'Market Data',
      editWithData: 'market,game_type,sportCategories,bettingTypes,oddsTypes',
    }
  },
  getStructure(operatorId) {
    return {
      list: this.getForm(operatorId),
      config: this.getConfig(),
    }
  },
}
