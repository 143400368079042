<template>
  <div class="sidebar-body-content mw-100 d-flex bg-white card">
    <div class="card-header p-2">
      <div class="w-20">
        <base-select
          :loadOnMount="false"
          optionLabelKey="name"
          ref="selectOddsSubTyType"
          v-model="oddsSubTypes"
          class="pr-2 pl-2 mb-0"
          :selectFirstAfterFetch="true"
          :clearable="false"
          path="hercules/widgets/odds-types"
        />
      </div>
    </div>
  </div>
</template>

<script>
import BaseSelect from '@/components/fields/base-select.vue'
import OddsSubTypesService from '@/services/OddsSubTypesService.js'

export default {
  components: { BaseSelect },
  created() {
    this.getOddsTypeOptions()
  },
  computed: {
    filters() {
      return {
        odds_sub_types: this.oddsSubTypes,
      }
    },
  },
  data() {
    return {
      oddsSubTypes: { id: 1, name: 'Event' },
      odds_sub_types: { id: 1, name: 'Event' },
      oddsSubTypesOptions: [],
    }
  },
  methods: {
    getFilter() {
      return {
        odds_sub_types: this.oddsSubTypes,
      }
    },
    getOddsTypeOptions() {
      OddsSubTypesService.getAll()
        .then((response) => {
          this.oddsSubTypesOptions = response.data.result
        })
        .catch(() => {
          this.showErrorMessage('Failed to get page type categories')
        })
    },
  },
  watch: {
    filters() {
      this.$emit('filterChanged', this.getFilter())
    },
  },
}
</script>
