import { useRoute } from 'vue-router'
export function useTitle() {
  const route = useRoute()
  const setTitle = (name = false) => {
    const env = import.meta.env.VUE_APP_ENV !== 'production' ? import.meta.env.VUE_APP_ENV : ''
    const envSuffix = env ? ` ${env}` : ''
    if (name) {
      document.title = `${name} | Hercules${envSuffix}`
    } else if (route.meta?.name) {
      document.title = `${route.meta.name} | Hercules${envSuffix}`
    } else {
      console.warn('No title set for the current view.')
    }
  }
  return {
    setTitle,
  }
}
