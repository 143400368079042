<template>
  <div class="modal" ref="modal" tabindex="-1" role="dialog" v-click-outside="close">
    <div class="modal-dialog modal-xl">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" v-if="label">
            Bulk editing {{ ids ? ids.length : resultCount }}
            {{ label.endsWith('s') ? label.toLowerCase().slice(0, -1) : label.toLowerCase() }}s
            <div class="popover-wrapper mr-5 d-inline">
              <span v-if="filters" class="popover-trigger clickable">using <strong>filters</strong></span>
              <div class="popover-content" style="font-size: 12px; min-width: 500px">
                <code>{{ filters }}</code>
              </div>
            </div>
          </h5>
          <button class="close" v-on:click="close" />
        </div>
        <div class="modal-body" v-if="bulkFields">
          <div class="row row-cols-3">
            <div
              v-for="(field, index) in bulkFields.filter(
                (field) => field.bulk_edit == true && shouldDisplayField(field)
              )"
              :key="index"
              class="col"
              v-bind:class="{ changed: field.changed }"
            >
              <div class="card">
                <div class="card-header">
                  <base-checkbox
                    :label="`Change ${field.display_name ? field.display_name.toLowerCase() : field.name}`"
                    v-model="field.changed"
                    :readOnly="checkIfOperatorSelected(field)"
                  />
                </div>
                <div class="card-body">
                  <dynamic-field
                    :field="field"
                    :disabled="!field.changed"
                    :readOnly="!field.changed"
                    :searchBy="field.searchBy"
                  />
                  <span
                    v-if="account && field.name === 'migrated_to_id' && !field.changed"
                    class="form-text text-danger"
                    >&nbsp;*&nbsp;You must select an account before submitting.</span
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer d-flex justify-content-between">
          <div>
            <button
              v-if="showRemove"
              class="btn btn-danger"
              v-bind:class="{ loading: loading }"
              @click="remove()"
            >
              Delete
            </button>
          </div>
          <div>
            <button class="btn btn-secondary" v-bind:class="{ loading: loading }" @click="close()">
              Cancel
            </button>
            <button class="btn btn-primary ml-2" v-bind:class="{ loading: loading }" @click="submit()">
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import DynamicField from '@/components/fields/dynamic-field.vue'
import BaseCheckbox from '@atoms/fields/base-checkbox.vue'
import { loadService, loadModel } from '@mixins/HandleDynamicImports.js'

export default {
  components: { DynamicField, BaseCheckbox },
  data() {
    return {
      ids: null,
      filters: null,
      resultCount: {},
      bulkFields: null,
      loading: false,
      label: null,
      extraData: {},
      selectedStatus: null,
      operatorId: null,
      account: false,
    }
  },
  props: {
    model: {
      type: String,
      required: true,
    },
    showRemove: {
      type: Boolean,
      default: () => false,
    },
    serviceFile: {
      type: String,
      default: () => null,
    },
    stateName: {
      type: String,
      default: () => 'data-table',
    },
  },
  created() {
    this.modelLoader()
      .then((model) => {
        if (model) {
          let formFields = model.default.getForm().filter((field) => !field.read_only || false)
          formFields.forEach((field) => {
            field.changed = false
            field.value = null
            field.label = null
            field.required = false
          })
          this.bulkFields = formFields
          this.label = model.default.getConfig().name
        }
      })
      .catch(() => {
        this.showErrorMessage(`Failed to load model ${this.model}`)
      })
  },
  mounted() {
    document.getElementById('modal').appendChild(this.$el)
  },
  watch: {
    bulkFields: {
      handler() {
        this.processFieldChanges()
      },
      deep: true,
      immediate: false,
    },
    operatorId(newVal, oldVal) {
      if (newVal !== oldVal) {
        const migratedField = this.bulkFields.find((field) => field.name === 'migrated_to_id')
        if (migratedField && migratedField.changed) {
          migratedField.value = null // Clear the value
          migratedField.key = new Date().getTime() // Force re-render
        }
      }
    },
  },
  computed: {
    serviceLoader() {
      return () => loadService(this.serviceFile)
    },
    modelLoader() {
      return () => loadModel(this.model)
    },
  },
  methods: {
    checkIfOperatorSelected(field) {
      const operatorsField = this.bulkFields.find((f) => f.name === 'operators')

      if (operatorsField) {
        if (!operatorsField.changed) {
          this.operatorId = null
          operatorsField.value = null
          this.account = false
          // Find and reset 'migrated_to_id' field if 'operators' is unchecked
          const migratedField = this.bulkFields.find((f) => f.name === 'migrated_to_id')
          if (migratedField) {
            migratedField.changed = false
            migratedField.value = null
          }
        } else {
          this.account = true
        }
      }
      // For 'migrated_to_id' field, return whether it should be read-only
      if (field.name === 'migrated_to_id') {
        return !this.operatorId || !operatorsField.changed
      }
      return false
    },

    shouldDisplayField(field) {
      if (field.show_if && this.model === 'koala/Accounts') {
        const allowedValues = field.show_if.value
        return allowedValues.includes(this.selectedStatus)
      }
      return true
    },
    processFieldChanges() {
      if (this.model === 'koala/Accounts' && this.bulkFields) {
        this.bulkFields.forEach((field) => {
          if (field.name === 'status' && field.changed) {
            this.selectedStatus = field.value
          }
          if (field.name === 'operators' && field.changed) {
            this.operatorId = field.value
          }
          if (field.name === 'migrated_to_id' && field.changed) {
            field.search_url = `koala/v1/accounts?limit=100&operators=${this.operatorId}`
          } else if (!field.changed) {
            field.value = null
          }
        })
      }
    },
    showByIds(ids, extraData) {
      this.ids = ids
      this.extraData = extraData
      this.$refs.modal.classList.add('show')
    },
    showByFilters(ids, filters, resultCount, extraData) {
      this.ids = ids
      this.filters = filters
      this.extraData = extraData
      this.resultCount = resultCount
      this.$refs.modal.classList.add('show')
    },
    close() {
      this.loading = false
      this.$refs.modal && this.$refs.modal.classList.remove('show')
      this.$emit('closed')
    },
    changesObject() {
      let changes = {}
      this.bulkFields
        .filter((field) => field.changed)
        .forEach((changedField) => {
          changes[changedField.name] = changedField?.value
        })
      return changes
    },
    pagesCountText() {
      return `${this.ids} ${this.ids.length === 1 ? 'page' : 'pages'}`
    },
    clearBulkSelection() {
      this.$store.dispatch(`${this.stateName}/resetBulk`)
    },
    submit() {
      this.loading = true
      const changes = this.changesObject()
      if (Object.keys(changes).length === 0) {
        this.loading = false
        return this.showErrorMessage('There are no changes')
      }
      this.serviceLoader()
        .then((service) => {
          if (this.ids) {
            return service.default.bulkByIds(this.ids, changes, this.extraData)
          }
          return service.default.bulkByFilters(this.filters, changes, this.extraData)
        })
        .then((response) => {
          if (response.data.success) {
            this.clearBulkSelection()
            this.showSuccessMessage('Bulk update successful')
            this.$emit('refresh')
            this.close()
          } else {
            this.showErrorMessages(response.data.messages)
          }
        })
        .catch(this.showUnknownErrorMessage)
        .finally(() => (this.loading = false))
    },
  },
}
</script>

<style lang="scss" scoped>
.changed {
  border-color: #80c9f1;
}
</style>
