<template>
  <div>
    <data-table
      model="Log"
      service-file="ActivityLogsService"
      path="hercules/logs/activity-logs"
      :column-templates="columnTemplates()"
      :columns="columns()"
      :rowActionButtons="rowActions()"
      :enableRowClick="false"
      :showCreate="false"
      :searchDelay="200"
      :showRemove="false"
      ref="table"
    />
    <activity-logs-list-modal ref="modal" model="model" />
  </div>
</template>

<script lang="jsx">
import ActivityLogsListModal from '@atoms/logs/activity-logs-list-modal.vue'
import ActivityLogsActionColumn from '@atoms/logs/activity-logs-action-column.vue'
import TableColumnTemplate from '@constants/table-column-template.js'

export default {
  components: { ActivityLogsListModal },
  data() {
    return {
      refreshCooldown: false,
    }
  },
  created() {
    this.setTitle()
  },
  methods: {
    columns() {
      return [
        {
          field: 'created_at',
          label: 'Moments ago',
          type: 'text',
          template: TableColumnTemplate.RELATIVE_DATE,
          filterable: false,
        },
        {
          field: 'created_at',
          type: 'text',
          label: 'Date',
          template: TableColumnTemplate.DATE_TIME,
          filterable: true,
        },
        { field: 'model', type: 'text', filterable: true },
        { field: 'model_id', type: 'text', filterable: true },
        { field: 'service', type: 'text', filterable: true },
        { field: 'action', type: 'text', filterable: true },
        { field: 'user_name', type: 'text', label: 'User', filterable: true },
      ]
    },
    rowActions() {
      return [
        {
          action: this.showDiff,
          title: 'Details',
          icon: 'uil uil-eye',
          class: 'btn-info',
        },
      ]
    },
    columnTemplates() {
      return {
        action: function (row) {
          return <ActivityLogsActionColumn action={row.action} />
        },
      }
    },
    showDiff(row) {
      this.$refs.modal.object = row
      this.$refs.modal.show()
    },
  },
}
</script>
