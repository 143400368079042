<template>
  <div class="sidebar-body-content mw-100 d-flex bg-white card">
    <div class="card-header p-2">
      <div class="w-50">
        <base-select
          label="Market"
          v-model="marketId"
          ref="marketSelect"
          placeholder="Choose market"
          :loadOnMount="false"
          :select-first-after-fetch="marketId === null"
          :path="`hercules/sites/markets/by-site/${siteId}`"
          class="pr-2 pl-2 mb-0"
        />
      </div>
      <div class="w-50">
        <base-select
          label="Page type"
          v-model="templateId"
          ref="templateSelect"
          placeholder="Choose page type"
          :loadOnMount="false"
          :select-first-after-fetch="marketId === null"
          optionLabelKey="name"
          :path="`hercules/sites/templates?site_id=${siteId}&orderBy=name&ascending=1&market_id=${marketId}`"
          class="pr-2 pl-2 mb-0"
        />
      </div>
    </div>
  </div>
</template>

<script>
import BaseSelect from '@/components/fields/base-select.vue'

export default {
  props: {
    siteId: {
      type: String,
      default: null,
    },
  },
  components: { BaseSelect },
  mounted() {
    if (this.$route.query.marketId) {
      this.$store.commit('sidebar/setMarket', this.$route.query.marketId)
    }
    if (this.$route.query.pageTypeId) {
      this.$store.commit('sidebar/setPageType', this.$route.query.pageTypeId)
    }

    this.$nextTick(() => {
      this.$refs.marketSelect.getOptions()
      this.$refs.templateSelect.getOptions()
    })
  },
  computed: {
    marketId: {
      get() {
        return this.$store.state.sidebar.market
      },
      set(value) {
        this.$store.commit('sidebar/setMarket', value)
        this.$nextTick(() => {
          this.$refs.templateSelect.getOptions()
        })
      },
    },
    templateId: {
      get() {
        return this.$store.state.sidebar.pageType
      },
      set(value) {
        this.$store.commit('sidebar/setPageType', value)
      },
    },
    cacheConfig() {
      return this.cacheSettings && this.cacheSettings.site_id
    },
    filters() {
      return {
        template_id: this.templateId,
        market_id: this.marketId,
        site_id: this.siteId,
      }
    },
  },
  watch: {
    filters() {
      Promise.all([
        this.$store.commit('sidebar/setSite', this.siteId),
        this.$store.commit('sidebar/setMarket', this.marketId),
        this.$store.commit('sidebar/setPageType', this.templateId),
      ]).then(() => {
        this.$emit('filterChanged', this.getFilter())
      })
    },
  },
  methods: {
    getFilter() {
      let filter = {
        site_id: this.siteId,
        market_id: this.marketId,
        template_id: this.templateId,
      }

      return filter
    },
  },
  unmounted() {
    this.$store.commit('sidebar/setSite', null)
    this.$store.commit('sidebar/setMarket', null)
    this.$store.commit('sidebar/setPageType', null)
    this.$store.commit('sidebar/setCategory', null)
  },
}
</script>

<style scoped>
.type > li.active {
  margin-top: 0 !important;
}
</style>
