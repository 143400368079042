<template>
  <div class="text-center" v-if="isLoading">
    <span>Loading...</span>
  </div>
  <div v-else>
    <div class="row mb-1">
      <div class="col">
        <h3>
          <router-link
            :to="{ path: `/site-menus` }"
            data-tooltip="Back to menus"
            data-tooltip-position="right center"
          >
            <i class="uil uil-angle-left"></i>
          </router-link>
          <span class="mr-3 ml-2">{{ menu.title }}</span>
          <span class="text-muted mr-2">{{ menu.short_code }}</span>
        </h3>
        <div class="additional mb-2">
          <span>Last updated: {{ $dateRelative(menu.updated_at) }}</span>
          <hr class="vertical mx-2" />
          <span class="badge bg-azure">{{ menu.site?.name }}</span>
          <span class="badge bg-purple ml-2">{{ menu.market?.label }}</span>
          <span class="badge bg-green ml-2" v-if="menu.active">active</span>
          <span class="badge bg-black-25 ml-2" v-if="!menu.active">inactive</span>
        </div>
      </div>
    </div>
    <ul class="nav nav-tabs mb-3">
      <router-link tag="li" :to="{ path: `/site-menus/${menu.id}` }" exact>
        <a>General</a>
      </router-link>
      <router-link tag="li" :to="{ path: `/site-menus/${menu.id}/items` }" exact>
        <a>Items</a>
      </router-link>
    </ul>
    <router-view></router-view>
  </div>
</template>
<script>
import MenusService from '@services/MenusService.js'

export default {
  data() {
    return {
      tabs: ['general', 'items'],
      activeTab: 'general',
      loading: false,
      title: '',
    }
  },
  async created() {
    if (this.isIdMismatch) {
      await this.getMenu()
    } else {
      this.$store.commit('editPage/setIsLoading', false)
    }
    this.title = this.menu?.title
  },
  computed: {
    menu() {
      return { ...this.$store.state.editPage.model }
    },
    isIdMismatch() {
      return Number(this.$route.params.id) !== this.menu?.id
    },
    isLoading() {
      return this.$store.state.editPage.isLoading
    },
  },
  methods: {
    async getMenu() {
      await MenusService.get({
        id: this.$route.params.id,
        with: ['children.children', 'site', 'market'].join(),
      })
        .then((response) => {
          if (response.data.messages) {
            this.showErrorMessages(response.data.messages[0])
            this.$router.push('/site-menus')
          } else {
            this.$store.commit('editPage/setModel', response.data.result[0])
            this.title = response.data.result[0]?.title
          }
        })
        .catch(this.showUnknownErrorMessage)
        .finally(() => {
          this.$store.commit('editPage/setIsLoading', false)
        })
    },
  },
  unmounted() {
    if (this.isIdMismatch) {
      this.$store.commit('editPage/setModel', null)
      this.$store.commit('editPage/setIsLoading', true)
    }
  },
}
</script>
<style lang="scss" scoped>
.modules {
  z-index: 1;
}
</style>
