<template>
  <div>
    <data-table
      model="koala/AccountReports"
      service-file="koala/AccountReportsService"
      :path="path()"
      permissionPath="koala/v1/account-reports"
      :columns="columns()"
      :columnTemplates="columnTemplates()"
      ref="table"
      :create-defaults="createDefaults()"
      @bulkClickByIds="bulkClickByIds"
      :enableBulkSelect="true"
      :tableLimits="[10, 50, 100]"
    />
    <dynamic-bulk-edit
      v-if="showBulkEdit"
      ref="bulkEdit"
      model="koala/AccountReports"
      service-file="koala/AccountReportsService"
      @closed="() => (this.showBulkEdit = false)"
      @refresh="$refs.table.search()"
    />
  </div>
</template>

<script lang="jsx">
import TableColumnTemplate from '@constants/table-column-template.js'
import EnabledActionColumn from '@molecules/table/cell/enabled-action.vue'
import AccountReports from '@services/koala/AccountReportsService.js'
import DynamicBulkEdit from '@molecules/koala/dynamic-bulk-edit.vue'
import FilterChangedLogic from '@mixins/FilterChangedLogic.js'

export default {
  mixins: [FilterChangedLogic],
  created() {
    this.setTitle()
  },
  data() {
    return {
      showBulkEdit: false,
    }
  },
  components: { DynamicBulkEdit },
  methods: {
    columns() {
      return [
        {
          label: 'Platform',
          field: 'platform_name',
          type: 'text',
          sortable: true,
          filterable: true,
          class: 'col-platforms',
          search_key: 'platforms',
          advancedFilter: true,
          path: 'koala/v1/platforms?orderBy=name&ascending=1&limit=100',
          optionLabelKey: 'name',
          showModalAdvanced: true,
          basicFilterType: 'select',
          showEmptyNull: false,
          fetch_on_load: false,
          trackBy: 'id',
          showAllOption: true,
          order_key: 'report.platform.name',
          searchBy: 'name',
        },
        {
          label: 'Operator',
          field: 'operator_name',
          type: 'text',
          filterable: true,
          class: 'col-operators',
          search_key: 'operators',
          advancedFilter: true,
          path: this.computedPath.operatorsPath,
          optionLabelKey: 'name',
          showModalAdvanced: true,
          basicFilterType: 'select',
          showEmptyNull: false,
          fetch_on_load: false,
          order_key: 'account.operator.name',
          trackBy: 'id',
          showAllOption: true,
          searchBy: 'name',
        },
        {
          label: 'Account',
          field: 'account.username',
          fieldCustomName: 'Account username',
          type: 'text',
          filterable: true,
          class: 'col-accounts',
          search_key: 'accounts',
          advancedFilter: true,
          path: this.computedPath.accountsPath,
          optionLabelKey: 'username',
          showModalAdvanced: true,
          basicFilterType: 'select',
          showEmptyNull: false,
          fetch_on_load: false,
          sortable: true,
          order_key: 'account.username',
          trackBy: 'id',
          showAllOption: true,
          searchBy: 'username',
          extraSelectLabel: 'operator.name',
          width: '300px',
        },
        {
          label: 'Report',
          field: 'report.name',
          fieldCustomName: 'Report name',
          type: 'text',
          sortable: true,
          filterable: true,
          class: 'col-report-name',
          search_key: 'reports',
          advancedFilter: true,
          path: this.computedPath.reportsPath,
          optionLabelKey: 'name',
          showModalAdvanced: true,
          basicFilterType: 'select',
          showEmptyNull: false,
          fetch_on_load: false,
          order_key: 'report.name',
          trackBy: 'id',
          showAllOption: true,
          extraSelectLabel: 'platform.name',
          width: '300px',
          searchBy: 'name',
        },
        {
          label: 'Operator',
          field: 'operator_name',
          type: 'text',
          filterable: true,
          class: 'col-operators',
          search_key: 'operators',
          advancedFilter: true,
          path: 'koala/v1/operators?orderBy=name&ascending=1&limit=100',
          optionLabelKey: 'name',
          showModalAdvanced: true,
          basicFilterType: 'select',
          sortable: true,
          showEmptyNull: false,
          fetch_on_load: false,
          order_key: 'account.operator.name',
          trackBy: 'id',
          showAllOption: true,
          searchBy: 'name',
        },
        {
          field: 'active',
          label: 'Enabled',
          type: 'text',
          filterable: true,
          advancedFilter: true,
          clearable: true,
          allowNullOption: true,
          customOptions: [
            { label: 'Enabled', id: 'true' },
            { label: 'Disabled', id: 'false' },
          ],
          basicFilterType: 'select',
          optionLabelKey: 'label',
          width: '150px',
          sortable: true,
        },
        { field: 'updated', type: 'text', filterable: false, template: TableColumnTemplate.RELATIVE_DATE },
      ]
    },
    createDefaults() {
      return {
        active: false,
      }
    },
    actionColumnClick(id, field, value) {
      this.$refs.table.isLoading = true
      let object = this.$refs.table.rows.find((item) => item.id === id)
      object = { id: object.id, account: object.account.id, report: object.report.id, active: object.active }
      object[field] = value
      AccountReports.update(object)
        .then(() => {
          this.$refs.table.search()
          this.showSuccessMessage('Column updated')
        })
        .catch(() => {
          this.showErrorMessage('Failed to update account report')
          this.$refs.table.isLoading = false
        })
    },
    columnTemplates() {
      const self = this
      return Object.assign({
        active: function (row) {
          return <EnabledActionColumn row={row} field={'active'} onColumnClick={self.actionColumnClick} />
        },
      })
    },
    bulkClickByIds(ids, extraData, filters, resultCount) {
      if (filters && Object.keys(filters).length > 0) {
        this.showBulkEdit = true
        this.$nextTick(() => this.$refs.bulkEdit.showByFilters(ids, filters, resultCount, extraData))
      } else {
        this.showBulkEdit = true
        this.$nextTick(() => this.$refs.bulkEdit.showByIds(ids))
      }
    },
    bulkClickByFilters(ids, filters, resultCount, extraData) {
      this.showBulkEdit = true
      this.$nextTick(() => this.$refs.bulkEdit.showByFilters(ids, filters, resultCount, extraData))
    },
    path() {
      return `koala/v1/account-reports`
    },
  },
}
</script>
