<template>
  <data-table
    model="Log"
    service-file="LogsService"
    path="hercules/logs/logs"
    :column-templates="columnTemplates()"
    :columns="columns()"
    :enableRowClick="false"
    :show-create="false"
    :show-remove="false"
    search-placeholder="Message or From"
    ref="table"
  />
</template>

<script lang="jsx">
import LogsTypeColumn from '@atoms/logs/logs-type-table-column.vue'
import TableColumnTemplate from '@constants/table-column-template.js'

export default {
  methods: {
    columns() {
      return [
        {
          field: 'created_at',
          label: 'Moments ago',
          type: 'text',
          template: TableColumnTemplate.RELATIVE_DATE,
          filterable: false,
        },
        {
          field: 'created_at',
          type: 'text',
          label: 'Date',
          template: TableColumnTemplate.DATE_TIME,
          filterable: true,
        },
        { field: 'from', type: 'text', filterable: true },
        { field: 'environment', type: 'text', filterable: true },
        { field: 'type', type: 'text', filterable: true },
        { field: 'message', type: 'text', filterable: true },
      ]
    },
    columnTemplates() {
      return {
        type: function (row) {
          return <LogsTypeColumn type={row.type} />
        },
      }
    },
  },
}
</script>
