<template>
  <div v-if="type === 'group'">
    <h3># {{ index + 1 }}</h3>
    <div v-bind:class="listItems">
      <component
        v-for="(child, index) in moduleField.children"
        v-bind:key="index"
        v-bind:is="getFieldComponent(child.field.name)"
        :index="index"
        :fieldSpecificAttributes="fieldSpecificAttributes"
        :affiliateId="affiliateId"
        :last="index + 1 === moduleField.children.length"
        :parent-type="type"
        :site-id="siteId"
        :market-id="marketId"
        :visible-fields="visibleFields"
        :module-field="child"
      />
    </div>
  </div>
  <!-- is list -->
  <div v-else-if="type === 'list'" class="col">
    <draggable
      tag="div"
      :list="moduleField.children"
      :class="getListFieldClass(moduleField.field.name)"
      item-key="id"
    >
      <template #item="{ element, index }">
        <component
          v-bind:key="index"
          v-bind:is="getFieldComponent(element.field.name)"
          :affiliateId="affiliateId"
          :visible-fields="visibleFields"
          :index="index"
          :last="index + 1 === moduleField.children.length"
          :fieldSpecificAttributes="fieldSpecificAttributes"
          :parent-type="type"
          :module-field="element"
          :site-id="siteId"
          :market-id="marketId"
        />
      </template>
    </draggable>
  </div>
</template>
<script>
import Draggable from 'vuedraggable'
import SpotlightsGroup from '@molecules/cms/modules/preview/spotlights-group.vue'
import IframeGroup from '@molecules/cms/modules/preview/iframe-group.vue'
import FaqGroup from '@atoms/cms/modules/preview/faq-group.vue'
import AccordionGroup from '@atoms/cms/modules/preview/accordion-group.vue'

import pageEditModuleFieldVue from './module-dynamic-fields-preview.vue'

export default {
  components: {
    Draggable,
    SpotlightsGroup,
    IframeGroup,
    FaqGroup,
    AccordionGroup,
    // TopListItems
  },
  props: {
    fieldSpecificAttributes: {},
    hidden: {
      type: Boolean,
      required: false,
    },
    moduleField: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
    },
    last: {
      type: Boolean,
    },
    parentType: {
      type: String,
    },
    siteId: {
      type: Number,
      required: true,
    },
    marketId: {
      type: Number,
    },
    affiliateId: {
      type: Number,
      required: true,
    },
    visibleFields: {
      default: () => null,
    },
  },
  name: 'page-edit-module-field',
  computed: {
    type() {
      return this.moduleField.field.type
    },
    listItems() {
      return this.moduleField.field.type != 'group' ? 'd-flex justify-content-between' : ''
    },
  },
  methods: {
    getFieldComponent(name) {
      switch (name) {
        case 'spotlights_group':
          return SpotlightsGroup
        case 'iframe_clip_group':
          return IframeGroup
        case 'faq_group':
          return FaqGroup
        case 'accordion_group':
          return AccordionGroup
        default:
          return pageEditModuleFieldVue
      }
    },
    getListFieldClass(name) {
      switch (name) {
        case 'spotlights_list':
          return 'row row-cols-4'
        case 'iframe_list':
          return 'row row-cols-4'
        case 'faq_list':
          return 'row row-cols-1'
      }
    },
  },
}
</script>
