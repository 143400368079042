<template>
  <div>
    <div v-bind:key="index" v-for="(moduleField, index) in pageModule.module_value_fields">
      <base-input
        v-if="isTypeOnlyText.includes(moduleField.field.name)"
        :label="moduleField.field.name"
        :modelValue="moduleField.value"
        v-model="moduleField.value"
      />

      <base-input
        v-if="isTypeOnlyNumber.includes(moduleField.field.name)"
        type="number"
        :label="moduleField.field.name"
        :modelValue="moduleField.value"
        v-model="moduleField.value"
      />

      <base-select
        v-else-if="Object.hasOwn(isItWithOptions, moduleField.field.name)"
        :label="moduleField.field.name"
        :modelValue="moduleField.value"
        :placeholder="'Select ' + $prettyLabels(moduleField.field.name).toLowerCase()"
        :custom-options="isItWithOptions[moduleField.field.name]"
        v-model="moduleField.value"
      />

      <base-radio
        v-if="
          isItRadioButtonsType &&
          Object.hasOwn(isItRadioButtonsType, moduleField.field.name) &&
          (moduleField.value
            ? moduleField.value
            : (moduleField.value = isItRadioButtonsType[moduleField.field.name].default))
        "
        :label="moduleField.field.name"
        :modelValue="
          moduleField.value ? moduleField.value : isItRadioButtonsType[moduleField.field.name].default
        "
        :name="moduleField.field.name"
        :data="isItRadioButtonsType[moduleField.field.name].values"
        @update:modelValue="(option) => (moduleField.value = option)"
        v-bind:key="index"
        :useNameValue="false"
        :inlineLayout="true"
      />
    </div>
  </div>
</template>

<script>
import BaseInput from '@atoms/fields/base-input.vue'
import BaseSelect from '@/components/fields/base-select.vue'
import BaseRadio from '@atoms/fields/base-radio.vue'
import ModuleGroupAddItem from '@atoms/cms/modules/module-group-add-item.vue'

export default {
  components: { BaseSelect, BaseInput, ModuleGroupAddItem, BaseRadio },
  props: {
    siteId: {
      required: false,
      default: null,
    },
    marketId: {
      required: false,
      default: null,
    },
    pageModule: {
      type: Object,
      required: true,
      default: () => {},
    },
  },
  data() {
    return {
      isTypeOnlyText: ['archive_title'],
      isTypeOnlyNumber: [
        'archive_num_of_items',
        'archive_desktop_num_of_columns',
        'archive_tablet_num_of_columns',
        'archive_mobile_num_of_columns',
      ],
      isItRadioButtonsType: {
        archive_sort_by: {
          values: ['created_at', 'updated_at', 'random'],
          default: 'created_at',
        },
        archive_sort_order: {
          values: ['ascending', 'descending'],
          default: 'ascending',
        },
        archive_pagination_type: {
          values: ['numbered', 'next_previous', 'load_more'],
          default: 'numbered',
        },
        archive_display_input: {
          values: ['automatic', 'custom'],
          default: 'automatic',
        },
      },
      isItWithOptions: {
        archive_model_type: [
          'article',
          'game',
          'operator',
          'page',
          'software_provider',
          'payment_method',
          'event',
        ],
      },
    }
  },
  methods: {
    setModuleChildValue(field, siteId) {
      field.children[0].value = siteId
    },
  },
}
</script>
