<template>
  <div class="card">
    <div class="card-header">
      <h2>Notification</h2>
    </div>
    <div class="card-body">
      <form v-on:submit.prevent="save()">
        <base-input
          :modelValue="text"
          v-model="text"
          :required="true"
          label="Message"
          placeholder="Type a message"
        />
        <div class="switch">
          <input type="checkbox" v-model="enabled" id="notification-enabled" />
          <label for="notification-enabled">Enabled</label>
        </div>
      </form>
    </div>
    <div class="card-footer">
      <button class="btn btn-primary" @click="save">Save</button>
    </div>
  </div>
</template>

<script>
import BaseInput from '@atoms/fields/base-input.vue'
import SettingsService from '@services/SettingsService.js'

export default {
  components: { BaseInput },
  data() {
    return {
      enabled: false,
      text: '',
    }
  },
  mounted() {
    SettingsService.getAll({
      keys: ['notification_enabled', 'notification_text'],
    }).then((response) => {
      if (response.data.success) {
        const result = response.data.result
        this.enabled = result.find((setting) => setting.key === 'notification_enabled').value === '1'
        this.text = result.find((setting) => setting.key === 'notification_text').value
      }
    })
  },
  methods: {
    save() {
      SettingsService.update({
        notification_enabled: this.enabled ? 1 : 0,
        notification_text: this.text,
      })
        .then((response) => {
          if (response.data.messages) {
            this.showErrorMessages(response.data.messages)
          } else {
            this.emitter.emit('toggle-notification', { text: this.text, enabled: this.enabled })
            this.showSuccessMessage('Settings saved')
          }
        })
        .catch(this.showUnknownErrorMessage)
    },
  },
}
</script>
