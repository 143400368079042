<template>
  <div class="card">
    <dynamic-form
      :object="prefilledModule"
      ref="form"
      :path="config.url"
      form-type="edit"
      :formFields="structure.list"
      :inCard="true"
      :on-submit="update"
      :fixedButtons="true"
      submitLabel="Save"
    />
  </div>
</template>
<script>
import PrefilledModulesService from '@services/PrefilledModulesService.js'
import DynamicForm from '@/components/fields/DynamicForm.vue'
import PrefilledModule from '@models/cms/PrefilledModule.js'

export default {
  components: {
    DynamicForm,
  },
  created() {
    this.structure = PrefilledModule.getStructure()
  },
  props: {
    prefilledModule: {},
  },
  data() {
    return {
      structure: { list: [] },
      config: PrefilledModule.getConfig(),
    }
  },
  methods: {
    async update() {
      this.prefilledModule.prefilled_module_markets = this.prefilledModule.prefilled_module_markets.filter(
        (item) => item.active
      )
      await PrefilledModulesService.update(this.prefilledModule)
        .then((response) => {
          if (response.data.messages) {
            this.showErrorMessages(response.data.messages)
          } else {
            this.prefilledModule.prefilled_module_markets = response.data.result.prefilled_module_markets
            this.showSuccessMessage('Prefilled module saved')
          }
        })
        .catch(this.showUnknownErrorMessage)
        .finally(() => {
          this.$refs.form.isLoading = false
        })
    },
  },
}
</script>
