<script setup>
import { computed, onUnmounted, getCurrentInstance, onMounted } from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import OperatorsService from '@services/OperatorsService.js'
import AssetsService from '@services/AssetsService.js'
import BaseAsset from '@atoms/fields/base-asset.vue'
import OperatorBadges from './operator-badges.vue'

const { proxy } = getCurrentInstance()
const store = useStore()
const route = useRoute()
const router = useRouter()

const itemLogo = computed(() => store.state.editPage.standardiseLogoUrl)
const operator = computed(() => ({ ...store.state.editPage.model }))
const isLoading = computed(() => store.state.editPage.isLoading)
const showCallout = computed(() => route.path.includes('/wagering'))

//To handle the goback button logic
const getCurrentPath = () => {
  const suffixes = ['/sites-data', '/markets-data']
  const suffix = suffixes.find((suffix) => route.path.includes(suffix))
  return suffix ? `/operators/${route.params.id}${suffix}` : '/operators'
}

const getOperator = async () => {
  if (operator.value.id == route.params.id) {
    return
  }
  try {
    const response = await OperatorsService.getOne(route.params.id)
    if (response.data.messages) {
      proxy.showErrorMessages(response.data.messages)
      response.data.messages.some((message) => {
        if (message.includes('Operator not found')) {
          router.push('/operators')
        }
      })
    } else {
      store.commit('editPage/setModel', response.data.result[0])
      if (store.hasModule('data-table')) {
        store.commit('data-table/setTitle', response.data.result[0].name)
        store.commit(
          'data-table/setStandardisedLogo',
          response.data.result[0]?.standardised_logo_asset_id || null
        )
      }

      let standardisedLogo = response.data.result[0].standardised_logo_asset_id
      if (standardisedLogo !== null) {
        await getAsset(standardisedLogo)
      } else {
        store.commit('editPage/setStandardiseLogoUrl', null)
      }
    }
  } catch (error) {
    proxy.showUnknownErrorMessage(error)
  } finally {
    store.commit('editPage/setIsLoading', false)
  }
}

const getAsset = async (standardisedLogo) => {
  await AssetsService.getOne(standardisedLogo, { with: 'site,market' }).then((response) => {
    if (response.data.result.length === 1) {
      store.commit('editPage/setStandardiseLogoUrl', response.data.result[0])
    } else {
      store.commit('editPage/setStandardiseLogoUrl', null)
    }
  })
}

// Fetch operator data on initial load and when the ID changes
onMounted(() => {
  getOperator()
})

// const updateStatus = (status) => {
//   OperatorsService.update({
//     id: route.params.id,
//     status: status,
//     status_changed_by: store.state.auth.user.id,
//   })
//     .then((response) => {
//       if (response.data.messages) {
//         proxy.showErrorMessages(response.data.messages)
//       } else {
//         proxy.showSuccessMessage('Status updated')
//         getOperator()
//       }
//     })
//     .catch((error) => {
//       proxy.showUnknownErrorMessage(error)
//     })
// }

// Cleanup on unmount
onUnmounted(() => {
  store.commit('data-table/setStandardisedLogo', null)
  store.commit('data-table/setTitle', '')

  if (operator.value.id && operator.value.id !== Number(route.params.id)) {
    store.commit('editPage/setModel', null)
    store.commit('editPage/setStandardiseLogoUrl', null)
    store.commit('editPage/setIsLoading', true)
  }
})
</script>
<template>
  <div class="text-center" v-if="isLoading">
    <span>Loading...</span>
  </div>
  <div v-else>
    <div class="row">
      <div class="col">
        <h3>
          <router-link
            :to="{ path: getCurrentPath() }"
            data-tooltip="Back to operators"
            data-tooltip-position="right center"
          >
            <i class="uil uil-angle-left"></i>
          </router-link>
          <span class="mr-3 ml-2">{{ operator.name }}</span>
          <span class="text-muted mr-2">{{ operator.short_name }}</span>
          <base-asset
            v-if="itemLogo"
            :asset="itemLogo"
            width="50px"
            height="50px"
            class="p-0 rounded-circle border-none"
          />
          <span v-if="showCallout" class="callout callout-help float-right">
            Section to be deprecated. Add wagering info to Bonus section in Markets Data.
          </span>
        </h3>
        <OperatorBadges :operator="operator" />
      </div>
    </div>
    <ul
      class="nav nav-tabs mb-3"
      v-if="route.path === `/operators/${operator.id}` || route.path === `/operators/${operator.id}/toplists`"
    >
      <router-link tag="li" :to="{ path: `/operators/${operator.id}` }" exact>
        <a>General</a>
      </router-link>
      <router-link tag="li" :to="{ path: `/operators/${operator.id}/toplists` }" exact>
        <a>Toplists</a>
      </router-link>
    </ul>
    <router-view></router-view>
  </div>
</template>
<style scoped></style>
