<template>
  <div>
    <data-table
      model="BonusGeneric"
      service-file="GenericBonusesService"
      :path="path()"
      :columns="columns()"
      :serviceParams="[operator_id]"
      :editFieldFilters="['operator_id']"
      :create-defaults="createDefaults()"
    />
  </div>
</template>

<script>
import TableColumnTemplate from '@constants/table-column-template.js'

export default {
  data() {
    return {
      operator_id: this.$route.params['id'],
    }
  },
  created() {
    this.setTitle(
      `${this.$parent.$parent.title ? this.$parent.$parent.title + ':' : ''} ${this.$route.meta.name}`
    )
  },
  methods: {
    columns() {
      return [
        { field: 'type', type: 'text', filterable: true },
        { field: 'dep_wagreq', type: 'text', filterable: true, label: 'Deposit Wagering' },
        { field: 'fs_wagreq', type: 'text', filterable: true, label: 'FS Wagering Requirements' },
        { field: 'fsnd_wagreq', type: 'text', filterable: true, label: 'FS No Deposit Requirements' },
        { field: 'updated_at', type: 'text', filterable: true, template: TableColumnTemplate.RELATIVE_DATE },
      ]
    },
    path() {
      return `hercules/operators/generic-bonuses?operator_id=${this.$route.params['id']}`
    },
    createDefaults() {
      return {
        operator_id: this.$route.params['id'],
      }
    },
  },
}
</script>
