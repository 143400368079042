export async function loadService(serviceFile) {
  const modules = import.meta.glob('/src/services/**/*.js')
  const path = `/src/services/${serviceFile}.js`
  const module = modules[path]
  if (!module) {
    throw new Error(`Service not found: ${serviceFile}`)
  }
  return module()
}

export async function loadModel(model) {
  const modules = import.meta.glob('/src/models/**/*.js')
  const path = `/src/models/${model}.js`
  const module = modules[path]
  if (!module) {
    throw new Error(`Model not found: ${model}`)
  }
  return module()
}
