<template>
  <div class="card">
    <div class="card-header">
      <h3>Provider links</h3>
      <div class="ml-auto d-flex align-items-center">
        <span class="text-muted mr-2">
          Save your changes
          <strong>before</strong> changing to a different tab
        </span>
        <i class="uil uil-exclamation-circle mr-3 text-muted" />
        <button class="btn btn-primary" v-bind:class="{ loading: loading }" @click="submit">
          <i class="uil uil-check mr-2"></i>Save
        </button>
      </div>
    </div>
    <div class="card-body">
      <table class="table">
        <thead>
          <tr>
            <th>Campaign</th>
            <th>Channel</th>
            <th>Link</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody v-if="siteProvider" class="multi-column-form">
          <tr v-for="(link, index) in siteProvider.links" :key="index">
            <td class="link-campaign">
              <base-input
                v-model="link.campaign"
                :disabled="isReadOnly(link)"
                :valueModifier="slugify"
                :showLabel="false"
              />
            </td>
            <td class="link-channel">
              <base-input
                v-model="link.channel"
                :disabled="isReadOnly(link)"
                :valueModifier="slugify"
                :showLabel="false"
              />
            </td>
            <td>
              <base-input v-model="link.link" :showLabel="false" />
            </td>
            <td class="link-actions">
              <div v-if="!isReadOnly(link)">
                <button class="btn btn-link icon-button" data-tooltip="Add" @click="addRow(index + 1)">
                  <i class="uil uil-plus"></i>
                </button>
                <button class="btn btn-link icon-button" data-tooltip="Clone" @click="cloneRow(index)">
                  <i class="uil uil-share-alt"></i>
                </button>
                <button class="btn btn-link icon-button" data-tooltip="Remove" @click="removeRow(index)">
                  <i class="uil uil-trash"></i>
                </button>
              </div>
            </td>
          </tr>
          <tr>
            <td colspan="6">
              <button class="btn btn-info" @click="addRow(siteProvider.links.length)">
                Add new link
                <i class="uil uil-plus ml-2"></i>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import SiteProvidersService from '@services/SiteProvidersService.js'
import BaseCheckbox from '@atoms/fields/base-checkbox.vue'
import BaseInput from '@atoms/fields/base-input.vue'

export default {
  components: { BaseCheckbox, BaseInput },
  data() {
    return {
      loading: false,
      showModal: false,
      siteProvider: null,
    }
  },
  mounted() {
    this.show()
    this.setTitle(`${this.$parent.$parent.title ? this.$parent.$parent.title + ':' : ''} Links`)
  },
  methods: {
    show() {
      SiteProvidersService.get({ id: this.$route.params.id, with: 'links,provider,sport,site' })
        .then((response) => {
          this.siteProvider = response.data.result[0]
          if (this.siteProvider.links.length === 0) {
            this.addRow()
          }
        })
        .catch(this.showUnknownErrorMessage)
    },
    addRow(index = 0) {
      const newRow = {
        id: null,
        campaign: null,
        channel: null,
        link: null,
        static_link: false,
        site_provider_id: this.siteProvider.id,
      }
      this.siteProvider.links.splice(index, 0, newRow)
    },
    removeRow(index) {
      this.siteProvider.links.splice(index, 1)
    },
    cloneRow(index) {
      let clone = { ...this.siteProvider.links[index], id: null }
      this.siteProvider.links.splice(index + 1, 0, clone)
    },
    async submit() {
      this.loading = true
      await SiteProvidersService.update(this.siteProvider)
        .then((response) => {
          if (response.data.success) {
            this.showSuccessMessage('Saved successfully')
            this.siteProvider = response.data.result
            if (this.siteProvider.links.length === 0) {
              this.addRow()
            }
          } else {
            this.showErrorMessages(response.data.messages)
          }
        })
        .catch(this.showUnknownErrorMessage)
        .finally(() => {
          this.loading = false
        })
    },
    isReadOnly(link) {
      return link.static_link == 1
    },
  },
}
</script>

<style lang="scss" scoped>
.link-static {
  width: 30px;
}
.link-actions {
  width: 180px;
}
.link-campaign {
  max-width: 100px;
}
.link-channel {
  max-width: 100px;
}
</style>
