<template>
  <div class="d-flex">
    <div class="form-group">
      <label>{{ $prettyLabels(label) }}</label>
      <input v-bind="$attrs" type="text" :readonly="readOnly" v-model="color" class="form-control" />
    </div>
    <div class="form-group ml-4">
      <label>Color picker</label><span v-if="required">&nbsp;*&nbsp;</span>
      <label v-if="color" class="text-muted ml-2" @click="clearColor" style="cursor: pointer">clear</label>
      <input
        :name="label && slugify(label)"
        type="color"
        :readonly="readOnly"
        :required="required"
        v-model="color"
        class="form-control"
        style="padding: 4px 6px"
      />
    </div>
    <small v-if="helpText" class="form-text text-muted">{{ helpText }}</small>
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
    },
    helpText: {
      type: String,
    },
    modelValue: {
      type: [String, Number],
      default: '',
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      color: this.modelValue || '#ececec',
    }
  },
  watch: {
    modelValue(newValue) {
      if (newValue !== this.color) {
        this.color = newValue
      }
    },
    color(newValue) {
      this.$emit('update:modelValue', newValue)
    },
  },
  methods: {
    clearColor() {
      this.color = ''
      this.$emit('update:modelValue', '')
    },
  },
}
</script>
