<template>
  <div class="d-flex pt-0 pb-0 filter-container position-relative">
    <div
      class="d-flex justify-content-center align-items-center"
      v-if="column.showModalAdvanced || column.clearFilter"
      style="margin-top: 24px"
    >
      <i class="uil uil-filter d-flex align-items-center text-muted" @click="showModal"></i>
      <span
        class="badge icon-badge remove-filter"
        @click="clearFilter"
        data-tooltip="Clear Filters"
        v-if="!basicFilterEnabled || basicFilterValue"
      >
        <i class="uil uil-times"></i>
      </span>
    </div>
    <div class="modal" ref="modal" v-click-outside="closeModal" v-if="column.showModalAdvanced">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Advanced Filter</h5>
            <button class="close" v-on:click="closeModal"></button>
          </div>
          <div class="modal-body pb-0">
            <base-radio
              label="Filter By: "
              name="filter"
              :modelValue="include && 'include'"
              :data="extraFilters"
              @update:modelValue="
                (option) => (
                  (include = option.value === 'include'),
                  (showOnlyEmpty = option.value === 'only_empty'),
                  (showOnlyPopulated = option.value === 'only_populated')
                )
              "
              :disabled="selectEmpty"
            />
            <base-multiselect
              v-if="column.path || column.customOptions"
              :label="column.fieldCustomName ? column.fieldCustomName : $prettyLabels(column.field)"
              :placeholder="column.optionPlaceholder"
              v-bind:custom-options="column.customOptions"
              v-bind:path="column.path"
              v-bind:selectLabel="column.optionLabelKey"
              :extraOptionLabelKey="column.extraSelectLabel"
              :field="column.field"
              :multiple="true"
              @sync="saveFilterValue"
              :trackByOption="column.trackBy"
              :searchBy="column.searchBy"
              :disabled="selectEmpty || showOnlyEmpty || showOnlyPopulated"
              :loadOnMount="typeof column.fetch_on_load !== 'undefined' ? column.fetch_on_load : true"
              helpText="Filter will identify entries with any of the above (OR logic)."
              ref="multiselect"
            />
          </div>
          <div class="modal-footer">
            <button class="btn btn-secondary mr-2" v-on:click="closeModal">Close</button>
            <button class="btn btn-primary" v-on:click="applyFilter(false)">Apply</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BaseMultiselect from '@/components/fields/base-multiselect.vue'
import BaseRadio from '@atoms/fields/base-radio.vue'
import _ from 'lodash'

export default {
  components: { BaseMultiselect, BaseRadio },
  watch: {
    basicFilterValue(filterValue) {
      this.checkBasicFilterValue(filterValue)
    },
    filtersEnabled() {
      let searchKey = this.column.search_basic_key ? this.column.search_basic_key : this.column.search_key
      this.basicFilterValue = Object.hasOwn(
        this.filtersEnabled,
        this.column.search_key ? searchKey : this.column.field
      )
        ? this.filtersEnabled[this.column.search_key ? searchKey : this.column.field]
        : ''
    },
  },
  created() {
    if (this.column.extraFilters) {
      this.extraFilters = this.extraFilters.concat(this.column.extraFilters)
    }
    this.tableIsModal = this.$store.hasModule('model-select-items-selector')
  },
  computed: {
    placeholder() {
      if (this.basicFilterEnabled) {
        return `Filter`
      }
      return 'Advanced Filter On'
    },
  },
  data() {
    return {
      tableIsModal: false,
      filterValue: '',
      include: true,
      showOnlyEmpty: false,
      showOnlyPopulated: false,
      basicFilterValue: '',
      basicFilterEnabled: true,
      selectEmpty: false,
      extraFilters: ['include'],
    }
  },
  props: {
    column: {
      required: true,
      type: Object,
    },
    showModalAdvanced: {
      type: Boolean,
      default: () => false,
    },
    filtersEnabled: {
      type: Object,
      default: () => {},
    },
    stateName: {
      type: String,
      default: 'data-table',
    },
    isKoalaModel: {
      type: Boolean,
      default: () => false,
    },
  },
  methods: {
    checkBasicFilterValue: _.debounce(function (filterValue) {
      this.$emit('filterChange', { [this.column.field]: filterValue })
    }, 300),
    filterName(include) {
      let customPath
      if (this.column.includeCustomPath && this.column.model === 'AffiliateLink') {
        customPath = include
          ? 'is_in[' + this.column.search_key + ']'
          : 'is_not[' + this.column.search_key + ']'
      }

      if (this.column.includeCustomPath) {
        customPath = include
          ? 'include[' + this.column.search_key + ']'
          : 'exclude[' + this.column.search_key + ']'
        if (this.selectEmpty) {
          customPath = 'is_null_or_empty[]'
          return customPath
        }
        return customPath
      }
      return include ? '' : this.isKoalaModel ? '_exclude' : '__exclude'
    },
    clearFilter() {
      this.basicFilterValue = ''
      if (this.basicFilterEnabled) {
        this.filterValue = ''
        this.basicFilterEnabled = true
      } else {
        this.filterValue = ''
        this.selectEmpty = false
        this.showOnlyEmpty = false
        this.showOnlyPopulated = false
        this.applyFilter(true)
        this.include = true
        this.$refs.multiselect && this.$refs.multiselect.deselectAll()
        this.basicFilterEnabled = true
      }
    },
    saveFilterValue(field, value) {
      this.filterValue = _.map(value, (item) => item[this.column.trackBy ? this.column.trackBy : 'id']).join()
    },
    applyFilter(reset) {
      this.basicFilterValue = ''

      let nullOrEmptyValue

      let showValues = this.selectEmpty || this.showOnlyEmpty
      let filterObj = {
        [this.getEliasEmptyFieldName(this.column)]: showValues ? nullOrEmptyValue : '',
        [`is_not_empty[${this.column.field}]=`]: this.showOnlyPopulated ? nullOrEmptyValue : '',
      }

      reset = this.selectEmpty || this.showOnlyEmpty || this.showOnlyPopulated ? true : reset
      let columnField = this.isKoalaModel
        ? this.column.search_key
        : !this.column.includeCustomPath
        ? this.column.field
        : ''
      Object.assign(filterObj, {
        [`${columnField}${this.filterName(true)}`]: this.include && !reset ? this.filterValue : '',
        [`${columnField}${this.filterName(false)}`]: !this.include && !reset ? this.filterValue : '',
      })
      this.basicFilterEnabled = false
      this.$emit('filterChange', filterObj)
      this.closeModal()
    },
    showModal() {
      this.basicFilterValue = ''
      document.getElementById('modal').appendChild(this.$refs.modal)
      setTimeout(() => {
        this.$refs.modal.classList.add('show')
      }, 100)
    },
    closeModal() {
      this.$refs.modal && this.$refs.modal.classList.remove('show')
    },
    clearPaginationOnInput() {
      // moved this from table component to here bcs of pagination memory
      this.$store.dispatch(`${this.stateName}/setActivePage`, 1)
    },
  },
  unmounted() {
    this.$store.commit(`${this.stateName}/setAdvancedFilters`, {})
  },
}
</script>

<style scoped>
.filter-container > div,
.filter-container > i {
  padding: 0;
}

i {
  cursor: pointer;
}

.badge.icon-badge.remove-filter {
  position: absolute;
  display: block;
  right: -10px;
  top: 40%;
  transform: translate(-50%, -50%);
  width: 16px;
  height: 16px;
  font-size: 0px;
  z-index: 1;
}

.remove-filter i {
  font-size: 16px !important;
}
</style>
