<template>
  <div>
    <MarketsDataFilter @filterChange="onFilterChanged" />
    <data-table
      :key="tableKey"
      model="Bonus"
      service-file="BonusesService"
      :serviceParams="[operator_id]"
      :path="path"
      ref="list"
      :columns="columns()"
      :create-defaults="createDefaults()"
      :rowActionButtons="rowActions()"
      :column-templates="columnTemplates()"
      :customRowClick="customRowClick"
      :show-edit-modal="false"
      :show-create="false"
      :customActionButton="{
        action: marketCreatePage,
        title: 'Create',
        class: 'btn btn-primary',
        icon: 'uil uil-plus',
      }"
    />
    <market-clone ref="marketCloneModal" />
  </div>
</template>

<script setup>
import { ref, getCurrentInstance, onMounted, computed, onBeforeMount } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import MarketClone from '@molecules/dms/operator/markets-data/clone-modal.vue'
import TableColumnTemplate from '@constants/table-column-template.js'
import MarketsDataFilter from './markets-data-filter.vue'
import { useStore } from 'vuex'

const { proxy } = getCurrentInstance()
const route = useRoute()
const router = useRouter()
const marketCloneModal = ref(null)
const list = ref(null)
const store = useStore()
const operator_id = route.params['id']
const isMarketOnStore = computed(() => store.state.editPage.marketId)

// const isCreateOrEditPage = computed(() => {
//   return route.path.includes('/markets-data/create') || route.path.includes('/markets-data/edit')
// })

const path = ref(
  `hercules/operators/bonuses?with=market,currencies&operator_id=${operator_id}&limit=10&page=1`
)
const tableKey = ref(0)

const rowActions = () => {
  return [
    {
      action: cloneAction,
      title: 'Clone',
      icon: 'uil uil-share-alt',
      class: 'bg-orange-75',
    },
  ]
}
onBeforeMount(() => {
  if (isMarketOnStore.value) {
    onFilterChanged()
  }
})

onMounted(() => {
  proxy.setTitle(`${proxy.$parent.$parent.title ? proxy.$parent.$parent.title + ':' : ''} ${route.meta.name}`)
})

const columns = () => {
  return [
    { field: 'type', type: 'text', filterable: true },
    { field: 'market_label', type: 'text', filterable: true, label: 'Market' },
    { field: 'min_deposit', type: 'text', filterable: true },
    { field: 'min_withdrawal', type: 'text', filterable: true },
    { field: 'max_withdrawal', type: 'text', filterable: true },
    { field: 'currencies', type: 'number', filterable: true },
    { field: 'rating', type: 'text', filterable: true },
    { field: 'updated_at', type: 'text', filterable: true, template: TableColumnTemplate.RELATIVE_DATE },
  ]
}

const columnTemplates = () => {
  return {
    currencies: function (row) {
      if (row.currencies && row.currencies.length) {
        return row.currencies.length
      }
      return ''
    },
  }
}

const marketCreatePage = () => {
  router.push(`/operators/${route.params.id}/markets-data/create`)
}

const customRowClick = (event, row) => {
  router.push(`/operators/${route.params.id}/markets-data/edit/${row.id}`)
}

// const onCreateClick = () => {
//   router.push(`/operators/${route.params.id}/markets-data/create`)
// }

const cloneAction = (row) => {
  marketCloneModal.value.show(row)
}

const createDefaults = () => {
  return {
    operator_id: route.params['id'],
    rating: 4,
  }
}

const onFilterChanged = (market, type) => {
  let newPath = `hercules/operators/bonuses?with=market,currencies&operator_id=${operator_id}&exact_match=type&limit=10&page=1`

  const queryParams = []

  if (market || isMarketOnStore.value) {
    let isMarketSelected = market ? market : isMarketOnStore.value
    const marketLabel = isMarketSelected.split('-')[0].toLowerCase()
    queryParams.push(`market_label=${marketLabel}`)
  }

  if (type) {
    queryParams.push(`type=${type}`)
  }

  if (queryParams.length) {
    newPath += `&${queryParams.join('&')}`
  }

  path.value = newPath
  tableKey.value++
}
</script>
