<template>
  <div>
    <div class="form-group" v-if="showField">
      <label>
        {{ customLabel ? $prettyLabels(customLabel) : '' }}
        <span v-if="valueLabel || disabled" class="text-muted clickable" @click="reset()">clear</span>
      </label>
      <div :class="tableWrapperClass">
        <table :class="['multi-select-modal-result-table', tableClass, loading && 'loading']">
          <thead>
            <tr>
              <th v-for="(column, index) in columns()" :key="index">
                {{ $prettyLabels(column.label ? column.label : column.field) }}
              </th>
            </tr>
          </thead>
          <draggable
            tag="tbody"
            :list="selectedValues"
            v-if="valueLabel"
            ghost-class="ghost"
            @change="onDrag"
          >
            <template #item="{ element }">
              <tr :class="[draggable && 'draggable']">
                <table-column
                  v-for="(column, idx) in columns()"
                  :key="idx"
                  :column="column"
                  :row="element"
                  :template="columnTemplates()[column.field] || null"
                />

                <td class="text-right">
                  <button
                    :disabled="loading"
                    v-bind:class="{ loading: loading }"
                    @click="deleteItem(element.id)"
                    class="btn btn-danger btn-sm"
                  >
                    <i class="uil uil-trash"></i> Delete
                  </button>
                </td>
              </tr>
            </template>
          </draggable>
        </table>
        <button class="btn btn-secondary d-block" @click="show">
          <i class="uil uil-plus"></i>
          Add {{ $prettyLabels(type) }}s
        </button>
      </div>
    </div>
    <small v-if="helpText" class="form-text text-muted">{{ helpText }}</small>
    <div class="modal modal-xlarge" v-if="showModal" ref="modal">
      <div class="modal-dialog" v-click-outside="close">
        <div class="modal-content">
          <div class="modal-header pb-2">
            <h3>{{ customLabel }}</h3>
            <button class="close" @click="close()"></button>
          </div>
          <div class="modal-body pb-0">
            <div class="row">
              <multi-select-filter
                v-if="showFilter"
                :type="type"
                :pageTypeId="pageTypeId"
                :parameters="this.parameters"
                @filterChanged="filterChanged"
              />
              <div v-bind:class="{ 'col-lg-8': showFilter, 'col-lg-12': !showFilter }">
                <data-table
                  stateName="multi-select-table"
                  :path="fixedPath()"
                  :basicHeader="true"
                  :basicTable="true"
                  :showCreate="false"
                  :enabledAdvancedSearchOnMount="true"
                  :enableDataExport="false"
                  :enableBulkSelect="true"
                  :showEditModal="false"
                  @bulkClickByIds="onSelect"
                  :columnTemplates="columnTemplates()"
                  :enableAdvancedSearch="true"
                  :columns="columns()"
                  ref="table"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AbstractService from '@services/AbstractService.js'
import TableColumnTemplate from '@constants/table-column-template.js'
import MultiSelectFilter from './multi-select-filters.vue'
import Draggable from 'vuedraggable'
import TableColumn from '@atoms/table/table-column.vue'

export default {
  components: { MultiSelectFilter, Draggable, TableColumn },
  data() {
    return {
      loading: false,
      valueLabel: this.value,
      showModal: false,
      disabled: false,
      selectedValues: [],
    }
  },
  props: {
    loadOnMount: {
      type: Boolean,
      default: () => true,
    },
    showField: {
      type: Boolean,
      default: () => true,
    },
    helpText: {
      type: String,
    },
    pageTypeId: {
      type: [Number, String],
    },
    parameters: {
      type: Object,
      default: () => {},
    },
    customColumnTemplates: {
      type: Object,
      default: () => {},
    },
    value: {},
    type: {
      type: String,
      default: 'page',
    },
    customLabel: {
      default: () => null,
    },
    showFilter: {
      type: Boolean,
      default: () => false,
    },
    draggable: {
      type: Boolean,
      default: () => true,
    },
    tableClass: {
      type: String,
      default: () => 'table',
    },
    tableWrapperClass: {
      type: String,
      default: () => '',
    },
  },
  mounted() {
    if (this.value && this.loadOnMount) {
      this.getValue()
    }
  },
  updated() {
    this.valueLabel = this.value
    if (!this.value) {
      this.selectedValues = []
    }
  },
  methods: {
    fixedPath(selected) {
      return this.modelPath() + '?' + this.buildQueryParams(selected)
    },
    show(event) {
      event && event.preventDefault()
      this.$nextTick(() => {
        this.showModal = true
        this.$nextTick(() => {
          this.$refs.modal.classList.add('show')
          document.getElementById('modal').appendChild(this.$refs.modal)
        })
      })
    },
    async getValue() {
      this.loading = true
      await AbstractService.get(this.fixedPath(true))
        .then((response) => {
          if (response.data.result.length > 0) {
            this.selectedValues = response.data.result
          } else {
            this.disabled = true
          }
        })
        .catch(() => {
          this.disabled = true
        })
        .finally(() => {
          this.loading = false
        })
    },
    modelPath() {
      if (this.type === 'top_list') {
        return 'hercules/toplists/lists'
      }
      return 'hercules/sites/pages/filter'
    },
    columns() {
      let columns = []
      if (this.type === 'top_list') {
        columns.push({ field: 'site_name', label: 'Site', type: 'text', filterable: true })
        columns.push({ field: 'market_name', label: 'Market', type: 'text', filterable: true })
        columns.push({ field: 'label', type: 'text', filterable: true })
        columns.push({ field: 'short_code', type: 'text', filterable: true })
        columns.push({ field: 'type', type: 'text', filterable: true })
      } else {
        columns.push({ field: 'title', type: 'text', filterable: true })
        columns.push({ field: 'path', type: 'text', filterable: true })
        columns.push({ field: 'type', type: 'text', filterable: true })
        columns.push({ field: 'market', type: 'text', filterable: true })
        columns.push({
          field: 'updated_at',
          type: 'text',
          filterable: true,
          template: TableColumnTemplate.RELATIVE_DATE,
        })
      }
      return columns
    },
    columnTemplates() {
      let columnTemplates = {
        template: function (row) {
          return row.template ? row.template.name : ''
        },
        title: function (row) {
          return row.title.substring(0, 30) + (row.title.length > 34 ? '...' : '')
        },
        type: function (row) {
          return row.type && row.type.replace('_', ' ')
        },
        market: function (row) {
          return row.market ? row.market.label : ''
        },
        market_name: function (row) {
          return row.market ? row.market.label : ''
        },
      }
      return Object.assign(columnTemplates, this.customColumnTemplates)
    },
    requestParameters(selected) {
      let params = {}
      params.with = 'template,market,site'

      // Already selected
      if (selected) {
        params.ids = this.valueLabel
      } else {
        if (this.valueLabel != null) {
          params.ids_not = this.valueLabel
        }
      }

      return Object.assign(params, this.parameters)
    },
    valueKeyName() {
      return 'name'
    },
    buildQueryParams(selected) {
      return Object.entries(this.requestParameters(selected))
        .map(([key, val]) => `${key}=${val}`)
        .join('&')
    },
    reset() {
      this.disabled = false
      this.valueLabel = ''
      this.$emit('valuePicked', '', 'No value selected')
    },
    onSelect(value) {
      this.valueLabel.length > 0
        ? (this.valueLabel += ',' + value.toString())
        : (this.valueLabel = value.toString())
      this.$emit('valuePicked', this.valueLabel)
      this.getValue()
      this.close()
    },
    onDrag() {
      this.valueLabel = this.selectedValues.map(({ id }) => id).toString()
      this.$emit('valuePicked', this.valueLabel)
    },
    close() {
      this.$refs.modal && this.$refs.modal.classList.remove('show')
      setTimeout(() => {
        this.showModal = false
      }, 100)
      this.$store.commit('multi-select-table/setSearchQuery', '')
      this.$store.commit('multi-select-table/setSelecteditems', [])
    },
    filterChanged(filters) {
      this.emitter.emit('multi-select-table.applyFilters', filters)
    },
    deleteItem(id) {
      this.valueLabel = this.valueLabel
        .split(',')
        .filter((item) => item !== id.toString())
        .join(',')
      this.$emit('valuePicked', this.valueLabel)
      this.valueLabel && this.getValue()
    },
  },
}
</script>

<style scoped>
.draggable.list-group-item:after,
[draggable]:not([draggable='false']).list-group-item:after {
  background: #989898;
}

.draggable.list-group-item {
  padding-left: 40px !important;
  border-bottom: 1px solid #ced0dd;
  border-bottom-right-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

h1 {
  color: #0860a0 !important;
}

.modal-xlarge .modal-dialog {
  max-width: 1300px;
}

.card-body .multi-select-modal-result-table th:first-of-type {
  padding-left: 18px;
}
</style>
