<template>
  <div v-if="value">
    <base-multiselect
      v-if="value['page_authors']"
      :path="authorsGetPath"
      :value="value['page_authors']"
      :multiple="true"
      field="page_authors"
      label="Authors"
      placeholder="Please select an author"
      @sync="(field, value) => valueChanged('page_authors', value)"
    />

    <base-multiselect
      v-if="value['page_reviewer']"
      :path="authorsGetPath"
      :value="value['page_reviewer']"
      :multiple="true"
      field="page_reviewers"
      label="Reviewer"
      placeholder="Please select a reviewer"
      @sync="(field, value) => valueChanged('page_reviewer', value)"
    />

    <base-multiselect
      v-if="value['page_categories'] && pageCategories.length > 0"
      :customOptions="pageCategories"
      :value="value['page_categories']"
      :multiple="true"
      field="page_categories"
      label="Page categories"
      placeholder="Please select a category"
      @sync="(field, value) => valueChanged('page_categories', value)"
    />

    <dynamic-filters
      v-if="pageType"
      :parameters="parameters"
      :modelValue="value"
      ref="dynamicFilters"
      :pageType="pageType"
      @update:modelValue="handleDynamicFilters"
    />
  </div>
</template>

<script>
import BaseMultiselect from '@/components/fields/base-multiselect.vue'
import DynamicFilters from '@/components/fields/dynamic-filters.vue'
import CategoryService from '@services/CategoryService.js'

export default {
  components: { BaseMultiselect, DynamicFilters },
  props: {
    value: {
      type: Object,
      default: () => {},
    },
    parameters: {
      type: Object,
      default: () => {},
    },
    pageType: {},
  },
  beforeMount() {
    let dataObject = this.data

    if (this.value === null || Object.keys(this.value).length === 0) {
      // set defaults for non page type specific fields (dynamic filters)
      dataObject = {
        page_authors: [],
        page_reviewer: [],
        page_categories: [],
      }
    }

    // author fields might not exist (based on an earlier implementation of the cards-v2 component)
    this.authorFields.forEach((authorField) => {
      if (!Object.hasOwn(dataObject, authorField)) {
        dataObject = { ...dataObject, ...{ [authorField]: [] } }
      }
    })

    this.data = dataObject
    this.getPageTypeCategoryFilters()
  },
  mounted() {
    if (this.$refs.dynamicFilters) {
      this.$refs.dynamicFilters.loadOptions()
    }
  },
  data() {
    return {
      pageCategories: [],
      authorFields: ['page_authors', 'page_reviewer'],
    }
  },
  computed: {
    data: {
      get() {
        return this.value
      },
      set(newValue) {
        this.$emit('change', newValue)
      },
    },
    authorsGetPath() {
      return `hercules/sites/authors?site_id=${this.parameters.site_id}fields_only[]=id&fields_only[]=name&orderBy=name&ascending=1`
    },
  },
  watch: {
    pageType: {
      handler() {
        // if nextTick is not used, the pageType is not correct (using old value)
        this.$nextTick(() => {
          this.getPageTypeCategoryFilters()
          if (this.$refs.dynamicFilters) {
            this.$refs.dynamicFilters.loadOptions()
          }
        })
      },
    },
  },
  methods: {
    handleDynamicFilters(value) {
      this.data = value
    },
    valueChanged(filter, value) {
      // merge value for given filter into existing data object
      this.data = { ...this.data, ...{ [filter]: value } }
    },
    getPageTypeCategoryFilters() {
      if (this.pageType === null) {
        return
      }
      CategoryService.get({
        site_id: this.parameters.site_id,
        market_id: this.parameters.market_id,
        match_market_language: 1,
        page_type_id: this.pageType.id,
        orderBy: 'name',
        ascending: 1,
      })
        .then((response) => {
          if (!this.value || !Object.hasOwn(this.value, 'page_categories')) {
            this.data = Object.assign({}, this.value, { ['page_categories']: [] })
          }
          this.pageCategories = response.data.result
        })
        .catch(() => {
          this.showErrorMessage('Failed to get page type categories')
        })
    },
  },
}
</script>
