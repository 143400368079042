<template>
  <div>
    <div class="row">
      <div class="col">
        <div class="card">
          <div class="card-header">
            <h3>
              Permissions for
              <strong>{{ role ? role.name : null }}</strong>
            </h3>
            <div class="ml-auto">
              <button class="btn btn-secondary mr-2" @click="toggleAllPermission(false)">
                <i class="uil uil-circle"></i>
                Deselect all
              </button>
              <button class="btn btn-secondary" @click="toggleAllPermission(true)">
                <i class="uil uil-check-circle"></i>
                Select all
              </button>
              <button
                class="btn btn-primary ml-2"
                :disabled="loading"
                @click="update"
                v-bind:class="{ loading: loading }"
              >
                <i class="uil uil-check"></i>
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row" v-if="role">
      <div class="col">
        <div class="card">
          <div class="card-header">
            <h3>
              Role labels for role
              <strong>{{ role ? role.name : null }}</strong>
            </h3>
          </div>
          <div class="card-body">
            <base-multiselect
              ref="roleLabelSelect"
              :multiple="true"
              field="labels"
              label="Role Labels"
              selectLabel="name"
              :required="true"
              trackByOption="id"
              :path="`backoffice/labels`"
              :value="roleLabels"
              class="mb-0 w-50"
              @sync="syncLabels"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="row" v-if="!loading && role">
      <div class="col-2 embedded-sidebar">
        <label>Filter by group</label>
        <span class="ml-1 clickable" v-if="filteredGroup" @click="filteredGroup = null">clear</span>
        <ul class="list-group mb-3">
          <li
            v-for="(subGroups, group) in role.grouped_permissions || []"
            v-bind:key="group"
            @click="filteredGroup = filteredGroup === group ? null : group"
            class="list-group-item"
            v-bind:class="{ active: filteredGroup === group }"
          >
            {{ $capitalize(group) }}
          </li>
        </ul>
      </div>
      <div class="col-10 overflow-auto" id="permissions-list">
        <div class="row" v-for="(subGroups, group) in visiblePermissions || []" v-bind:key="group">
          <div class="col">
            <div class="card pb-4">
              <div class="card-body pb-0">
                <h4></h4>
                <h4>
                  <strong>{{ $capitalize(group) }}</strong>
                </h4>
                <div
                  class="d-flex py-3 align-items-center border-bottom"
                  v-for="(permissions, subGroup) in subGroups"
                  v-bind:key="subGroup"
                >
                  <div class="col d-flex">
                    <h6 class="mb-0 align-self-center">{{ $prettyLabels(subGroup) }}</h6>
                  </div>
                  <div class="col" v-for="(permission, index) in permissions" v-bind:key="index">
                    <base-checkbox
                      v-model="permission.active"
                      :label="getPermissionLabel(permission.name)"
                      :wrapperClass="null"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="role.name === 'user manager'" class="col-10 overflow-auto">
          <h3 class="mb-0 align-self-center">Assign role</h3>
          <ul class="d-flex flex-wrap pl-0">
            <li v-for="role in roles || []" :key="role.id" class="list-group-item mr-2">
              <base-checkbox
                :label="`${role.name}`"
                class="mr-3 mb-0"
                :value="isRoleSelected(role.id)"
                @input="inputPermission(role.id)"
              />
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import RolesService from '@services/RolesService.js'
import BaseCheckbox from '@atoms/fields/base-checkbox.vue'
import BaseMultiselect from '@/components/fields/base-multiselect.vue'

export default {
  components: { BaseCheckbox, BaseMultiselect },
  data() {
    return {
      role: null,
      filteredGroup: null,
      checked: null,
      loading: false,
      addPermission: [],
      roles: [],
      roleLabels: null,
    }
  },
  async mounted() {
    await this.getRole()
    await this.getRoles()
  },
  computed: {
    visiblePermissions() {
      if (this.filteredGroup && this.role) {
        let permissions = {}
        permissions[this.filteredGroup] = this.role?.grouped_permissions[this.filteredGroup]
        return permissions
      }
      return this.role.grouped_permissions
    },
  },
  methods: {
    syncLabels(_field, value, key) {
      let newValue = null
      if (value && Array.isArray(value)) {
        newValue = value.map((item) => item[key])
      }
      this.roleLabels = newValue
    },
    isRoleSelected(id) {
      return this.addPermission && this.addPermission.includes(id)
    },
    inputPermission(value) {
      let findIndex = this.addPermission && this.addPermission.findIndex((item) => item == value)
      if (findIndex !== -1) {
        this.addPermission.splice(findIndex, 1)
      } else {
        this.addPermission.push(value)
      }
      this.role.addPermission = this.addPermission
    },
    updatePermission(value) {
      let findIndex = this.addPermission && this.addPermission.findIndex((item) => item == value)
      if (findIndex !== -1) {
        this.addPermission.splice(findIndex, 1)
      } else {
        this.addPermission.push(value)
      }
      this.role.addPermission = this.addPermission
    },
    async getRole() {
      this.loading = true
      await RolesService.getOne(this.$route.params.id)
        .then((response) => {
          this.role = response.data.result[0]
          this.addPermission = this.role.permissions_for_user_manager.map((item) => item.allowed_role_id)
          this.roleLabels = response.data.result[0].labels.map((label) => label.id)
        })
        .catch(this.showUnknownErrorMessage)
        .finally(() => {
          this.loading = false
        })
    },
    async getRoles() {
      if (this.$route.params.id == 10) {
        this.loading = true
        await this.$http
          .get(`/backoffice/roles`, {
            params: { exclude_admin: 1 },
          })
          .then((response) => {
            this.roles = response.data.result
          })
          .catch(this.showUnknownErrorMessage)
          .finally(() => {
            this.loading = false
          })
      }
    },
    async update() {
      this.loading = true
      this.role.labels = this.roleLabels

      await RolesService.update(this.role)
        .then(async () => {
          // Re-fetch the role to get updated permissions
          await this.getRole()
          this.showSuccessMessage('Role updated')
        })
        .catch(this.showUnknownErrorMessage)
        .finally(() => {
          this.loading = false
        })
    },
    toggleAllPermission(active) {
      Object.keys(this.visiblePermissions).forEach((groupKey) => {
        Object.keys(this.visiblePermissions[groupKey]).forEach((subGroupKey) => {
          this.visiblePermissions[groupKey][subGroupKey].forEach((item) => (item.active = active))
        })
      })
    },
    getPermissionLabel(permission) {
      const label = permission.split('.')[0]
      if (label === 'misc') {
        return 'Enabled'
      } else if (label === 'put') {
        return 'Update'
      } else if (label === 'post') {
        return 'Create'
      }
      return label
    },
    getGroupLabel(name) {
      return name.split('/')[1]
    },
  },
}
</script>

<style scoped>
#permissions-list {
  max-height: calc(100vh - 200px);
  border-bottom: 1px solid #cfd8dc;
}

.user-management-permission {
  width: fit-content;
}
</style>
