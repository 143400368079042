import axios from 'axios'
import VueAxios from 'vue-axios'
import { store } from '@store/index'

axios.defaults.baseURL = import.meta.env.VITE_APP_SERVER_SIDE
axios.defaults.headers['pusherToken'] = store.state.app.uniqueKey
axios.defaults.withCredentials = true

export default {
  install: (app) => {
    app.use(VueAxios, axios)
    app.config.globalProperties.$http = axios
  },
  root: import.meta.env.VITE_APP_SERVER_SIDE,
}
