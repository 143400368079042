const state = () => ({
  model: null,
  isLoading: true,
  cmsSiteId: null,
  modules: [],
  standardiseLogoUrl: null,
  marketId: null,
})

const mutations = {
  setMarketId(state, value) {
    state.marketId = value
  },
  setStandardiseLogoUrl(state, value) {
    state.standardiseLogoUrl = value
  },
  setModel(state, value) {
    state.model = value
  },
  setIsLoading(state, value) {
    state.isLoading = value
  },
  setSiteId(state, value) {
    state.cmsSiteId = value
  },
  setModules(state, module) {
    const existingModule = state.modules.find((mod) => mod.id === module.id)

    if (existingModule) {
      existingModule.removed = module.removed
    } else {
      state.modules.push(module)
    }
  },
  TOGGLE_SOFT_DELETE(state, moduleId) {
    const module = state.modules.find((mod) => mod.id === moduleId)
    if (module) {
      module.removed = !module.removed
    }
  },
}

export default {
  namespaced: true,
  state,
  mutations,
}
