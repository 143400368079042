<template>
  <div v-if="menu">
    <div class="mb-2 mt-2">
      <colaborative-edit-notification
        v-if="showColaborativeNotification"
        :route_path="$route.fullPath"
        :username="username"
      />
    </div>
    <div class="row">
      <div class="col-6">
        <div class="card">
          <div class="card-header border-bottom d-flex justify-content-between align-items-center">
            <div class="justify-content-start align-self-center">
              <strong>Menu items</strong>
            </div>
            <div class="text-right">
              <span @click="openModalInformation" class="btn btn-outline-info btn-sm mr-2">
                <i class="uil uil-info-circle"></i>Help
              </span>
              <span class="btn btn-primary btn-sm clickable" @click="addItem">+ Add</span>
            </div>
          </div>
          <div class="card-body pt-3" id="menu-items-wrapper" @click="handleWrapperClick">
            <Draggable class="mtl-tree" v-model="menuItems" :key="reRenderMenu">
              <template #default="{ node, stat }">
                <div
                  v-if="stat.children.length"
                  :open="stat.open"
                  class="btn btn-secondary btn-sm icon-button mrs ml-2"
                  @click.native="stat.open = !stat.open"
                >
                  <i :class="`uil ${stat.open ? 'uil-minus' : 'uil-plus'} icon-xs text-muted node-icon`"></i>
                </div>

                <div class="mtl-ml w-100 p-2 cursor-pointer d-flex" @click="setActiveNode(node)">
                  <div :class="{ 'text-dark': activeNode === node }">
                    {{ getNodeLabel(node) }}
                  </div>
                  <div class="ml-3">
                    <span :class="badgeStyle(node.type)">{{ node.type }}</span>
                  </div>
                </div>
              </template>
            </Draggable>
          </div>
        </div>
      </div>
      <div class="col-6" @click="handleWrapperClick">
        <menu-item-edit
          ref="menuItemEdit"
          v-if="activeNode"
          :menu-item="activeNode"
          @delete="remove"
          @updateItem="updateItem"
          :marketId="menu.market_id"
          :siteId="menu.site_id"
          :showColaborativeNotification="showColaborativeNotification"
        />
      </div>
    </div>
    <information-modal ref="informationModal" :id="confluenceId" />
  </div>
</template>
<script>
import '@he-tree/vue/style/default.css'
import MenuItemEdit from '@molecules/cms/menus/edit/item-edit.vue'
import MenusService from '@services/MenusService.js'
import InformationModal from '@molecules/cms/modals/information-modal.vue'
import ColaborativeEditNotification from '@molecules/colaborative-edit-notification.vue'
import confluenceIds from '@constants/confluence-ids.js'
import { BaseTree, Draggable, pro, OpenIcon } from '@he-tree/vue'
import '@he-tree/vue/style/default.css'
import '@he-tree/vue/style/material-design.css'
import ConfirmDialog from '@atoms/misc/confirm-dialog.vue'
import { openDialog } from 'vue3-promise-dialog'

const confirmDelete = async (title, content) => {
  return await openDialog(ConfirmDialog, { title, content })
}

export default {
  components: {
    Draggable,
    MenuItemEdit,
    InformationModal,
    ColaborativeEditNotification,
  },
  mixins: [],
  data() {
    return {
      activeNode: null,
      activePath: null,
      dragging: false,
      menu: null,
      menuItems: null,
      confluenceId: 0,
      showColaborativeNotification: false,
      modelName: 'sites-menus',
      username: '',
      reRenderMenu: 0,
      submitted: true,
    }
  },
  beforeRouteLeave(to, from) {
    if (!this.submitted) {
      if (!confirm('Are you sure you want to leave page.')) {
        return false
      }
    }
  },
  computed: {
    menuModel() {
      return { ...this.$store.state.editPage.model }
    },
  },
  created() {
    this.confluenceId = confluenceIds.menus.items.id
  },
  mounted() {
    this.getMenu()

    let pusherChannel = window.pusher.subscribe('updateModelChannel')

    pusherChannel.bind('pusher:subscription_succeeded', function () {})

    pusherChannel.bind('updateModel', (message) => {
      if (
        message.modelName === this.modelName &&
        message.modelId === Number(this.menu.id) &&
        message.pusherToken !== this.$store.state.app.uniqueKey
      ) {
        this.showColaborativeNotification = true
        this.username = message.username
      }
    })
    this.setTitle(`${this.$parent.$parent.title ? this.$parent.$parent.title + ':' : ''} Items`)
  },
  methods: {
    handleWrapperClick() {
      this.submitted = false
    },
    badgeStyle(type) {
      switch (type) {
        case 'page':
          return 'badge badge-light'
        case 'label':
          return 'badge badge-success'
        case 'link':
          return 'badge badge-info'
        default:
          return 'badge badge-light'
      }
    },
    setActiveNode(node) {
      this.activeNode = node
    },
    updateItem(updatedMenu) {
      this.menu = updatedMenu
      this.reRenderMenu += 1
    },
    openModalInformation() {
      this.$refs.informationModal.show()
    },
    setMenuFromStore() {
      this.menu = this.menuModel
      this.menuItems = Array.isArray(this.menu.children) ? this.menu.children : []

      if (this.menuItems.length > 0) {
        this.activeNode = this.menuItems[0]
      } else {
        this.activeNode = null
      }
    },
    getMenu() {
      if (this.menuModel) {
        this.setMenuFromStore()
        return
      }
      MenusService.get({
        id: this.$route.params.id,
        with: ['children.children', 'site', 'market'].join(),
      })
        .then((response) => {
          if (response.data.messages) {
            this.showErrorMessages(response.data.messages[0])
            this.$router.push('/site-menus')
          } else {
            this.menu = response.data.result[0]
            this.menuItems = response.data.result[0].children
            this.activeNode = this.menuItems[0]
          }
        })
        // .catch(this.showUnknownErrorMessage)
        .catch((error) => {
          console.log(error)
        })
    },
    async remove() {
      if (this.showColaborativeNotification) {
        return
      }
      if (await confirmDelete('Warning', 'Are you sure you want to delete this item?')) {
        const removeNode = (items, nodeId) => {
          const findNode = items.findIndex((item) => item.id === nodeId)
          if (findNode !== -1) {
            items.splice(findNode, 1)
            return true
          }

          // Recursively search in children
          // eslint-disable-next-line no-unused-vars
          for (const item of items) {
            if (item.children && item.children.length > 0) {
              const foundInChildren = removeNode(item.children, nodeId)
              if (foundInChildren) {
                return true
              }
            }
          }
          return false
        }

        const nodeRemoved = removeNode(this.menuItems, this.activeNode.id)
        this.$refs.menuItemEdit.update()

        if (nodeRemoved) {
          this.reRenderMenu += 1
          this.activeNode = this.menuItems[0] || null
        }
      }
    },
    addItem() {
      const newItem = {
        id: 0,
        text: null,
        page_title: null,
        value: null,
        type: 'page',
        label: null,
        nofollow: false,
        open_in_new_tab: false,
        image: null,
        image_id: null,
        parent_id: null,
        children: [],
      }
      if (this.menu.children.length === 0) {
        this.menu.children.push(newItem)
        this.setActiveNode(newItem)
        return
      }
      this.menu.children.push(newItem)
      this.setActiveNode(newItem)
      this.reRenderMenu += 1
    },
    getNodeLabel(node) {
      if (node.type === 'label') {
        return node.text
      }
      if (!node.value) {
        return 'No value selected'
      }
      if (node.type === 'page') {
        return node.text ? node.text : node.page_title
      }
      return node.text
    },
  },
}
</script>
<style scoped>
#menu-items-wrapper {
  max-height: calc(100vh - 320px);
  overflow-y: auto;
}
</style>
<style>
.tree-node-inner {
  color: #607d8b;
  font-weight: 600;
  height: 46px;
  line-height: 46px;
  margin-bottom: -1px;
  background-color: #fff;
  border: 1px solid #cfd8dc;
  a {
    color: #607d8b;
    font-weight: 600;
    height: 46px;
    line-height: 46px;
    margin-bottom: -1px;
    background-color: #fff;
    border: 1px solid #cfd8dc;
  }
}
.tree-node-inner:not(:has(> a)) {
  margin-left: 0.9rem;
}

.mtl-tree .tree-node:hover {
  background-color: unset;
}

.mtl-tree .tree-node .tree-node-inner:hover {
  color: #17182f;
}
</style>
