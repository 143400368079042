<template>
  <div class="mb-2">
    <div class="form-group" :name="label && label && slugify(label)">
      <label>{{ $prettyLabels(label) }}</label>
      <span v-if="required">*</span>
      <div class="d-inline ml-2" v-if="!readOnly">
        <a v-if="isAssetLoaded" class="text-muted clickable" @click="clear">clear</a>
      </div>
      <div class="images d-flex">
        <div
          class="image-container"
          v-bind:class="{ extraPadding: extraPadding }"
          v-if="isAssetLoaded"
          @click="openMediaLibrary(true)"
          :style="{ cursor: readOnly ? 'not-allowed' : 'pointer' }"
          @mouseover="hovered = true"
          @mouseout="hovered = false"
        >
          <i class="uil uil-arrows-h-alt" v-if="!readOnly"></i>
          <base-asset
            :style="{ opacity: getOpacityValue(readOnly, hovered) }"
            :asset="selectedAsset"
            minWidth="200px"
            width="auto"
            height="auto"
          />
          <div class="asset-size">
            <span>{{ sizeConverter(size) }}</span>
          </div>
          <div class="asset-details">
            <p class="mb-0">{{ selectedAsset.raw_filename }}</p>
          </div>
        </div>
      </div>
      <button
        class="btn btn-secondary d-block"
        v-if="!isAssetLoaded"
        type="button"
        @click="openMediaLibrary"
        :disabled="readOnly"
      >
        Select image
      </button>
      <small v-if="error" class="text-danger form-text">
        Image <strong>{{ modelValue }}</strong> not found.
      </small>
      <small v-if="helpText" class="form-text text-muted">{{ helpText }}</small>
    </div>
    <media-library v-if="showMediaLibrary" @close="closeMediaLibrary" v-model="selectedAsset" ref="library" />
  </div>
</template>

<script>
import AssetsService from '@services/AssetsService.js'
import MediaLibrary from '@organisms/media-library/assets.vue'
import BaseAsset from '@atoms/fields/base-asset.vue'

export default {
  components: { BaseAsset, MediaLibrary },
  mounted() {
    if (this.modelValue) {
      this.getAsset()
    }
  },
  data() {
    return {
      selectedAsset: null,
      showMediaLibrary: false,
      error: false,
      uid: this.generateUID(),
      hovered: false,
    }
  },
  props: {
    helpText: {
      type: String,
    },
    label: {
      type: String,
    },
    modelValue: {
      type: [Number, String],
      default: () => null,
    },
    required: {
      type: Boolean,
    },
    standardised: {
      type: Boolean,
      default: false,
    },
    siteSpecific: {
      type: Boolean,
      default: false,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    modelValue: function (newValue) {
      if (newValue) {
        this.getAsset()
      } else {
        this.selectedAsset = null
      }
    },
    selectedAsset: function () {
      this.$emit('update:modelValue', this.selectedAsset ? this.selectedAsset.id : null)
    },
  },
  computed: {
    extraPadding() {
      return this.selectedAsset && this.selectedAsset.height < 40
    },
    size() {
      return this.selectedAsset && this.selectedAsset.size
    },
    isAssetLoaded() {
      return this.selectedAsset
    },
    loadedAssets() {
      return this.$store.state.media.loadedAssetsArray
    },
  },
  unmounted() {
    this.$store.dispatch('media/setActiveMediaLib', { uid: null, asset: null })
    this.$store.dispatch('media/setVisibility', false)
  },
  methods: {
    getOpacityValue(readOnly, hovered) {
      if (hovered && !readOnly) {
        return 0.1
      }
      return 1
    },
    async getAsset() {
      // check if media is already loaded from modules
      if (this.loadedAssets.findIndex((value) => value.id == this.modelValue) !== -1) {
        this.selectedAsset = this.loadedAssets.find((value) => value.id == this.modelValue)
        return
      }

      await AssetsService.getOne(this.modelValue, { with: 'site,market' }).then((response) => {
        if (response.data.result && response.data.result.length === 1) {
          this.selectedAsset = response.data.result[0]
          this.$store.dispatch('media/setLoadedAssetsArray', this.selectedAsset)
        } else {
          this.error = true
        }
      })
    },
    openMediaLibrary(replace) {
      this.showMediaLibrary = true
      this.$nextTick(() => {
        this.$refs.library.show(this.selectedAsset)
      })

      if (this.readOnly) return
      this.error = false
      const asset = replace && this.selectedAsset ? this.selectedAsset : null
      this.$store.dispatch('media/setStandardised', this.standardised)
      this.$store.dispatch('media/setSiteSpecific', this.siteSpecific)
      this.$store.dispatch('media/setActiveMediaLib', { uid: this.uid, asset: asset })
      this.$store.dispatch('media/setVisibility', true)
    },
    closeMediaLibrary() {
      this.showMediaLibrary = false
      this.$store.dispatch('media/setVisibility', false)
    },
    clear() {
      this.selectedAsset = null
      this.$store.dispatch('media/setActiveMediaLib', { uid: this.uid, asset: null })
    },
  },
}
</script>

<style scoped>
.image-container {
  position: relative;
  cursor: pointer;
  height: auto;
  width: auto;
  min-width: 200px;
  min-height: 100px;
}

.image-container.extraPadding img {
  padding: 50px;
}

.image-container img {
  max-height: 150px;
}

.image-container i {
  display: none;
}

.image-container:hover i {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  border-radius: 5px;
}

.asset-details {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, -5px);
  background-color: white;
  opacity: 0.6;
  padding: 5px;
  color: black;
  width: 95%;
}

.asset-size {
  position: absolute;
  top: 6px;
  right: 6px;
  background-color: white;
  opacity: 0.6;
  padding: 2px 5px;
}

.asset-details > p {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
