<template>
  <div v-if="widgetsList">
    <div class="row">
      <div class="col">
        <h3>
          <router-link
            :to="{ path: '/site-widgets-odds' }"
            data-tooltip="Back to widgets"
            data-tooltip-position="right center"
          >
            <i class="uil uil-angle-left"></i>
          </router-link>
          <span class="mr-2 ml-2"> {{ widgetsList.title }} </span>
          <span class="badge bg-purple" v-if="widgetsList.geo_located">Geolocated</span>
          <span class="badge bg-blue-50 ml-2 clickable" @click="copyValue(widgetsList.token)">{{
            widgetsList.token
          }}</span>
        </h3>
        <div class="additional mb-2">
          <span>Last updated: {{ $dateRelative(widgetsList.updated_at) }}</span>
        </div>
      </div>
    </div>
    <ul class="nav nav-tabs mb-3">
      <router-link tag="li" :to="{ path: `/site-widgets-odds/${widgetsList.id}` }" exact>
        <a>General</a>
      </router-link>
      <router-link
        tag="li"
        v-if="widgetsList.geo_located"
        :to="{ path: `/site-widgets-odds/${widgetsList.id}/toplists` }"
      >
        <a>Toplists</a>
      </router-link>
    </ul>
    <router-view></router-view>
  </div>
</template>

<script>
import WidgetOddsService from '@services/WidgetOddsService.js'

export default {
  data() {
    return {
      widgetsList: null,
    }
  },
  created() {
    this.setTitle()
    this.getWidget()
  },
  methods: {
    copyValue(value) {
      navigator.clipboard
        .writeText(value)
        .then(() => {
          this.showSuccessMessage('Copied to your clipboard.')
        })
        .catch((err) => {
          this.showErrorMessage('Failed to copy text: ', err)
        })
    },
    getWidget() {
      WidgetOddsService.getOne(this.$route.params['id'], { with: 'market' })
        .then((response) => {
          if (response.data.messages) {
            this.showErrorMessages(response.data.messages)
          } else {
            this.widgetsList = response.data.result[0]
            this.title = this.widgetsList?.title
          }
        })
        .catch(this.showUnknownErrorMessage)
    },
  },
}
</script>
