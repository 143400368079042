<template>
  <div>
    <data-table
      model="widgets/toplist/toplistItem"
      service-file="WidgetsToplistItemsService"
      :path="path()"
      :columns="columns()"
      :column-templates="columnTemplates()"
      :create-defaults="createDefaults()"
      :serviceParams="[$parent.$parent.widgetsList.market, $parent.$parent.widgetsList.site_id]"
    />
  </div>
</template>
<script lang="jsx">
import TableColumnTemplate from '@constants/table-column-template.js'

export default {
  mounted() {
    this.setTitle(
      `${this.$parent.$parent.title ? this.$parent.$parent.title + ':' : ''} ${this.$route.meta.name}`
    )
  },
  beforeMount() {
    this.$parent.$parent.widgetsList.geo_located &&
      this.$router.push(`/site-widgets-toplist/${this.$parent.$parent.widgetsList.id}`)
  },
  methods: {
    columns() {
      return [
        { field: 'label', type: 'text', filterable: true },
        { field: 'toplist_name', type: 'text', label: 'Toplist', filterable: true },
        { field: 'updated_at', type: 'text', filterable: true, template: TableColumnTemplate.RELATIVE_DATE },
      ]
    },
    columnTemplates() {
      return {
        toplist_name: function (row) {
          return (
            <div>
              <span>{row.toplist_name}</span>
              {row.toplist_name && (
                <a
                  class="p-2 ml-2 font-size-sm text-muted clickable"
                  href={`/toplists/${row.toplist_id}`}
                  target="_blank"
                >
                  Edit list
                  <i class="uil uil-link ml-2 icon-xs"></i>
                </a>
              )}
            </div>
          )
        },
      }
    },
    path() {
      return `hercules/widgets/toplist-items?widget_toplists_id=${this.$route.params['id']}&is_geo_located=0&with=toplist,widgett&orderBy=updated_at`
    },
    createDefaults() {
      return {
        is_geo_located: 0,
        widget_toplists_id: parseInt(this.$route.params['id']),
        num_items_initial_load: 0,
        num_items_load_more: 0,
      }
    },
  },
}
</script>
