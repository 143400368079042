<template>
  <div class="modal" ref="modal" tabindex="-1" role="dialog" v-click-outside="close">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header pb-4">
          <h5 class="modal-title">{{ clonedPage ? 'Page cloned' : 'Clone page' }}</h5>
          <button class="close" v-on:click="close"></button>
        </div>
        <div class="modal-body">
          <div v-if="clonedPage === null">
            <div class="text-muted">
              <p>Before cloning, please read the following:</p>
              <ul>
                <li>Any unsaved changes will not be copied</li>
                <li>
                  Site or market specific module fields cannot be copied (if the target site or market is
                  different)
                </li>
              </ul>
            </div>
            <dynamic-form
              form-type="edit"
              v-if="object"
              ref="form"
              :formFields="structure.list"
              path="sites/sites"
              :structure="structure"
              :object="object"
              :show-submit="false"
              :on-submit="submit"
              :closeOnDestroy="false"
            />
            <page-type-compare
              :difference="pageTypeDifference"
              warning="Cloning to a different page type is possible but please note the following warnings"
            />
            <div class="text-right">
              <button
                class="btn btn-primary"
                :class="{ loading: submitLoading }"
                @click="$refs.form.validateBeforeSubmit()"
              >
                <i class="uil uil-check"></i> Clone page
              </button>
            </div>
          </div>
          <div v-if="clonedPage">
            <p>The page was cloned <strong>successfully</strong> and can be accessed via the following URL</p>
            <a :href="getCloneUrl()" target="_blank">{{ getCloneUrl() }}</a>
            <div class="text-muted mt-3">(The link opens in a new tab)</div>
            <div class="text-right">
              <button class="btn btn-secondary" @click="close"><i class="uil uil-check"></i>Close</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PagesService from '@services/PagesService.js'
import TemplatesService from '@services/TemplatesService.js'
import DynamicForm from '@/components/fields/DynamicForm.vue'
import PageClone from '@models/cms/PageClone.js'
import PageTypeCompare from '@atoms/cms/pages/type-compare.vue'

export default {
  components: { DynamicForm, PageTypeCompare },
  data() {
    return {
      loading: false,
      submitLoading: false,
      object: {},
      clonedPage: null,
      pageTypeDifference: null,
      structure: {},
      config: PageClone.getConfig(),
    }
  },
  watch: {
    object: {
      handler(newValue) {
        if (!newValue || newValue.site_id === null || newValue.target_page_type_id === null) {
          this.pageTypeDifference = null
          return
        }
        const newPageTypeId = newValue.target_page_type_id
        if (newPageTypeId !== null && newPageTypeId !== this.object.template_id) {
          this.checkPageTypeDifference()
        }
      },
      deep: true,
    },
  },
  mounted() {
    document.getElementById('modal').appendChild(this.$el)
  },
  methods: {
    show(object) {
      let objectClone = Object.assign({}, object)
      objectClone.target_page_path = `${object.path}-clone-${Date.now()}`
      objectClone.title = object.title + ' CLONE'
      objectClone.source_page_id = object.id
      objectClone.target_page_type_id = object.template_id
      objectClone.target_site_market_id = object.site_market_id
      objectClone.status = 'inactive'
      this.object = objectClone
      this.structure = PageClone.getStructure()
      this.$refs.modal.classList.add('show')
    },
    close() {
      this.$refs.modal && this.$refs.modal.classList.remove('show')
      this.object = null
      this.clonedPage = null
    },
    getCloneUrl() {
      return window.location.origin + '/site-pages/' + this.clonedPage.id
    },
    async submit() {
      this.submitLoading = true
      await PagesService.clone(this.object)
        .then((response) => {
          if (response.data.messages) {
            this.showErrorMessages(response.data.messages)
          } else {
            this.clonedPage = response.data.result
          }
          this.$refs.form.loading(false)
        })
        .catch(() => {
          this.showUnknownErrorMessage
          this.$refs.form.loading(false)
        })
        .finally(() => {
          this.submitLoading = false
        })
    },
    async checkPageTypeDifference() {
      this.submitLoading = true
      await TemplatesService.compare({
        source_page_id: this.object.source_page_id,
        target_page_type_id: this.object.target_page_type_id,
      })
        .then((response) => {
          this.pageTypeDifference = response.data.result
        })
        .catch(() => {
          this.showUnknownErrorMessage
        })
        .finally(() => {
          this.submitLoading = false
        })
    },
  },
}
</script>
