import Api from './Api'

export default {
  getAll(data) {
    return Api().get('hercules/operators/operators', { params: data })
  },
  post(data) {
    return Api().post(`hercules/operators/operators`, data)
  },
  remove(data) {
    return Api().delete(`hercules/operators/operators/${data.id}`)
  },
  update(data) {
    return Api().put(`hercules/operators/operators/${data.id}`, data)
  },
  getOne(id, data) {
    return Api().get(`hercules/operators/operators/${id}`, {
      params: data,
    })
  },
  getOperatorsByProvider(id) {
    return Api().get(`hercules/operators/operators?provider_id=${id}`)
  },
  getOperatorsByDepositMethod(id) {
    return Api().get(`hercules/operators/operators?deposit_method_id=${id}`)
  },
}
