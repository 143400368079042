<template>
  <div v-if="cryptoWallet">
    <div class="row">
      <div class="col">
        <h3>
          <router-link
            :to="{ path: `/crypto-wallets` }"
            data-tooltip="Back to crypto wallets"
            data-tooltip-position="right center"
          >
            <i class="uil uil-angle-left"></i>
          </router-link>
          <span class="mr-3 ml-2">{{ cryptoWallet.name }}</span>
        </h3>
        <div class="additional mb-2">
          <span>Last updated: {{ $dateRelative(cryptoWallet.updated_at) }}</span>
        </div>
      </div>
    </div>
    <ul class="nav nav-tabs mb-3">
      <router-link tag="li" :to="{ path: `/crypto-wallets/${cryptoWallet.id}` }" exact>
        <a>General</a>
      </router-link>
    </ul>
    <router-view></router-view>
  </div>
</template>

<script>
import CryptoWalletsService from '@services/CryptoWalletsService.js'

export default {
  data() {
    return {
      cryptoWallet: null,
      title: '',
    }
  },
  mounted() {
    this.getCryptoWallet()
  },
  methods: {
    getCryptoWallet() {
      CryptoWalletsService.getOne(this.$route.params['id'], {})
        .then((response) => {
          if (response.data.messages) {
            this.showErrorMessages(response.data.messages)
            response.data.messages.some((message) => {
              if (message.indexOf('Wallet not found') >= 0) {
                this.$router.push('/crypto-wallets')
              }
            })
          } else {
            this.cryptoWallet = response.data.result[0]
            this.title = response.data.result[0].name
          }
        })
        .catch(this.showUnknownErrorMessage)
    },
  },
}
</script>
