<template>
  <div>
    <data-table
      model="GameMarket"
      service-file="GamesMarketService"
      :serviceParams="[game_id]"
      :path="path()"
      :columns="columns()"
      :create-defaults="createDefaults()"
      :column-templates="columnTemplates()"
    />
  </div>
</template>

<script>
import TableColumnTemplate from '@constants/table-column-template.js'

export default {
  created() {
    this.setTitle(
      `${this.$parent.$parent.title ? this.$parent.$parent.title + ':' : ''} ${this.$route.meta.name}`
    )
  },
  data() {
    return {
      game_id: this.$route.params['id'],
    }
  },
  mounted() {},
  methods: {
    columns() {
      return [
        { field: 'market', type: 'text', filterable: true },
        { field: 'iframe', type: 'text', filterable: true, label: 'Game Iframe' },
        { field: 'average_rating', type: 'text', filterable: true },
        { field: 'updated_at', type: 'text', filterable: true, template: TableColumnTemplate.RELATIVE_DATE },
      ]
    },
    path() {
      return `hercules/games/game-markets?with=market&game_id=${this.$route.params['id']}`
    },
    columnTemplates() {
      return Object.assign({
        market: function (row) {
          return row.market?.label
        },
      })
    },
    createDefaults() {
      return {
        game_id: this.$route.params['id'],
      }
    },
  },
}
</script>
