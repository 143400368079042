<template>
  <data-table model="Unit" service-file="UnitsService" path="hercules/operators/units" :columns="columns()" />
</template>

<script>
import TableColumnTemplate from '@constants/table-column-template.js'

export default {
  created() {
    this.setTitle()
  },
  methods: {
    columns() {
      return [
        { field: 'name', type: 'text', filterable: true },
        { field: 'short_name', type: 'text', filterable: false },
        { field: 'description', type: 'text', filterable: false },
        { field: 'updated_at', type: 'text', filterable: true, template: TableColumnTemplate.RELATIVE_DATE },
      ]
    },
  },
}
</script>
