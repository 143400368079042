<template>
  <data-table
    model="sport/Provider"
    service-file="ProvidersService"
    path="hercules/sports/providers"
    :columns="columns()"
    :show-remove="false"
    :show-create="false"
  />
</template>

<script>
import TableColumnTemplate from '@constants/table-column-template.js'

export default {
  created() {
    this.setTitle()
  },
  methods: {
    columns() {
      return [
        { field: 'label', type: 'text', label: 'name', filterable: true },
        { field: 'video_quality', type: 'text', filterable: true },
        { field: 'video_size', type: 'text', filterable: true },
        { field: 'updated_at', type: 'text', filterable: false, template: TableColumnTemplate.RELATIVE_DATE },
      ]
    },
  },
}
</script>
