<template>
  <data-table
    service-file="AffiliateLinkedMarketsService"
    model="AffiliateLinkedMarket"
    :path="path()"
    ref="list"
    :columns="columns()"
    :column-templates="columnTemplates()"
    :create-defaults="createDefaults()"
    :serviceParams="[affiliate_id]"
  />
</template>

<script>
import TableColumnTemplate from '@constants/table-column-template.js'

export default {
  created() {
    this.setTitle(
      `${
        this.$store.state.editPage.model.website ? this.$store.state.editPage.model.website + ':' : ''
      } Linked Markets`
    )
  },
  data() {
    return {
      affiliate_id: this.$route.params['id'],
    }
  },
  methods: {
    columns() {
      return [
        { field: 'group_name', type: 'text', filterable: true },
        { field: 'linked_markets', type: 'text', filterable: true },
        { field: 'updated_at', type: 'text', filterable: true, template: TableColumnTemplate.RELATIVE_DATE },
      ]
    },
    path() {
      return `hercules/operators/affiliate-linked-markets?affiliate_id=${this.$route.params['id']}&with=market,linked_markets,default_market`
    },
    createDefaults() {
      return {
        affiliate_id: this.$route.params['id'],
      }
    },
    columnTemplates() {
      return Object.assign(
        {
          linked_markets: function (row) {
            return (
              row.linked_markets
                .map((item) => {
                  return item.label
                })
                .join(', ')
                .substring(0, 60) + '...'
            )
          },
        },
        {
          site: function (row) {
            return row.site.name
          },
        }
      )
    },
  },
}
</script>
