<template>
  <div>
    <data-table
      ref="list"
      model="koala/Repull"
      service-file="koala/RepullService"
      :path="path()"
      permissionPath="koala/account-jobs"
      :columns="columns()"
      :show-edit-modal="false"
      :show-create="false"
      :show-remove="false"
      :columnTemplates="columnTemplates()"
      :enableRowClick="false"
      :enableQuickSearch="false"
      :enableAdvancedSearch="true"
      :customActionButton="{
        action: openCreateModal,
        title: 'Create',
        class: 'btn btn-primary ml-2',
        icon: 'uil uil-plus',
      }"
      :tableLimits="[10, 50, 100]"
    />
    <repull-modal
      ref="createRepullModal"
      :closeAfterRemove="true"
      @refresh="$refs.list.search()"
    ></repull-modal>
  </div>
</template>
<script>
import RepullModal from '@molecules/koala/repull/add-modal.vue'
import TableColumnTemplate from '@constants/table-column-template.js'
import FilterChangedLogic from '@mixins/FilterChangedLogic.js'

export default {
  mixins: [FilterChangedLogic],
  components: { RepullModal },
  data() {
    return {
      repull: false,
    }
  },
  methods: {
    columns() {
      return [
        {
          label: 'Plaftorm',
          field: 'platform_names',
          class: 'col-platforms',
          search_key: 'platforms',
          type: 'text',
          sortable: false,
          filterable: true,
          advancedFilter: true,
          path: 'koala/v1/platforms?orderBy=name&ascending=1&limit=100',
          optionLabelKey: 'name',
          trackBy: 'id',
          basicFilterType: 'select',
          showEmptyNull: true,
          showAllOption: true,
          fetch_on_load: false,
          width: '200px',
        },
        {
          field: 'operator_names',
          search_key: 'operators',
          label: 'Operator',
          type: 'text',
          sortable: false,
          filterable: true,
          advancedFilter: true,
          path: this.computedPath.operatorsPath,
          optionLabelKey: 'name',
          trackBy: 'id',
          basicFilterType: 'select',
          showEmptyNull: true,
          showAllOption: true,
          fetch_on_load: false,
          searchBy: 'name',
        },
        {
          label: 'Username',
          field: 'usernames',
          search_key: 'accounts',
          type: 'text',
          filterable: true,
          advancedFilter: true,
          path: this.computedPath.accountsPath,
          optionLabelKey: 'username',
          trackBy: 'id',
          basicFilterType: 'select',
          showEmptyNull: true,
          showAllOption: true,
          fetch_on_load: false,
          searchBy: 'username',
          sortable: false,
          order_key: 'hercules_user_name',
          extraSelectLabel: 'operator.name',
          width: '300px',
        },
        {
          label: 'Hercules user',
          field: 'hercules_user_name',
          search_key: 'hercules_user_name',
          type: 'text',
          filterable: true,
          advancedFilter: true,
          path: 'backoffice/users?orderBy=name&ascending=1',
          optionLabelKey: 'name',
          trackBy: 'name',
          basicFilterType: 'select',
          showEmptyNull: true,
          showAllOption: true,
          fetch_on_load: false,
          searchBy: 'name',
          sortable: false,
          width: '200px',
        },
        {
          label: 'Business Unit',
          field: 'business_unit_names',
          search_key: 'business_units',
          type: 'text',
          sortable: false,
          filterable: true,
          advancedFilter: true,
          path: 'koala/v1/business-units?orderBy=name&ascending=1&limit=100',
          optionLabelKey: 'name',
          trackBy: 'id',
          basicFilterType: 'select',
          showEmptyNull: true,
          showAllOption: true,
          fetch_on_load: false,
        },
        {
          label: 'Reports',
          field: 'report_names',
          search_key: 'reports',
          type: 'text',
          sortable: false,
          filterable: true,
          advancedFilter: true,
          path: this.computedPath.reportsPath,
          optionLabelKey: 'name',
          trackBy: 'id',
          basicFilterType: 'select',
          showEmptyNull: true,
          showAllOption: true,
          fetch_on_load: false,
          searchBy: 'name',
        },
        {
          label: 'Dates',
          class: 'col-date',
          type: 'text',
          filterable: false,
          field: 'dates',
          sortable: false,
        },
        {
          label: 'Timestamp',
          class: 'col-timestamp',
          field: 'created',
          type: 'text',
          filterable: false,
          sortable: false,
          advancedFilter: false,
          template: TableColumnTemplate.FORMATTED_DATE_TIME,
        },
      ]
    },
    columnTemplates() {
      return Object.assign(
        {
          platform_names: function (row) {
            return row.platform_names.join(', ')
          },
        },
        {
          operator_names: function (row) {
            return row.operator_names.join(', ')
          },
        },
        {
          usernames: function (row) {
            return row.usernames.join(', ')
          },
        },
        {
          report_names: function (row) {
            return row.report_names.join(', ')
          },
        },
        {
          business_unit_names: function (row) {
            return row.business_unit_names.join(', ')
          },
        },
        {
          dates: function (row) {
            return row.date_range ? `${row.date_range.start_date} - ${row.date_range.end_date}` : ''
          },
        }
      )
    },
    path() {
      return `koala/v1/account-jobs`
    },
    openCreateModal() {
      this.$nextTick(() => {
        this.$refs.createRepullModal.show()
      })
    },
  },
}
</script>
<style>
.col-date {
  white-space: nowrap;
}
.col-timestamp {
  white-space: nowrap;
}
</style>
