<template>
  <data-table
    model="Language"
    service-file="LanguagesService"
    path="hercules/globals/languages"
    :columns="columns()"
  />
</template>

<script>
import TableColumnTemplate from '@constants/table-column-template.js'

export default {
  created() {
    this.setTitle()
  },
  methods: {
    columns() {
      return [
        { field: 'code', type: 'text', filterable: true },
        { field: 'name', type: 'text', filterable: true },
        { field: 'updated_at', type: 'text', filterable: true, template: TableColumnTemplate.RELATIVE_DATE },
      ]
    },
  },
}
</script>
