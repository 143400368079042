<template>
  <div class="card mt-3" :class="loading && 'loading'" v-if="listData">
    <div class="card-header">
      <div class="d-flex">
        <div>
          <div class="h5">
            {{ $prettyLabels(listData.label) }}
            <button
              class="btn btn-sm icon-button btn-link"
              data-tooltip="Edit list in new tab"
              @click="editList"
            >
              <i class="uil uil-edit"></i>
            </button>
          </div>
          <div class="text-muted mb-1">{{ listData.short_code }}</div>
          <div class="text-muted font-weight-bold text-uppercase mb-1">{{ listData.category.name }}</div>
        </div>
        <div class="ml-5">
          <dl class="card-insert">
            <dt>Type</dt>
            <dd class="code">{{ listData.type }}</dd>
            <dt>Tracker name</dt>
            <dd class="code">{{ listData.tracker }}</dd>
            <dt>Bonus name</dt>
            <dd class="code">{{ listData.one_liner }}</dd>
          </dl>
        </div>
        <div class="ml-5">
          <dl class="card-insert">
            <dt>{{ findFieldByName('top_list_item_show_load_more').field.label }}</dt>
            <dd class="code text-green" v-if="loadMoreEnabled">Enabled</dd>
            <dd class="code text-black-25" v-else>Disabled</dd>
            <dt>{{ findFieldByName('top_list_item_num_initial_load').field.label }}</dt>
            <dd class="code">{{ findFieldByName('top_list_item_num_initial_load').value || '-' }}</dd>
            <dt>{{ findFieldByName('top_list_item_num_load_more').field.label }}</dt>
            <dd class="code">{{ findFieldByName('top_list_item_num_load_more').value || '-' }}</dd>
          </dl>
        </div>
      </div>
    </div>
    <div class="card-body mh-300">
      <details>
        <summary class="alt-label">Operators ({{ listData.items.length }})</summary>
        <div class="mt-2">
          <ul>
            <li v-for="(item, key) in listData.items" :key="key">{{ item }}</li>
          </ul>
        </div>
      </details>
    </div>
  </div>
</template>
<script>
import TopListService from '@services/TopListsService.js'

export default {
  data() {
    return {
      listData: null,
      loading: false,
    }
  },
  mounted() {
    this.getListData()
  },
  computed: {
    loadMoreEnabled() {
      return this.findFieldByName('top_list_item_show_load_more').value
    },
  },
  methods: {
    async getListData() {
      this.loading = true
      await TopListService.getOne(this.findFieldByName('top_list_item_id').value, { with: 'items,category' })
        .then((response) => {
          this.listData = response.data.result && response.data.result[0]
        })
        .finally(() => {
          this.loading = false
        })
    },
    editList() {
      window.open(`/toplists/${this.listData.id}/items`)
    },
    findFieldByName(name) {
      return this.moduleField.children.find((item) => item.field.name === name)
    },
  },
  props: {
    fieldSpecificAttributes: {},
    moduleField: {
      type: Object,
      required: true,
    },
  },
}
</script>

<style scoped>
.spotlight-img {
  max-height: 100px;
}
.spotlight-img-icon {
  max-height: 60px;
}
</style>
