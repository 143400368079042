<template>
  <div>
    <div class="row">
      <div class="col-sm-6 col">
        <stats-column :value="affiliates" title="Affiliates" icon="gig-affiliates" />
      </div>
      <div class="col-sm-6 col">
        <stats-column :value="operators" title="Operators" icon="gig-casino" />
      </div>
    </div>
  </div>
</template>

<script>
import OperatorStatsService from '@services/stats/operators/StatsService.js'
import StatsColumn from '@atoms/misc/hercules-stats-column.vue'

export default {
  name: 'OperatorStats',
  components: { StatsColumn },
  data() {
    return {
      affiliates: 0,
      operators: 0,
    }
  },
  created() {
    this.getOperatorsStats()
  },
  methods: {
    getOperatorsStats() {
      OperatorStatsService.get().then((response) => {
        if (response.data.success) {
          this.affiliates = response.data.result.affiliates
          this.operators = response.data.result.operators
        }
      })
    },
  },
}
</script>
