<template>
  <div>
    <data-table
      model="DepositMethod"
      service-file="DepositMethodsService"
      :path="path()"
      :columns="columns()"
      :rowActionButtons="rowActions()"
      :show-edit-modal="false"
      ref="list"
      :show-remove="false"
    />
    <operator-list-modal ref="modal" model="model" />
  </div>
</template>

<script>
import OperatorsService from '@services/OperatorsService.js'
import OperatorListModal from '@atoms/general/operator-list-modal.vue'
import TableColumnTemplate from '@constants/table-column-template.js'
import ConfirmDialog from '@atoms/misc/confirm-dialog.vue'
import { openDialog } from 'vue3-promise-dialog'
import PagesService from '@services/PagesService.js'
import DepositMethodsService from '@services/DepositMethodsService.js'

const confirmDelete = async ({ title, content, htmlForPageRelations, actionButtonTitle }) => {
  return await openDialog(ConfirmDialog, { title, content, htmlForPageRelations, actionButtonTitle })
}

export default {
  components: { OperatorListModal },
  created() {
    this.setTitle()
  },
  methods: {
    columns() {
      return [
        { field: 'id', type: 'text', filterable: true },
        { field: 'name', type: 'text', filterable: true },
        { field: 'short_name', type: 'text', filterable: true },
        { field: 'rating', type: 'text', filterable: true },
        { field: 'payout_time', type: 'text', filterable: true },
        { field: 'min_deposit', type: 'text', filterable: true },
        {
          field: 'standardised_logo.url',
          type: 'text',
          filterable: false,
          sortable: false,
          label: 'Logo',
          template: TableColumnTemplate.IMAGE,
        },
        { field: 'updated_at', type: 'text', filterable: true, template: TableColumnTemplate.RELATIVE_DATE },
      ]
    },
    path() {
      return 'hercules/globals/deposit-methods'
    },
    rowActions() {
      let actions = [
        {
          action: this.showOperatorList,
          title: 'Operators',
          icon: 'uil uil-align-justify',
          class: 'btn-info',
        },
      ]
      if (this.methodAllowed('delete', this.path())) {
        actions.push({
          action: this.remove,
          title: 'Delete',
          icon: 'uil uil-trash-alt',
          class: 'btn-danger',
        })
      }
      return actions
    },
    showOperatorList(row) {
      OperatorsService.getOperatorsByDepositMethod(row.id)
        .then((response) => {
          if (response.data.success) {
            this.$refs.modal.operators = response.data.result
            this.$refs.modal.show()
          }
        })
        .catch(this.showUnknownErrorMessage)
    },
    async checkPageRelations(id) {
      try {
        const response = await PagesService.get({ relation_type: 'payment_method', relation_id: id })
        if (response.data.result) {
          this.pageRelations = response.data.result
        } else {
          console.error('No page relations found or error in response:', response)
          this.pageRelations = []
        }
      } catch (error) {
        console.error('Error fetching page relations:', error)
        this.showErrorMessage('Error fetching page relations')
      }
    },
    async remove(row) {
      await this.checkPageRelations(row.id)

      try {
        let htmlForPageRelations = ''

        if (this.pageRelations.length > 0) {
          const relatedPageIds = this.pageRelations
            .map((item) => `- <a href='/site-pages/${item.id}' target="_blank"> ${item.title} </a>`)
            .join('<br>')

          htmlForPageRelations = `<p>This item is used as a page relation for the following pages:<br>${relatedPageIds}</p>`
        } else {
          htmlForPageRelations = `<p>This item is not used as a page relation for any existing pages. It is safe to delete.</p>`
        }

        const confirm = await confirmDelete({
          title: 'Warning',
          content: 'Are you sure you want to delete this item?',
          htmlForPageRelations: htmlForPageRelations,
          actionButtonTitle: 'Delete item',
        })

        if (confirm) {
          this.loading = true

          if (row.id) {
            const deleteResponse = await DepositMethodsService.remove({ id: row.id })

            if (deleteResponse.data?.messages) {
              this.showErrorMessages(deleteResponse.data.messages)
            } else {
              this.showSuccessMessage('Item removed successfully.')
            }
          }
        }
      } catch (error) {
        console.error('Error removing item:', error)
        this.showUnknownErrorMessage()
      } finally {
        this.loading = false
        this.refresh()
      }
    },
    refresh() {
      this.$refs.list.search()
    },
  },
}
</script>
