<template>
  <img v-bind="$attrs" :src="src" />
</template>
<script>
// temporary component (created because backoffice/assets is not accessible without a JWT)
import AssetsService from '@services/AssetsService.js'
export default {
  components: {},
  props: {
    id: {},
  },
  data() {
    return {
      src: null,
    }
  },
  mounted() {
    if (this.id) {
      this.loadImage()
    }
  },
  methods: {
    loadImage() {
      AssetsService.getOne(this.id).then((response) => {
        if (response.data.success) {
          this.src = response.data.result[0].url
        }
      })
    },
  },
}
</script>

<style scoped>
.spotlight-img {
  max-height: 100px;
}
.spotlight-img-icon {
  max-height: 60px;
}
</style>
