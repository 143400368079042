const state = () => ({
  colors: [],
  styles: [],
})

const getters = {
  getColors: (state) => {
    return state.colors
  },
  getColorById: (state) => (id) => {
    return state.todos.find((color) => color.id === id)
  },
  getStyleByModuleId: (state) => (id) => {
    return state.styles.filter((item) => item.module_id === id)
  },
}

const actions = {
  setColors({ commit }, value) {
    commit('setColors', value)
  },
  setStyles({ commit }, value) {
    commit('setStyles', value)
  },
}

const mutations = {
  setColors(state, value) {
    state.colors = value
  },
  setStyles(state, value) {
    state.styles = value
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
