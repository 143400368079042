export default {
  methods: {
    setTitle: function (name = false) {
      //eslint-disable-next-line
      const env = import.meta.env.VITE_APP_ENV !== 'production' ? import.meta.env.VITE_APP_ENV : ''
      if (name) {
        document.title = `${name} | Hercules ${env}`
      } else if (this.$route.meta.name) {
        document.title = `${this.$route.meta.name} | Hercules ${env}`
      } else {
        alert('No title set for current view')
      }
    },
    getSlugTitle() {
      return this.$route.matched
        .map((item) => {
          return this.slugify(item.meta.name)
        })
        .join('_')
    },
    slugify(term) {
      return term
        .toLowerCase()
        .replace(/ /g, '_')
        .replace(/[^a-z0-9_]/g, '')
    },
    anchorify(term) {
      return term.toLowerCase().replace(/ /g, '-')
    },
    prettyLabels: (value) => {
      if (typeof value !== 'string' || value === '') return value
      let fixed = value.replace(/_|-/g, ' ')
      if (fixed.length !== 0) {
        return fixed[0].toUpperCase() + fixed.slice(1)
      }
    },
    capitalize(value) {
      if (!value) return value
      return value.replace(/^\w/, (c) => c.toUpperCase())
    },
    upperCase: (value) => {
      if (!value) return value
      return value.toUpperCase()
    },
    lowerCase: (value) => {
      if (!value) return value
      return value.toLowerCase()
    },
    findInObject(keys, object) {
      let value = null
      let current = null

      if (keys.length === 1) {
        return object[keys[0]]
      }

      keys.forEach((key, index) => {
        if (current == null) {
          current = object[key]
          return
        }

        if (index < keys.length - 1) {
          current = current[key]
        } else {
          value = current[key]
        }
      })

      return value
    },
    buildQueryString: (filters) => {
      return Object.keys(filters)
        .map((key) => key + '=' + filters[key])
        .join('&')
    },
    generateUID() {
      var dt = new Date().getTime()
      var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = (dt + Math.random() * 16) % 16 | 0
        dt = Math.floor(dt / 16)
        return (c == 'x' ? r : (r & 0x3) | 0x8).toString(16)
      })
      return uuid
    },
    sizeConverter(bytes) {
      const units = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

      let l = 0,
        n = parseInt(bytes, 10) || 0

      while (n >= 1024 && ++l) {
        n = n / 1024
      }

      return n.toFixed(n < 10 && l > 0 ? 1 : 0) + ' ' + units[l]
    },
    filterObject(obj, filter) {
      return Object.entries(obj)
        .map(([key, value]) => {
          return [key, value && typeof value === 'object' ? this.filterObject(value, filter) : value]
        })
        .reduce((acc, [key, value]) => {
          if (!filter.includes(value)) {
            acc[key] = value
          }

          return acc
        }, {})
    },
    isObject(objValue) {
      return objValue && typeof objValue === 'object' && objValue.constructor === Object
    },
  },
}
