<template>
  <div>
    <data-table
      model="sport/TeamMarket"
      service-file="SportsTeamMarketsService"
      :serviceParams="[team_id]"
      :path="path()"
      ref="list"
      :columns="columns()"
      :create-defaults="createDefaults()"
      :column-templates="columnTemplates()"
    />
  </div>
</template>

<script>
import TableColumnTemplate from '@constants/table-column-template.js'
import ConfirmDialog from '@atoms/misc/confirm-dialog.vue'

export default {
  components: { ConfirmDialog },
  created() {
    this.setTitle(
      `${this.$parent.$parent.title ? this.$parent.$parent.title + ':' : ''} ${this.$route.meta.name}`
    )
  },
  data() {
    return {
      team_id: this.$route.params['id'],
    }
  },
  methods: {
    columns() {
      return [
        { field: 'market.label', type: 'text', filterable: true, label: 'Market' },
        { field: 'nickname', type: 'text', filterable: true, label: 'Nickname' },
        { field: 'updated_at', type: 'text', filterable: true, template: TableColumnTemplate.RELATIVE_DATE },
      ]
    },
    createDefaults() {
      return {
        team_id: this.$route.params['id'],
      }
    },
    primaryCustomAction(row) {
      this.$refs.typesModal.show(row.id)
    },
    path() {
      return `hercules/sports/team-markets?with=teams,market&ascending=0&team_id=${this.$route.params['id']}`
    },
    columnTemplates() {
      return Object.assign({
        team_id: function (row) {
          return row.teams[0].name
        },
      })
    },
    refresh() {
      this.$refs.list.search()
    },
  },
}
</script>
