<template>
  <data-table
    service-file="AffiliateOperatorsService"
    model="AffiliateOperator"
    :path="path()"
    ref="list"
    :columns="columns()"
    :showEditModal="false"
    :customRowClick="rowClick"
    :showCreate="false"
    :column-templates="columnTemplates()"
  />
</template>

<script>
import TableColumnTemplate from '@constants/table-column-template.js'

export default {
  created() {
    this.setTitle(
      `${
        this.$store.state.editPage.model.website ? this.$store.state.editPage.model.website + ':' : ''
      } Operators`
    )
  },
  methods: {
    columns() {
      return [
        { field: 'operator_name', type: 'text', filterable: true },
        { field: 'market_label', type: 'text', filterable: true, template: TableColumnTemplate.MARKET_LABEL },
        { field: 'type', type: 'text', filterable: true },
        {
          field: 'status',
          type: 'text',
          advancedFilter: true,
          showModalAdvanced: false,
          filterable: true,
          customOptions: [
            { label: 'All', id: null },
            { label: 'Active', id: 'active' },
            { label: 'Inactive', id: 'inactive' },
            { label: 'Restricted', id: 'restricted' },
            { label: 'Coming soon', id: 'coming_soon' },
            { label: 'Blacklisted', id: 'blacklisted' },
            { label: 'Not recommended', id: 'not_recommended' },
          ],
          basicFilterType: 'select',
          optionLabelKey: 'label',
          template: TableColumnTemplate.OPERATOR_STATUS,
        },
        { field: 'updated_at', type: 'text', filterable: true, template: TableColumnTemplate.RELATIVE_DATE },
      ]
    },
    rowClick(event, row) {
      const ctrlOrMetaKey = event.ctrlKey || event.metaKey
      const path = `/operators/${row.operator_id}/sites-data?site=${this.$store.state.editPage.model.website}`
      if (ctrlOrMetaKey) {
        // open in new tab
        window.open(path, '_blank')
      } else {
        // navigate to edit page
        this.$router.push(path)
      }
    },
    path() {
      return `hercules/operators/affiliate-operators?affiliate_id=${this.$route.params['id']}&with=operator,market,linked_group&exact_match=status`
    },
    columnTemplates() {
      return Object.assign({
        operator_name: function (row) {
          return row.operator.name
        },
        status: function (row) {
          return row.status
        },
      })
    },
  },
}
</script>
