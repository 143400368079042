<template>
  <div class="row d-flex justify-content-center">
    <div class="card col-12 col-xl-6">
      <div class="card-header">
        <h3 class="card-title">Generate links & bonuses report</h3>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-10">
            <base-select
              label="Site"
              option-label-key="website"
              path="hercules/globals/sites?orderBy=name&ascending=1"
              v-model="site"
              :required="true"
              trackBy="id"
              @update:modelValue="changeSiteId()"
            />
          </div>
        </div>
        <div class="row">
          <base-multiselect
            ref="marketSelect"
            :multiple="true"
            field="market"
            label="Markets"
            selectLabel="label"
            trackByOption="id"
            :path="`hercules/operators/affiliate-markets-data?affiliate_id=${site}&with=market,linked_group&label=1`"
            :value="market"
            class="col-10 pr-2 pl-2 mb-0"
            helpText="Showing markets coming from DMS > Affiliate site settings > Markets data"
            @sync="syncMarket"
          />
        </div>
      </div>
      <div class="card-footer">
        <button :class="{ loading: loading }" class="btn btn-primary icon-btn" @click="exportCsv">
          <i class="uil uil-file-export"></i>Export to CSV
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import BaseSelect from '@/components/fields/base-select.vue'
import BaseMultiselect from '@/components/fields/base-multiselect.vue'
import moment from 'moment'
import { ExportToCsv } from 'export-to-csv'

export default {
  created() {
    this.setTitle()
  },
  data() {
    return {
      site: null,
      market: [],
      loading: false,
    }
  },
  components: { BaseSelect, BaseMultiselect },
  methods: {
    syncMarket(_field, value, key) {
      let newValue = null
      if (value && Array.isArray(value)) {
        newValue = value.map((item) => item[key])
      }
      this.market = newValue
    },
    exportCsv() {
      this.loading = true
      const filename = `links-and-bonuses-report-${moment().format('YYYY-MM-DD-HHmm')}`
      let params = {
        site: this.site,
      }
      if (this.market) {
        params.market = this.market
      }

      this.$http
        .get(`hercules/operators/affiliate-link-export`, {
          params,
        })
        .then((response) => {
          const items = response.data.result
          if (items.length === 0) {
            this.showErrorMessages('No results for selected fields')
            this.loading = false
            return
          }

          // items.forEach((item) => {
          //   item['Bonus Crawl Status'] =
          //     item['Bonus Crawl Status'] === 1 ? true : item['Bonus Crawl Status'] === 0 ? false : null
          // })

          const csvExporter = new ExportToCsv({
            useKeysAsHeaders: true,
            filename: filename,
          })
          csvExporter.generateCsv(items)
          this.loading = false
        })
        .catch(() => {
          this.showErrorMessage('Failed to generate report')
          this.loading = false
        })
    },
    changeSiteId() {
      this.$nextTick(() => {
        this.$refs.marketSelect.getOptions()
      })
    },
  },
}
</script>
