import Login from '@pages/auth/login.vue'
import ForgotPassword from '@pages/auth/forgot-password.vue'
import ResetPassword from '@pages/auth/reset-password.vue'

export default [
  {
    path: '/login',
    component: Login,
    meta: {
      auth: false,
      name: 'Login',
      bodyClass: 'bg-bluegrey-4',
    },
  },
  {
    path: '/azure-login',
    meta: {
      auth: false,
    },
    name: 'Azure Login',
    beforeEnter() {
      location.href = import.meta.env.VITE_APP_SERVER_SIDE + '/backoffice/auth/socialite-login/azure'
    },
  },
  {
    path: '/forgot-password',
    component: ForgotPassword,
    meta: {
      auth: false,
      name: 'Forgot password',
      bodyClass: 'bg-bluegrey-3',
    },
  },
  {
    path: '/reset-password',
    component: ResetPassword,
    meta: {
      auth: false,
      name: 'Reset password',
      bodyClass: 'bg-bluegrey-3',
    },
  },
]
