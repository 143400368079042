<template>
  <div v-if="modelValue">
    <div v-for="(filter, name) in filters" v-bind:key="name" class="form-group">
      <base-multiselect
        v-if="filter.length > 0"
        :value="modelValue[name]"
        :customOptions="filter"
        :multiple="true"
        :field="name"
        :label="filterLabel(name)"
        :placeholder="placeholder(name)"
        @sync="(field, value) => valueChanged(name, value)"
      />
    </div>
  </div>
</template>

<script>
import FilterService from '../../services/FilterService'
import PageService from '../../services/PagesService'
import BaseMultiselect from './base-multiselect.vue'
import MultiSelect from 'vue-multiselect'

export default {
  components: {
    BaseMultiselect,
    MultiSelect,
  },
  props: {
    modelValue: {
      type: Object,
      default: () => {},
    },
    parameters: {
      type: Object,
      default: () => {},
    },
    pageType: {
      type: Object,
    },
  },
  data() {
    return {
      filters: {},
      noFiltersMessage: '',
    }
  },
  methods: {
    loadOptions() {
      this.filters = {}
      this.getPageTypeFilters()
    },
    getPageTypeFilters() {
      this.getService()
        .then((response) => {
          // no filters found
          if (response.data.messages) {
            this.noFiltersMessage = response.data.messages
            return
          }

          // assign empty object if value is empty
          let valueObject = this.modelValue ? this.modelValue : {}

          // assign filter values
          Object.entries(response.data.result.filter).map(([key]) => {
            if (!this.modelValue || !Object.hasOwn(this.modelValue, key)) {
              this.valueObject = Object.assign({}, this.modelValue, { [key]: '' })
            }
          })

          // assign filter values (once)
          this.$emit('update:modelValue', valueObject)

          // load options for every filter
          Object.entries(response.data.result.filter).map(([key]) => {
            PageService.getFilters(
              Object.assign({}, this.parameters, { filter_name: key, type: this.pageType.type })
            )
              .then((filter) => {
                if (filter.data.messages) {
                  this.showErrorMessages(filter.data.messages)
                } else {
                  this.filters = Object.assign({}, this.filters, { [key]: filter.data.result })
                }
              })
              .catch((error) => {
                throw error
              })
          })
        })
        .catch(() => {
          this.showErrorMessage('Failed to get page type filters')
        })
    },
    filterLabel(name) {
      const filterName = this.capitalize(this.$prettyLabels(name))
      const originalFilterNames = ['page_categories']
      return originalFilterNames.includes(name)
        ? filterName
        : this.capitalize(this.pageType.type) + ' ' + filterName
    },
    getService() {
      switch (this.pageType.type) {
        case 'game':
          return FilterService.getGameFilters()
        case 'operator':
          return FilterService.getOperatorFilters()
      }
      return new Promise((resolve) => {
        resolve({ data: { messages: `No Filters Available` } })
      })
    },
    placeholder(name) {
      if (this.filters[name].length > 0) {
        return 'Search for ' + this.$prettyLabels(name)
      } else {
        return 'No ' + this.$prettyLabels(name) + ' available'
      }
    },
    valueChanged(filter, value) {
      this.$emit('update:modelValue', {...this.modelValue, ...{ [filter]: value } })
      
    },
  },
}
</script>
