<template>
  <div>
    <div v-bind:key="index" v-for="(moduleField, index) in pageModule.module_value_fields">
      <model-select
        v-if="moduleField.field.name === 'menu_id'"
        :parameters="{
          site_id: siteId,
          market_id: marketId,
          with_count: 'items',
        }"
        model="menu"
        :value="moduleField.value"
        :value-label="moduleField.value_label"
        @valuePicked="(id, label) => valuePicked(id, label, index)"
      />
      <div v-if="moduleField.value && showLinkToMenuItems">
        <router-link :to="'/site-menus/' + moduleField.value + '/items'" target="_blank"
          >Edit menu items</router-link
        >
      </div>
    </div>
  </div>
</template>
<script>
import ModelSelect from '@molecules/fields/model-select/base-model-select.vue'

export default {
  components: { ModelSelect },
  props: {
    siteId: {
      required: false,
      default: null,
    },
    marketId: {
      required: false,
      default: null,
    },
    pageModule: {
      type: Object,
      required: true,
      default: () => {},
    },
    showLinkToMenuItems: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    if (!this.pageModule.module_value_fields[0].value) {
      this.$parent.$parent.previewLabel = 'No menu selected'
    }
  },
  methods: {
    valuePicked(id, label, index) {
      this.pageModule.module_value_fields[index].value = id
      this.pageModule.module_value_fields[index].value_label = label
    },
    menuSelectColumns() {
      return [{ field: 'title', type: 'text', filterable: true }]
    },
  },
}
</script>
