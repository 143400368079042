<template>
  <data-table
    ref="list"
    model="Currency"
    service-file="CurrenciesService"
    path="hercules/globals/currencies"
    :columns="columns()"
    :column-templates="columnTemplates()"
    :createDefaults="{ active: true }"
  />
</template>

<script lang="jsx">
import CurrenciesService from '@services/CurrenciesService.js'
import TableColumnTemplate from '@constants/table-column-template.js'
import EnabledActionColumn from '@molecules/table/cell/enabled-action.vue'

export default {
  created() {
    this.setTitle()
  },
  methods: {
    columns() {
      return [
        { field: 'iso_code', type: 'text' },
        { field: 'name', type: 'text' },
        { field: 'crypto', type: 'text', template: TableColumnTemplate.BOOLEAN },
        { field: 'active', type: 'text' },
        { field: 'symbol', type: 'text' },
        {
          field: 'standardised_logo.url',
          type: 'text',
          filterable: false,
          label: 'Logo',
          template: TableColumnTemplate.IMAGE,
        },
        { field: 'updated_at', type: 'text', template: TableColumnTemplate.RELATIVE_DATE },
      ]
    },
    columnTemplates() {
      const self = this
      return {
        active: function (row) {
          if (this.hasRole('admin')) {
            return (
              <EnabledActionColumn
                row={row}
                field={'active'}
                onColumnClick={self.actionColumnClick}
                confirmTitle={'Action required'}
                confirmMessage={
                  row.active
                    ? `To deactivate a currency on a website's front end, reload the JSON listener. Deactivated currencies are shown in grey in Hercules.`
                    : `To activate a currency on a website's front end, reload the JSON listener.`
                }
                confirmButtonTitle={row.active ? 'Disable Currency' : 'Enable Currency'}
                confirmButtonClass={row.active ? 'btn btn-warning' : 'btn btn-info'}
                confirmButtonIconClass={row.active ? 'uil uil-trash' : 'uil uil-check'}
              />
            )
          } else {
            return row.active ? (
              <span class="badge bg-green">Active</span>
            ) : (
              <span class="badge bg-white-25">Inactive</span>
            )
          }
        },
      }
    },
    actionColumnClick(id, field, value) {
      this.$refs.list.isLoading = true
      let object = { id: id }
      object[field] = value
      CurrenciesService.update(object)
        .then((response) => {
          if (response.data.success) {
            this.showSuccessMessage(`Column updated`)
            this.$refs.list.search()
          } else {
            this.showErrorMessage(response.data.messages[0])
            this.$refs.list.isLoading = false
          }
        })
        .catch(() => {
          this.showErrorMessage('Failed to update currency')
          this.$refs.list.isLoading = false
        })
    },
  },
}
</script>
