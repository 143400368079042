<template>
  <div class="card">
    <dynamic-form
      ref="form"
      formType="create"
      :formFields="structure.list"
      :structure="structure"
      :object="object"
      :on-submit="submit"
      :path="config.url"
      :inCard="true"
    />
  </div>
</template>

<script setup>
import { ref, reactive, getCurrentInstance } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import DynamicForm from '@/components/fields/DynamicForm.vue'
import Bonus from '@models/Bonus.js'
import BonusesService from '@services/BonusesService.js'

const route = useRoute()
const router = useRouter()
const operatorId = ref(route.params.id)
const object = ref({})
const structure = reactive({ list: Bonus.getStructure(operatorId.value)?.list })
const config = Bonus.getConfig()

const form = ref(null)

const { proxy } = getCurrentInstance()

const submit = async () => {
  if (form.value) {
    form.value.isLoading = true
  }

  const submissionData = {
    ...object.value,
    operator_id: operatorId.value,
  }

  try {
    const response = await BonusesService.post(submissionData)
    if (response.data.messages) {
      proxy.showErrorMessages(response.data.messages)
    } else {
      proxy.showSuccessMessage('Market data created successfully')
      object.value = {}
      router.push(`/operators/${route.params.id}/markets-data/edit/${response.data.result.id}`)
    }
  } catch (error) {
    proxy.showUnknownErrorMessage()
  } finally {
    if (form.value) {
      form.value.isLoading = false
    }
  }
}
</script>
