import FieldType from '@constants/field-type.js'

export default {
  getForm() {
    return [
      {
        name: 'name',
        required: true,
        display_name: 'Site name',
        type: 'text',
        help_text: 'Only full URLs are accepted, e.g. http://casino.com',
      },
      {
        name: 'site_abbreviation',
        require: false,
        display_name: 'Site Abbreviation',
        type: 'text',
      },
      {
        name: 'default_market_id',
        search_url: 'hercules/globals/markets',
        required: true,
        display_name: 'Default Market',
        type: 'list-one',
        selectLabel: 'label',
        value_key: 'default_market.label',
      },
      {
        name: 'unit_id',
        search_url: 'hercules/operators/units',
        required: false,
        display_name: 'Unit',
        type: 'list-one',
        selectLabel: 'name',
        value_key: 'unit.name',
      },
      {
        name: 'tier',
        required: false,
        display_name: 'Tier',
        type: 'list-one',
        options: ['Tier 1', 'Tier 2', 'Tier 3', 'Tier 4', 'Unclassified'],
      },
      {
        name: 'framework',
        required: false,
        display_name: 'Framework',
        type: 'list-one',
        options: ['Laravel', 'Gatsby', 'Wordpress', 'Joomla', 'Others'],
      },
      {
        name: 'theme',
        required: false,
        display_name: 'Theme',
        type: 'list-one',
        options: [
          'Rage PPC',
          'Rage SEO',
          'Sports',
          'Matrix',
          'PPC',
          'Default',
          'Olympus',
          'Modular v2',
          'Modular v1',
          'Spartacus',
          'Boombox',
          'Mercury',
          'Kentaurus',
          'Elegance',
          'Expertly',
          'Custom',
          'Partnerships',
        ],
      },
      {
        name: 'featured_image_id',
        required: false,
        display_name: 'Featured Image',
        type: FieldType.BASE_MEDIA_SITE_SPECIFIC,
      },
      {
        name: 'watermark_asset_id',
        required: false,
        display_name: 'Watermark',
        type: FieldType.BASE_MEDIA_SITE_SPECIFIC,
      },
      {
        name: 'toplist_cache_enabled',
        help_text: "Only for small sites that don't have their own client-side caching",
        required: false,
        display_name: 'Toplist cache enabled',
        type: 'checkbox',
        switch: true,
      },
      {
        name: 'link_logic_enabled',
        help_text: 'Enable link logic for this site (fallback logic)',
        required: false,
        type: 'checkbox',
        switch: true,
      },
      {
        name: 'status',
        required: false,
        type: 'list-one',
        options: ['Active', 'Coming Soon', 'Inactive', 'Error'],
      },
      {
        name: 'advertising_disclosure',
        required: false,
        display_name: 'Advertising Disclosure',
        type: 'textarea',
      },
    ]
  },
  getConfig() {
    return {
      name: 'Site',
      url: 'operators/affiliates',
    }
  },
  getStructure() {
    return {
      list: this.getForm(),
      config: this.getConfig(),
    }
  },
}
