<template>
  <div class="modal" ref="modal" tabindex="-1" role="dialog" v-click-outside="close">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Clone {{ topListLabel }}</h5>

          <button class="close" v-on:click="close"></button>
        </div>

        <dynamic-form
          form-type="edit"
          v-if="object"
          ref="form"
          :formFields="structure.list"
          :inModal="true"
          :structure="structure"
          :object="object"
          :on-submit="submit"
          submit-label="Clone"
        />
      </div>
    </div>
  </div>
</template>

<script>
import TopListsService from '@services/TopListsService.js'
import MultiSelect from 'vue-multiselect'
import DynamicForm from '@/components/fields/DynamicForm.vue'
import TopListClone from '@models/TopListClone.js'

export default {
  components: { MultiSelect, DynamicForm },
  props: {
    shortCodeDisabled: {
      default: false,
      type: Boolean,
    },
    labelDisabled: {
      default: false,
      type: Boolean,
    },
    openInNewTab: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      object: null,
      structure: {},
      topListLabel: null,
      config: TopListClone.getConfig(),
    }
  },
  mounted() {
    this.structure = TopListClone.getStructure()
    let shortCodeField = this.structure.list.find((field) => field.name === 'short_code')
    if (shortCodeField) {
      shortCodeField.disabled = this.shortCodeDisabled
    }
    let labelField = this.structure.list.find((field) => field.name === 'label')
    if (labelField) {
      labelField.disabled = this.labelDisabled
    }
    document.getElementById('modal').appendChild(this.$el)
  },
  methods: {
    show(object) {
      this.topListLabel = object.label
      TopListsService.getOne(object.id, { with: 'market' })
        .then((response) => {
          if (Object.hasOwn(response.data, 'messages')) {
            this.showErrorMessages(response.data.messages)
            this.$emit('refresh')
          } else {
            this.object = response.data.result[0]
            delete this.object.market_id
            delete this.object.market
            this.$refs.modal.classList.add('show')
          }
        })
        .catch(this.showUnknownErrorMessage)
    },
    close() {
      this.$refs.modal && this.$refs.modal.classList.remove('show')
    },
    async submit() {
      await TopListsService.clone(this.object)
        .then((response) => {
          if (response.data.messages) {
            this.showErrorMessages(response.data.messages)
          } else {
            this.showSuccessMessage('List cloned')
            this.$emit('refresh')
            this.object = null
            this.close()
            if (this.openInNewTab) {
              window.open(`/toplists/${response.data.result.id}`, '_blank')
            }
          }
        })
        .catch(() => this.showUnknownErrorMessage)
        .finally(() => {
          if (this.$refs.form) {
            this.$refs.form.isLoading = false
          }
        })
    },
  },
}
</script>
