<template>
  <div v-if="shouldShow" class="row mb-2">
    <div class="col"></div>
    <div class="col-auto">
      <div class="d-flex flex-column font-size-sm bg-orange-10 rounded-lg p-2">
        <span>
          <i class="uil uil-exclamation-triangle mr-1 text-orange"></i>
          {{ page.relation_warning }}
        </span>
         <div class="pt-1" v-for="(link, index) in page.related_page_links" :key="index">
            <span class="d-block pl-4">
              <a :href="link.link" target="blank">
                {{ link.title }}
              </a>
            </span>
          </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    page: {
      type: Object,
      required: true
    }
  },
  computed: {
    shouldShow() {
      if (this.page.relation_warning) {
        return true
      }
      return false
    }
  }
}
</script>
