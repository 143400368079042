<template>
  <div class="modal" ref="modal" tabindex="-1" role="dialog" v-click-outside="close">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Add Ribbons to {{ row.operator.name }}</h5>
          <button class="close" v-on:click="close"></button>
        </div>
        <div class="modal-body pb-2">
          <base-multiselect
            :path="`hercules/globals/ribbons?site_id=${list.affiliate_id}&market_id=${list.market_id}`"
            name="ribbons"
            label="Ribbons"
            selectLabel="label"
            trackByOption="id"
            :value="row.ribbons"
            :loadOnMount="true"
            field="ribbons"
            noOptionsPlaceholder="Ribbons"
            :noOptionsLink="`/affiliates/${list.affiliate_id}/ribbons`"
            @sync="syncRibbons"
            :max="3"
          />
        </div>
        <div class="modal-footer pt-3">
          <div class="d-flex align-items-center justify-content-between w-100">
            <div class="text-muted text-left">
              Ribbons for your site and language can be managed
              <a :href="`/affiliates/${list.affiliate_id}/ribbons`" target="_blank">here</a>.
            </div>
            <div class="w-25 text-right">
              <button type="submit" class="btn btn-primary" @click="submitModal">
                <i class="uil uil-check"></i>
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { cloneDeep } from 'lodash'
import BaseCheckbox from '@atoms/fields/base-checkbox.vue'
import BaseMultiselect from '@/components/fields/base-multiselect.vue'

export default {
  components: { BaseCheckbox, BaseMultiselect },
  data: function () {
    return {
      showModal: false,
      ribbons: [],
      categoryParams: {},
    }
  },
  props: {
    index: {
      type: Number,
      required: true,
    },
    row: {
      type: Object,
      required: true,
    },
    list: {
      type: Object,
      required: true,
    },
    storeName: {
      type: String,
      required: true,
    },
  },
  mounted() {
    document.getElementById('modal').appendChild(this.$el)
  },
  methods: {
    show() {
      this.$refs.modal.classList.add('show')
    },
    close() {
      let clone = cloneDeep(this.row)
      clone.ribbons = []
      this.$store.dispatch('toplist/updateItem', clone)
      this.$refs.modal && this.$refs.modal.classList.remove('show')
    },
    submitModal() {
      this.$refs.modal && this.$refs.modal.classList.remove('show')
    },
    syncRibbons(_field, value) {
      if (value) {
        let newValue = null
        if (Array.isArray(value)) {
          newValue = value.map((item) => item)
        }
        let clone = cloneDeep(this.row)
        clone.ribbons = newValue
        this.$store.dispatch(`${this.storeName}/updateItem`, { index: this.index, item: clone })
      }
    },
  },
}
</script>
