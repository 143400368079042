<template>
  <div class="">
    <sidebar-filter ref="sidebar" :from-schema="true" />
    <div v-if="marketId">
      <div class="card">
        <div class="card-header">
          <h3>Schema Information</h3>
          <div class="ml-auto d-flex align-items-center">
            <span @click="openModalInformation()" class="btn btn-outline-info btn-md mr-3">
              <i class="uil uil-info-circle"></i>Help
            </span>
            <span class="text-muted mr-2">
              Save your changes
              <strong>before</strong> changing market
            </span>
            <i class="uil uil-exclamation-circle mr-3 text-muted" />
            <button
              class="btn btn-primary"
              v-bind:class="{ loading: loading }"
              @click="submit"
              :disabled="showColaborativeNotification"
            >
              <i class="uil uil-check mr-2"></i>Save
            </button>
          </div>
        </div>
        <div class="card-body">
          <dynamic-form
            actionButtonsTop
            ref="form"
            form-type="edit"
            :formFields="structure.list"
            :structure="structure"
            :object="object"
            :path="config.url"
            :on-submit="submit"
            :showSubmit="false"
            :parameters="{ site_id: $route.params.id, market_id: marketId }"
            @show-colaborative-notification="showColaborativeNotification = true"
            pusherModel="sites-schema_information"
          />
        </div>
      </div>
    </div>
    <information-modal ref="informationModal" :id="confluenceId" />
  </div>
</template>

<script>
import SidebarFilter from '@molecules/cms/sites/sidebar-filter.vue'
import Schema from '@models/cms/Schema.js'
import SchemaService from '@services/SchemaService.js'
import DynamicForm from '@/components/fields/DynamicForm.vue'
import InformationModal from '@molecules/cms/modals/information-modal.vue'
import confluenceIds from '@constants/confluence-ids.js'

export default {
  created() {
    this.confluenceId = confluenceIds.sites.schema.id
    this.setTitle()
  },
  data() {
    return {
      object: {},
      structure: { list: [] },
      config: Schema.getConfig(),
      isUpdate: false,
      loading: false,
      confluenceId: 0,
      showColaborativeNotification: false,
    }
  },

  computed: {
    marketId() {
      return this.$store.state.sidebar.market
    },
    siteId() {
      return this.$store.state.sidebar.siteId
    },
  },
  watch: {
    marketId(marketId) {
      if (marketId) this.getSchema()
    },
    siteId(siteId) {
      if (siteId) this.getSchema()
    },
  },
  methods: {
    openModalInformation() {
      this.$refs.informationModal.show()
    },
    submit() {
      if (this.showColaborativeNotification) {
        return
      }
      const self = this
      this.loading = true
      this.$refs.form
        .process(this.object)
        .then((response) => {
          const data = Object.assign(response, {
            market_id: this.marketId,
            site_id: this.siteId,
          })
          return this.isUpdate ? SchemaService.update(data) : SchemaService.post(data)
        })
        .then((response) => {
          if (response.data.messages) {
            self.showErrorMessages(response.data.messages)
          } else {
            self.showSuccessMessage(`${self.structure.config.name} updated`)
            self.getSchema()
          }
        })
        .catch(() => this.showUnknownErrorMessage)
        .finally(() => {
          this.loading = false
        })
    },
    getSchema() {
      SchemaService.get({
        market_id: this.marketId,
        site_id: this.siteId,
      }).then((response) => {
        this.isUpdate = response.data.result && response.data.result.length === 1
        this.object = (response.data.result && response.data.result[0]) || { email: '' }
        this.getStructure()
      })
    },
    getStructure() {
      this.structure = Schema.getStructure()
    },
  },
  components: { SidebarFilter, DynamicForm, InformationModal },
}
</script>
