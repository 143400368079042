<template>
  <div class="form-group">
    <label v-if="showLabel">{{ $prettyLabels(label) }}</label>
    <label class="text-muted ml-1 clickable" v-if="labelAction" @click="labelAction">{{
      labelActionTitle
    }}</label>
    <span v-if="required">&nbsp;*&nbsp;</span>
    <span
      v-if="tooltip"
      :data-tooltip="tooltip"
      data-tooltip-color="info"
      data-tooltip-position="left center"
      class="mr-2"
    >
      <i class="uil uil-info-circle" />
    </span>
    <div v-if="enableToggleAll" class="d-inline">
      <a class="text-muted ml-2" v-on:click="toggleAll(true)">select all</a>
      <span class="text-muted ml-1 mr-1">/</span>
      <a class="text-muted" v-on:click="toggleAll(false)">deselect all</a>
    </div>
    <div v-if="searchable && showSearchField" class="text-muted">
      <input
        type="text"
        class="form-control search-text"
        id="searchField"
        :placeholder="this.searchPlaceholder"
        v-model="searchText"
      />
    </div>
    <div
      v-if="(!filteredOptions || filteredOptions.length === 0) && parameters && parameters.site_id"
      class="form-control text-muted"
    >
      No {{ noOptionsPlaceholderComputed }}, click
      <a :href="manageCategoryLink" target="blank"> here </a>
      to add one
    </div>
    <div class="pb-0 p-3 checkbox-list-container" v-if="checkboxList">
      <div
        class="checkbox w-100 d-flex justify-content-between mb-3"
        v-for="(item, index) in filteredOptions"
        v-bind:key="index"
        ref="listRef"
        @click="toggleItem(item, $event)"
      >
        <input
          :checked="isActive(item[identifier])"
          type="checkbox"
          :id="item[identifier]"
          :value="item[identifier]"
          :disabled="disabled"
        />
        <label v-if="customLabel(item)" :for="item[identifier]" class="no-selectable mb-0">{{
          $prettyLabels(customLabel(item))
        }}</label>

        <span
          :id="item[identifier] + 'badge'"
          class="badge"
          v-if="showPrimary && isActive(item[identifier])"
          v-bind:class="[isPrimary(item[identifier]) ? 'bg-azure' : 'bg-black-25 opacity-4']"
          @mouseover="
            setPrimaryText(
              isPrimary(item[identifier]) ? 'Remove Primary' : 'Set Primary',
              index,
              item[identifier]
            )
          "
          @mouseout="setPrimaryText('Set Primary', index, item[identifier])"
          @click="setPrimary(item[identifier])"
          >Set Primary</span
        >
      </div>
    </div>
    <small v-if="helpText" class="d-block text-muted">{{ helpText }}</small>
  </div>
</template>

<script>
import GeneralService from '@services/GeneralService.js'

export default {
  data() {
    return {
      selectedItems: this.selectedOptions,
      checkboxList: [],
      loading: true,
      searchText: this.parameters ? this.parameters.search : '',
    }
  },
  props: {
    required: {
      default: () => false,
      type: Boolean,
    },
    showLabel: {
      type: Boolean,
      default: () => true,
    },
    showPrimary: {
      type: Boolean,
      default: () => true,
    },
    identifier: {
      type: String,
      default: () => 'id',
    },
    selectLabel: {
      type: String,
      default: () => 'name',
    },
    label: {
      type: String,
    },
    noOptionsPlaceholder: {
      type: String,
    },
    labelActionTitle: {
      type: String,
    },
    labelAction: {
      type: Function,
    },
    options: {
      type: Array,
    },
    path: {
      type: String,
    },
    parameters: {
      type: Object,
      required: false,
      default: () => {},
    },
    tooltip: {
      type: String,
    },
    helpText: {
      type: String,
    },
    selectedOptions: {
      type: Array,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      default: () => false,
    },
    loadOnMount: {
      type: Boolean,
      default: () => true,
    },
    enableToggleAll: {
      type: Boolean,
      default: () => false,
    },
    searchable: {
      type: Boolean,
      default: () => false,
    },
    searchPlaceholder: {
      type: String,
      default: () => 'Search for a category',
    },
    checkboxCountLimitToShow: {
      type: Number,
      default: () => 10,
    },
  },
  computed: {
    manageCategoryLink() {
      if (this.parameters && this.parameters.site_id) {
        return `/site-sites/${this.parameters.site_id}/categories`
      }
      return null
    },
    showSearchField() {
      const checkboxCount = this.checkboxList.length !== undefined ? this.checkboxList.length : 0
      return (
        checkboxCount > this.checkboxCountLimitToShow ||
        (checkboxCount <= this.checkboxCountLimitToShow &&
          this.searchText !== undefined &&
          this.searchText !== '')
      )
    },
    noOptionsPlaceholderComputed() {
      if (!this.searchable || !this.showSearchField) {
        return this.noOptionsPlaceholder + ' available'
      } else {
        return this.noOptionsPlaceholder + ' available when searching for: ' + this.searchText
      }
    },
    filteredOptions() {
      if (this.searchText !== '' && this.searchText !== undefined) {
        return this.checkboxList.filter((item) => {
          return item.name.toLowerCase().includes(this.searchText.toLowerCase())
        })
      }
      return this.checkboxList
    },
  },
  mounted() {
    if (this.options) {
      this.checkboxList = this.options
    } else if (this.path && this.loadOnMount) {
      this.getOptions()
    }
  },
  methods: {
    getOptions() {
      GeneralService.fetchItems(this.path, this.parameters).then((response) => {
        if (response.data.messages) {
          this.showErrorMessages(response.data.messages)
        } else {
          this.checkboxList =
            response.data.result.length > 0 &&
            response.data.result.map((item) => {
              item.primary = false
              return item
            })
          this.loading = false
        }
      })
    },
    setPrimaryText(text, index, id) {
      if (this.isActive(id) && !this.disabled) {
        this.$refs.listRef[index].querySelector('.badge').innerHTML = text
        this.$refs.listRef[index].classList.add('hover')
      }
    },
    setPrimary(id) {
      if (!this.disabled) {
        const currValue = this.isPrimary(id)
        this.selectedItems.filter((item) => (item.primary = false))

        if (!currValue) {
          this.selectedItems.filter((item) => {
            if (item[this.identifier] === id) {
              item.primary = true
            }
          })
        }
        this.$forceUpdate()
        this.$emit('onChange', this.selectedItems)
      }
    },
    isPrimary(id) {
      return this.selectedItems && this.selectedItems.find((item) => item[this.identifier] == id)?.primary
    },
    isActive(id) {
      return this.selectedItems &&
        this.selectedItems.find((item) => item[this.identifier] == id) !== undefined
        ? true
        : false
    },
    toggleItem(clickedItem, e) {
      if (e.target.nodeName != 'SPAN' && e.target.nodeName != 'LABEL') {
        let selectedItem = this.selectedItems.find(
          (item) => item[this.identifier] == clickedItem[this.identifier]
        )
        if (selectedItem) {
          selectedItem.primary = false
          this.selectedItems = this.selectedItems.filter((item) => {
            if (item[this.identifier] != selectedItem[this.identifier]) {
              return item
            }
          })
        } else {
          clickedItem.active = true
          this.selectedItems.push(clickedItem)
        }
      }
      this.$emit('onChange', this.selectedItems)
    },
    toggleAll(active) {
      this.checkboxList.forEach((element) => {
        element.active = active
      })
      this.selectedItems = active ? this.checkboxList : []
      this.$emit('onChange', this.selectedItems)
    },
    customLabel(value) {
      return this.findInObject(this.selectLabel.split('.'), value)
    },
  },
}
</script>

<style scoped>
.opacity-4 {
  opacity: 0.4;
}
.search-text {
  background-color: white !important;
  border-style: ridge;
}
</style>
