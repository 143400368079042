<template>
  <div>
    <data-table
      model="sport/TeamLocales"
      service-file="SportsTeamLocalesService"
      :serviceParams="[team_id]"
      :path="path()"
      ref="list"
      :columns="columns()"
      :create-defaults="createDefaults()"
    />
  </div>
</template>

<script>
import ConfirmDialog from '@atoms/misc/confirm-dialog.vue'
import TableColumnTemplate from '@constants/table-column-template.js'

export default {
  components: { ConfirmDialog },
  created() {
    this.setTitle(
      `${this.$parent.$parent.title ? this.$parent.$parent.title + ':' : ''} ${this.$route.meta.name}`
    )
  },
  data() {
    return {
      team_id: this.$route.params['id'],
    }
  },
  methods: {
    columns() {
      return [
        { field: 'language.name', type: 'text', filterable: true, label: 'Language' },
        { field: 'name', type: 'text', filterable: true, label: 'Name' },
        {
          field: 'updated_at',
          type: 'text',
          filterable: true,
          label: 'Updated at',
          template: TableColumnTemplate.RELATIVE_DATE,
        },
      ]
    },
    createDefaults() {
      return {
        team_id: this.$route.params['id'],
      }
    },
    primaryCustomAction(row) {
      this.$refs.typesModal.show(row.id)
    },
    path() {
      return `hercules/sports/team-locales?with=language&team_id=${this.$route.params['id']}&orderBy=name`
    },
    refresh() {
      this.$refs.list.search()
    },
  },
}
</script>
